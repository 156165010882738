import { useQuery } from 'react-query';
import { getContactLists } from '../api/getContactLists';

const useContactLists = (contactId) => {
  const result = useQuery('contact_lists_'+(contactId ?? 'all'), () => getContactLists(contactId), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useContactLists;

import { useQuery } from 'react-query';
import { getMetric } from '../api/getMetric';
import {useContext} from "react";
import {UserContext} from "../contexts";

const useMetric = (metricName, metricType= '', campaignId = null) => {
  
  const {
    state: {
      startDate,
      endDate
    }
  } = useContext(UserContext);
  const result = useQuery('metric_'+ metricName+metricType+campaignId+startDate+endDate, () => getMetric(metricName, metricType, campaignId,startDate,endDate), {
    refetchOnWindowFocus: false,
    staleTime: 5000//0000,
  });
  return result;
};

export default useMetric;

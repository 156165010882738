import React, {useCallback, useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button} from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import {postApiKey} from "../../lib/api";
import {useSnackbar} from "notistack";
import usePermission from "../../lib/hooks/usePermission";
import PlanPicker from "./PlanPicker";
import {BillingContext, UserContext} from "../../lib/contexts";
import useCheckoutToken from "../../lib/hooks/useCheckoutToken";
import DialogTitle from "@material-ui/core/DialogTitle";
import {AccountBalance, Phone} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  title:{
    fontSize:20,
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const ChoosePlanDialog = ({forceOpen = false, style, setForceOpen, refetch, size, invisible = true, title = undefined}) => {
  
  const {
    state: { chosenPlan, checkingOut },
    actions: { setChosenPlan, setCheckingOut },
  } = useContext(BillingContext);
  const {
    state: { organization_id },
  } = useContext(UserContext);
  const { isLoading, data } = useCheckoutToken(chosenPlan);
  const handleRelocation = useCallback(()=>{
    if(data && data.url){
      console.log('checkingOut')
      console.log(data)
      window.location = data.url
    }
  },[data, chosenPlan])
  
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [nickname, setNickname] = useState('');
  const [apiKey, setApiKey] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('organization_settings.edit')
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    if(saved){
      refetch()
    }
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen, setForceOpen, saved]);
  
  const handleCheckout = useCallback(() => {
    if(data && data.url){
      setCheckingOut(true)
    }
  },[data]);
  
  return (
     <>
       {!invisible && <Button size={size} style={style} variant={'contained'} color={'primary'} onClick={hasPermission ? handleToggleOpen : () => enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})} disabled={open || saving}>
         {title ?? 'Pick a Plan'}
       </Button>}
       <Dialog
          maxWidth={'xl'}
          fullWidth={true}
          open={open}
          aria-labelledby="add-api-key-dialog-form" className={classes.form}
       >
         <DialogTitle id="add-api-key-dialog-title"><AccountBalance/> Last step! Choose the plan that best fits your needs.</DialogTitle>
         <Typography className={classes.title}>You may easily change or cancel your plan at anytime from the Billing Settings.</Typography>
         {/*{<Typography className={classes.title}>60-Day Refund, No Questions Asked!*</Typography>}*/}
         <PlanPicker handleCheckout={handleCheckout} handleToggleOpen={handleToggleOpen}/>
         <DialogActions>
           <Button onClick={handleCheckout}
                   className={classes.addButton}
                   disabled={saving || saved}>
             Purchase
           </Button>
         </DialogActions>
       </Dialog>
       {checkingOut && handleRelocation()}
     </>
  );
}

export default ChoosePlanDialog;

import * as React from 'react';
import {  Grid, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import useMetric from "../../../lib/hooks/useMetric";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {useParams} from "react-router";
import {useContext} from "react";
import {ThemeContext} from "../../../lib/contexts";
export default function PerformanceByHour() {
   const {campaignId} = useParams();
   const { darkMode } = useContext(ThemeContext);
   const {isLoading, isRefetching, data, refetch} = useMetric('performance_by_hour', 'campaign', campaignId);
  return (
    <>
      <Grid item xs={6}>
        <Typography variant={'h2'} color={'primary'}>
          Performance By Hour
        </Typography>
         {isLoading || isRefetching && <ProgressBar style={{margin:25}}/>}
         {!(isLoading || isRefetching) && data && <Chart
            options={{
               colors: ['#546E7A', '#D9534F', '#FF9800'],
               chart: {
                  type: 'bar',
               },
               plotOptions: {
                  bar: {
                     horizontal: false,
                     columnWidth: '55%',
                     endingShape: 'rounded'
                  },
               },
               stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
               },
               dataLabels: {
                  enabled: false
               },
               xaxis: {
                  categories: data.metric.labels ?? [],
                  labels: {
                     show: true,
                     style: {
                        colors: darkMode ? "#fff" : "#355c7d"
                     }
                  }
               },
               yaxis: {
                  labels: {
                     show: true,
                     style: {
                        colors: darkMode ? "#fff" : "#355c7d"
                     }
                  }
               },
               legend: {
                  labels: {
                     show: true,
                     useSeriesColors: true,
                  }
               }
            }}
            series={[{
               name: 'Sent',
               data: data.metric.sent ?? []
            }, {
               name: 'Opens',
               data: data.metric.opens ?? []
            }, {
               name: 'Clicks',
               data: data.metric.clicks ?? []
            }
            ]}
            type="bar"
            height={250}
         />}
      </Grid>
    </>
  );
}

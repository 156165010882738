import * as React from 'react';
import {Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import useMetric from "../../../lib/hooks/useMetric";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {useParams} from "react-router";
import {TrendingUp} from "@material-ui/icons";
import {useMemo} from "react";
export default function VisitorsByReferrer() {
   const {campaignId} = useParams();
   const {isLoading, isRefetching, data, refetch} = useMetric('top_referrers', 'visitor', campaignId);
   const { metric } = useMemo(()=>data !== undefined ? data : [],[data])
   
   return (
      <Grid item xs={2}>
         <Typography variant={'h2'} color={'primary'} style={{marginTop:0}}>
           Top Referrers
        </Typography>
         {isLoading || isRefetching && <ProgressBar style={{margin:25}}/>}
         {!(isLoading || isRefetching) && data && <List style={{height:245, overflowY:'auto'}}>
            {metric.map((item,index)=>{
            return <React.Fragment key={'fragment_'+index}>
                  <ListItem key={'li_'+index} dense={true}>
                     <ListItemAvatar>
                        <Avatar><TrendingUp/></Avatar>
                     </ListItemAvatar>
                     <ListItemText
                        primary={`#${index+1} - ${item.referrer}`}
                        secondary={`${item.visitors} Visitors`}
                     />
                  </ListItem>
                  <Divider/>
               </React.Fragment>
            })
            }
         </List>
         }
      </Grid>
  );
}

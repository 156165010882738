import { useQuery } from 'react-query';
import { getHashtags } from '../api/getHashtags';

const useHashtags = (contactId) => {
  const result = useQuery('hashtags_'+(contactId ?? 'all'), () => getHashtags(contactId), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useHashtags;

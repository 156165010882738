import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItemText, ListItemSecondaryAction, Button, Switch, Divider
} from "@material-ui/core";
import { AddOrganizationInviteDialog, ProgressBar} from '../../../components'
import useOrganizationInvitations from "../../../lib/hooks/useOrganizationInvitations";
import { updateOrganization, updateOrganizationInvitation} from "../../../lib/api";
import useOrganization from "../../../lib/hooks/useOrganization";
import {ManagePermissionsDialog} from "../../../components/ManagePermissionsDialog";
import {useSnackbar} from "notistack";
import usePermission from "../../../lib/hooks/usePermission";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    marginBottom:40,
    textAlign: 'center',
    display: 'block'
  }
}));
const OrganizationInvitations = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [forceOpen, setForceOpen] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [autoAddMembers, setAutoAddMembers] = useState(false);
  const {isLoading, isRefetching, data: invitations, refetch} = useOrganizationInvitations();
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  const { plan } = useMemo(()=>organization ?? [], [organization]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('organization_members.edit')
  const hasPermissionDelete = usePermission('organization_members.delete')
  
  useEffect(()=>{
    if(organization){
      setAutoAddMembers(organization.auto_add_verified_domains)
    }
  },[organization])
  
  const handleAutoAdd = useCallback(()=>{
    if(hasPermission){
      if(plan != "" && plan !="lite"){
        updateOrganization({auto_add_verified_domains: !autoAddMembers}).then(()=>{
        setAutoAddMembers(!autoAddMembers)
        enqueueSnackbar('Success', {variant:'success'})
      })
    }else{
        enqueueSnackbar('Please Upgrade to the Basic plan.', {variant:'error'})
      }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
    
  },[autoAddMembers, hasPermission])
  
  const handleInviteUpdate = useCallback((invite) => {
    if(hasPermissionDelete){
      if(invite){
      const confirm = window.confirm(`Confirm Cancellation for ${invite.name}'s Invitation?`)
      if(confirm){
      setSaving(true);
      const options = {
        active: false,
      }
      updateOrganizationInvitation(invite.id, options).then(()=>{
        setSaving(false);
        refetch()
      })
    }
    }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[refetch, hasPermissionDelete])
  
  const handleInvitePermissions = useCallback((invite, permissions) => {
    if(invite){
      setSaving(true);
      const options = {
        permissions: permissions
      }
      updateOrganizationInvitation(invite.id, options).then(()=>{
        setSaving(false);
        setForceOpen(undefined)
        refetch()
      })
    }
  },[refetch])
  
  const handleInviteRefetch = useCallback((inviteID) => {
    setForceOpen(inviteID)
    refetch()
  },[refetch])
  
  
  
  
  return (
       <Box className={classes.container}>
         <Typography className={classes.title}>Easily invite your team members and manage their permissions.</Typography>
         {
           isLoading || isRefetching || saving ?
              <ProgressBar style={{margin:150}}/>
              :
              invitations.length ?
                 <List>
                   {invitations.map(item=>{
                     return <ListItem key={item.id}>
                       <ListItemText primary={item.name} secondary={item.access_code}/>
                       <ListItemSecondaryAction>
                         {item.active ? <>
                              {item.status !== 'pending' && <Button variant={'contained'}
                                       color={item.status === 'pending' ? "primary" : 'secondary'}>{item.status}</Button>}
                              {item.status === 'pending' && item && <ManagePermissionsDialog user={item} forceOpen={forceOpen === item.id} handleInvitePermissions={handleInvitePermissions} style={{marginLeft:10}} />}
                              {item.status === 'pending' && <Button variant={'contained'} style={{marginLeft:10}}>Resend Invite</Button>}
                              {item.status === 'pending' && <Button variant={'contained'} style={{marginLeft:10}} onClick={()=>handleInviteUpdate(item)}>Cancel</Button>}
                            </>
                            :
                            <Button variant={'contained'} disabled={true}>Canceled</Button>}
                       </ListItemSecondaryAction>
                     </ListItem>
                   })}
                 </List>
                 :
                 <>
                   <Typography className={classes.title}>No invites found. Let&apos;s send your first one now.</Typography>
                 </>
         }
         <List>
           <ListItem>
             <ListItemText primary={'Automatically add users who sign up using a Verified Domain?'} secondary={''}/>
             <ListItemSecondaryAction>
               <Switch checked={autoAddMembers} onChange={handleAutoAdd} color={"secondary"}/>
             </ListItemSecondaryAction>
           </ListItem>
           <Divider style={{marginTop:20,marginBottom:20}}/>
           <ListItem>
             <ListItemText primary={''} secondary={''}/>
             <ListItemSecondaryAction>
               <AddOrganizationInviteDialog refetch={handleInviteRefetch}/>
             </ListItemSecondaryAction>
           </ListItem>
         </List>
       </Box>
    );
}

export default OrganizationInvitations;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction, Switch, Divider
} from "@material-ui/core";
import {AccountCredits, AddOrganizationInviteDialog} from '../../../components'
import {postBillingSettings, updateOrganization} from "../../../lib/api";
import {useSnackbar} from "notistack";
import useOrganization from "../../../lib/hooks/useOrganization";
import usePermission from "../../../lib/hooks/usePermission";
import {UserContext} from "../../../lib/contexts";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const BillingSettings = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar()
  const [stripeURL, setStripeURL] = useState();
  const [autoRefill, setAutoRefill] = useState();
  const {
    state: {plan},
    actions: { setShowPlanRequiredDialog},
  } = useContext(UserContext);
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  const hasPermissionBillingSettings = usePermission('billing_settings.edit')
  
  
  useEffect(()=>{
    if(organization){
      if(!organization.plan){
        setShowPlanRequiredDialog(true)
      }
      setAutoRefill(organization.auto_refill_tokens)
    }
  },[organization])
  useEffect(()=>{
    if(organization){
      setAutoRefill(organization.auto_refill_tokens)
    }
  },[organization])
  
  useEffect(async ()=>{
    const data = await postBillingSettings();
    if(data && data.url){
      setStripeURL(data.url)
    }
  },[])
  const handleRedirect = useCallback(()=>{
    if(organization && !organization.plan){
      window.location.reload()
    }else{
      window.open (stripeURL,
         "Billing Settings | SendBoth",
         "menubar=1,resizable=1,width=1000,height=1000");
    }
  },[stripeURL, organization])
  
  
  const handleAutoRefill = useCallback(()=>{
    if(hasPermissionBillingSettings){
      updateOrganization({auto_refill_tokens: !setAutoRefill}).then(()=>{
        setAutoRefill(!autoRefill)
        enqueueSnackbar('Success', {variant:'success'})
      })
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[hasPermissionBillingSettings, autoRefill])
  
  
    return (
      <Box className={classes.container}>
        <Typography className={classes.title}>Billing Settings</Typography>
  
        <Grid
           item xs={12}
           container
           direction="row"
           justifyContent="space-around"
           alignItems="center"
           spacing={0}>
          <AccountCredits expanded={true}/>
          
       
  
          <List>
            <ListItem>
              <ListItemText primary={'Automatically refill credits to prevent campaigns from being paused?'} secondary={''}/>
              <ListItemSecondaryAction>
                <Switch checked={autoRefill} onChange={handleAutoRefill} color={"secondary"}/>
              </ListItemSecondaryAction>
            </ListItem>
            <Typography color={'textSecondary'} variant={'caption'} style={{marginLeft:15}}>This will auto-charge the amount to complete any Scheduled or Ongoing Campaigns, with a minimum of $5.</Typography>
            <Divider style={{marginTop:20,marginBottom:20}}/>
          </List>
              <Grid container item xs={10} spacing={6} style={{marginTop:40}}>
                <Grid item xs={6}>
                  <Button fullWidth variant={'contained'} color={'primary'} onClick={handleRedirect}>Manage Plan</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={'contained'} color={'primary'} onClick={handleRedirect}>Manage Payment Methods</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={'contained'} color={'primary'} onClick={handleRedirect}>Manage Billing Address</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={'contained'} color={'primary'} onClick={handleRedirect}>Browse Invoice History</Button>
                </Grid>
              </Grid>
          
        </Grid>
      </Box>
    );
}

export default BillingSettings;

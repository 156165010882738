import React, { useState, useEffect, useMemo, useRef} from 'react';
import { Grid } from '@material-ui/core/';

import {
  Avatar,
  Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { toUnderScoreSlug} from "../../../lib/helpers/helpers";
import MarkdownEditor from "@uiw/react-markdown-editor";
import useAI from "../../../lib/hooks/useAI";
import AutoSuggest from '@avcs/autosuggest';
const ImageOutput = ({prompt, debounceQuery, setInput, input}) => {
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [summary, setSummary] = useState();
  const textareaRef = useRef(null);
  const [imageSrc, setImageSrc] = useState();
  const {isLoading: isLoadingAI, isRefetching: isRefetchingAI, data: ai, refetch: refetchAI} = useAI('image_generation', toUnderScoreSlug(prompt), debounceQuery);
  const { prompt:previousPrompt, response } = useMemo(()=>ai ?? [], [ai]);
  useEffect(() => {
    if (response) {
      if (response) {
        setImageSrc(response)
      }
      if (response) {
        setSummary(response)
      }
    }
  }, [response]);
  
  return (
    <>
                  <Grid item xs={12}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                  <Grid item xs={matchSM ? 12 : 6}>
                    <Typography>Editor</Typography>
                    <MarkdownEditor
                       previewWidth={1}
                       visibleEditor={true}
                       visible={false}
                       hideToolbar={true}
                       ref={textareaRef}
                       style={{minHeight:390, height:390}}
                       source={input}
                       toolbarsMode={[]}
                       toolbars={["undo", "redo"]}
                       onChange={(value, viewUpdate) => setInput(value)}
                    />
                  </Grid>
                  <Grid item xs={matchSM ? 12 : 6}>
                    <Grid item xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}>
                      <Grid item xs={6}>
                        <Typography>Output</Typography>
                        <Avatar src={imageSrc} variant={'square'} style={{width:400, height: 400}}/>
                      </Grid>
                      <Grid item xs={6} style={{display:'none'}}>
                        <Typography>Summary</Typography>
                        <MarkdownEditor.Markdown
                           style={{height:400, overflowY:'auto'}}
                           source={summary}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>
    </>
  );
}

export default ImageOutput;

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const postPhoneNumberCheckout = async (desired) => {
  const apiURL = API_HOST + '/checkout/phone_number';
  const { token, userID } = useCurrentUser();
  
  try {
    const { data } = await axios.post(apiURL,{
      desired: desired
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getOrganizationInvitations = async (inviteId) => {

  const apiURL = API_HOST + (inviteId ? '/organization_invites/'+inviteId : '/organization_invites');
  const { token, userID } = useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

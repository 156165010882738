import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Chip,
  Typography,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Share, FileCopy, Create } from '@material-ui/icons';
import { CampaignContext, UserContext, ContactsContextProvider } from '../../../lib/contexts';
import useContacts from '../../../lib/hooks/useContacts';
import useContactLists from '../../../lib/hooks/useContactLists';
import {TemplateListing} from '../../TemplateListing/';
import {ProgressBar} from '../../ProgressBar/';
import ContactsTable from '../../../screens/ContactsScreen/components/ContactTable';

const useStyles = makeStyles(theme => ({
  container:{
    margin: 'auto',
    width: '100%',
    borderColor: theme.palette.primary.main,
    borderSize:1,
    borderStyle:'none',
    textAlign:'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.primary.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.primary.main
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const PickReceipient = () => {
  const classes = useStyles();
  
  const {
    state: { user_id },
  } = useContext(UserContext)
  const { isLoading:isLoadingContacts, data:contactsDataRaw, refetch:contactsRefetch } = useContacts();
  const { isLoading:isLoadingContactLists, data:contactListsDataRaw, refetch:contactListsRefetch } = useContactLists();
  const contactsData = useMemo(()=>contactsDataRaw && !contactsDataRaw.error ? contactsDataRaw : [],[contactsDataRaw]);
  const contactListsData = useMemo(()=>contactListsDataRaw && !contactListsDataRaw.error ? contactListsDataRaw : [],[contactListsDataRaw]);
  
  const {
    state: {
      campaignRecipientLists,
      campaignRecipientContacts,
    },
    actions: {
      setCampaignRecipientLists,
      setCampaignRecipientContacts,
    },
  } = useContext(CampaignContext)
  
  const handleInputChange = useCallback((event) => {
    //setName(event.target.value);
  },[]);
  
  
  
  return (
    <>
      <Grid
         container
         direction="row"
         justifyContent="space-evenly"
         alignItems="center"
         spacing={0}
         className={classes.container}
      >
        {<>
          <Grid
             container
             direction="row"
             alignItems="center"
             justifyContent="space-between"
             spacing={4}
             className={classes.imageList}>
            
            {isLoadingContacts || isLoadingContactLists ?
               <>
                 <ProgressBar style={{margin:50}}/>
               </>
               :
               <>
                 <Grid item xs={6}
                       className={classes.container}>
                   <Autocomplete
                      multiple
                      id="tags-outlined"
                      onChange={(e,v)=>setCampaignRecipientLists(v)}
                      options={contactListsData}
                      defaultValue={campaignRecipientLists}
                      getOptionLabel={(option) => option.list_name}
                      filterSelectedOptions
                      groupBy={(option) => option ? option.list_name[0].toUpperCase() : undefined}
                      renderInput={(params) => (
                         <TextField
                            {...params}
                            variant="outlined"
                            label="Select Contact Lists"
                            placeholder="Fall Newsletter"
                         />
                      )}
                   />
                 </Grid>
                 <Grid item xs={11}
                       className={classes.container}>
                   <ContactsContextProvider>
                     <ContactsTable data={contactsData} setSelectedContacts={setCampaignRecipientContacts} selectedContacts={campaignRecipientContacts}/>
                   </ContactsContextProvider>
                 </Grid>
            </>}
          </Grid>
        </>}
      </Grid>
    </>
  );
}
export default PickReceipient;

import React, { useState, useCallback } from 'react';
import { Grid, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons/';
import { DataGrid } from '@material-ui/data-grid';
import { DownloadProgressDialog } from '../../../components';
import useMetric from "../../../lib/hooks/useMetric";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {Refresh} from "@material-ui/icons";
import {useParams} from "react-router";

const columns = [
  {
    field: 'name',
    headerName: 'Campaign Name',
    width: 200,
    editable: true,
  },
  {
    field: 'total_sent',
    headerName: 'Total Sent',
    width: 150,
    editable: true,
  },
  {
    field: 'emails_sent',
    headerName: 'Emails Sent',
    width: 160,
    editable: true,
  },
  {
    field: 'sms_sent',
    headerName: 'SMS Sent',
    width: 150,
    editable: true,
  },
  {
    field: 'unique_opens',
    headerName: 'Unique Opens',
    width: 170,
    editable: true,
  },
  {
    field: 'open_rate',
    headerName: 'Open Rate',
    sortable: false,
    editable: true,
    width: 120,
  },
  {
    field: 'unique_clicks',
    headerName: 'Unique Clicks',
    sortable: false,
    editable: true,
    width: 140,
  },
  {
    field: 'click_rate',
    headerName: 'Click Rate',
    sortable: false,
    editable: true,
    width: 120,
  },
  {
    field: 'total_opens',
    headerName: 'Total Opens',
    sortable: false,
    editable: true,
    width: 150,
  },
  {
    field: 'total_clicks',
    headerName: 'Total Clicks',
    sortable: false,
    editable: true,
    width: 150,
  },
  {
    field: 'soft_bounces',
    headerName: 'Soft Bounces',
    sortable: false,
    editable: true,
    width: 150,
  },
  {
    field: 'hard_bounces',
    headerName: 'Hard Bounces',
    sortable: false,
    editable: true,
    width: 150,
  },
];

export default function CampaignTableBreakdown({campaignData}) {
  const {campaignId} = useParams();
  const {isLoading, isRefetching, data, refetch} = useMetric('campaign_performance_breakdown_by_recipient', 'campaign', campaignId);
  
  const [isDownloading, setIsDownloading] = useState(false);
  const [exportMenuOpen, setExportMenuOpen] = useState(null);
  
  const handleExportMenu = useCallback((event) => {
    setExportMenuOpen(event.currentTarget)
  },[])
  const handleExportMenuClose = useCallback(()=>{
    setExportMenuOpen(false)
  },[])
  
  const handleFileExport = useCallback((type, format) => {
    if(type && format){
      setIsDownloading(type);
      postExport(type, format, campaignId).then((result)=>{
        if(result.status === 200){
          const data = result.data
          const prefix = 'SendBoth_Export_';
          const fileName = prefix+type + "." + format;
          const blob = new Blob([format === 'csv' ? data : JSON.stringify(data)], { type: format === 'csv' ? 'text/csv' : 'text/json' })
          // Create an anchor element and dispatch a click event on it to trigger a download
          const a = document.createElement('a')
          a.download = fileName
          a.href = window.URL.createObjectURL(blob)
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          a.dispatchEvent(clickEvt)
          a.remove()
        }else{
          alert('Something went wrong, please try again.');
        }
        setIsDownloading(null);
        setExportMenuOpen(null);
      }).catch(()=>{
        alert('Something went wrong, please try again.');
        setIsDownloading(null);
        setExportMenuOpen(null);
      })
    }
  },[])
  
  
  return (
     <>
       <Grid item xs={9}>
         <Typography variant={'h2'} color={'primary'}>
           Campaign Performance Breakdown By Recipient
         </Typography>
       </Grid>
       <Grid item xs={1}>
         <Button disabled={(!campaignData.length && !data) || isLoading || isRefetching} color={'default'} variant={'contained'} style={{ width:50,height:50,margin:20}}
                 onClick={refetch}>
           <Refresh/>
         </Button>
       </Grid>
       <Grid item xs={2}>
         <Button disabled={!campaignData.length || isLoading || isRefetching} color={'default'} variant={'contained'} style={{ width:160,height:50,margin:20}}
                 onClick={handleExportMenu}>
           <SaveAlt/>
           Export
         </Button>
         <Menu
            id="export-menu"
            anchorEl={exportMenuOpen}
            open={Boolean(exportMenuOpen)}
            onClose={handleExportMenuClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'left'}}
         >
           <MenuItem onClick={()=>handleFileExport("users", "csv")}>CSV</MenuItem>
           <MenuItem onClick={()=>handleFileExport("users", "json")}>JSON</MenuItem>
         </Menu>
         {isDownloading && <DownloadProgressDialog type={isDownloading} />}
       </Grid>
    <div style={{ height: 400, width: '100%'}}>
      {isLoading || isRefetching && <ProgressBar style={{margin:100}}/>}
      {data && <DataGrid
         rows={data.metric}
         columns={columns}
         pageSize={5}
      />}
    </div>
     </>
  );
}

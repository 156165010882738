import { useQuery } from 'react-query';
import { getCampaigns } from '../api/getCampaigns';

const useCampaigns = () => {
  const result = useQuery('campaigns', () => getCampaigns(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useCampaigns;

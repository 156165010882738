import React, {useCallback, useState} from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItemText, ListItemSecondaryAction, Button, Divider
} from "@material-ui/core";
import {AddAPIKey, AddVerifiedDomainDialog, CopyAPIKey, ProgressBar} from '../../../components'
import useVerifiedDomains from "../../../lib/hooks/useVerifiedDomains";
import {prettyDateTime} from "../../../lib/helpers/helpers";
import {updateVerifiedDomain} from "../../../lib/api/updateVerifiedDomain";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  title:{
    marginTop:20,
    marginBottom:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const VerifiedDomains = () => {
  const classes = useStyles();
  const theme = useTheme();
  
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [forceOpen, setForceOpen] = useState();
  const [saving, setSaving] = useState(false);
  const {isLoading, isRefetching, data: domainNames, refetch} = useVerifiedDomains();
  
  const updateDomain = useCallback((domain, updateType) => {
    if(domain){
      setSaving(true);
      const options = updateType==="cancel" ? {
          active: false,
      }
      :
         {
           active: false,
           verified: false,
         }
      updateVerifiedDomain(domain.id, options).then(()=>{
        setSaving(false);
        refetch()
      })
    }
  },[refetch])
  
  const handleForceOpen = useCallback((item) => {
    setForceOpen(item)
  },[])
  
  return (
     <Box className={classes.container}>
       <Typography className={classes.title}>Verified Domains control which email addresses you can send from as well as who can automatically be added to your team.</Typography>
       {
         isLoading || isRefetching || saving?
            <ProgressBar style={{margin:150}}/>
            :
            domainNames.length ?
               <List>
                 {domainNames.map(item=>{
                   return <ListItem key={item.id}>
                     <ListItemText primary={item.domain} secondary={item.verified && !item.active ? <>{'Revoked on: ' + prettyDateTime(item.updated_at)}</> : item.verified ? <>{'Verified on: ' + prettyDateTime(item.updated_at)}</> : <>{'Added on: ' + prettyDateTime(item.created_at)}</>}/>
                     <ListItemSecondaryAction>
                       <Button variant={'contained'} color={"default"} onClick={()=>handleForceOpen(item.domain)} style={{marginRight:10}}>View DNS Records</Button>
                       {
                         item.active ?
                            item.verified ?
                               <Button variant={'contained'} color={"secondary"} onClick={()=>updateDomain(item, "revoke")} disabled={domainNames.length === 1}>Revoke</Button> :
                               <>
                                 <Button variant={'contained'} color={"secondary"} onClick={()=>updateDomain(item, "cancel")}>Cancel</Button>
                               </>
                            :
                            item.verified ?
                            <Button variant={'contained'} color={"secondary"} disabled={true}>Revoked</Button> :
                          <Button variant={'contained'} color={"secondary"} disabled={true}>Canceled</Button>
                       }
                     </ListItemSecondaryAction>
                   </ListItem>
                 })}
                 
                 <Divider style={{marginTop:20,marginBottom:20}}/>
                 <ListItem>
                   <ListItemText primary={''} secondary={''}/>
                   <ListItemSecondaryAction>
                     {(forceOpen || !forceOpen) && <AddVerifiedDomainDialog refetch={refetch} forceOpen={Boolean(forceOpen)}
                                               setForceOpen={setForceOpen} viewVerificationDetails={forceOpen}/>}
                   </ListItemSecondaryAction>
                 </ListItem>
               </List>
               :
               <>
                 <Typography className={classes.title}>Let&apos;s verify your first domain now.</Typography>
                 <AddVerifiedDomainDialog refetch={refetch} style={{margin: 'auto', display: 'block'}} size={'large'}/>
               </>
       }
     </Box>
  );
}

export default VerifiedDomains;

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const deleteContactFromList = async (connectionID = 0, listID, selectedContacts) => {
  const apiURL = API_HOST + '/contact_lists_connections' + (connectionID ? '/'+connectionID : '');
  const { token, userID } = useCurrentUser();
  
  try {
    const { data } = await axios.delete(apiURL,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      },
      data:{
        list_id: listID,
        contacts: selectedContacts,
      }
    });
    return data.data;
  } catch (error) {
    console.error(error);
  }
};

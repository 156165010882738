import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Dialog, FormControlLabel} from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {InvertColors, Schedule} from '@material-ui/icons';
import {Checkbox, DialogContentText, FormGroup, Typography} from "@material-ui/core";
import {ThemeContext} from "../../lib/contexts";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
  },
  formControl: {
    position:'relative',
    padding:50,
    paddingTop:0,
    '& p':{
      marginBottom: 20,
    },
    textAlign:'center'
  },
  stopAsking: {
    top:10,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  subtitle:{
    fontSize:16,
    fontWeight:'bold'
  }
}));

const Index = () => {
  const classes = useStyles();
  
  const { darkMode, toggleTheme } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [suggestTheme, setSuggestTheme] = useState(true);
  const [greeting, setGreeting] = useState(true);
  const [greetingSubtitle, setGreetingSubtitle] = useState(true);
//  const [darkMode, setDarkMode] = useState(localStorage.getItem('SB_THEME') === 'true' || (!localStorage.getItem('SB_THEME') && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches));
  
  const handleStopAsking = useCallback(() => {
    setSuggestTheme(!suggestTheme)
    localStorage.setItem('SB_THEME_SUGGEST', !suggestTheme)
  },[suggestTheme]);
  
  const handleToggleTheme = useCallback(() => {
    toggleTheme();
    setOpen(false);
  },[toggleTheme]);
  
  const handleClose = useCallback(() => {
    setOpen(false);
  },[]);
  
  const currentTime = new Date();
  const hour = currentTime.getHours();
  
  useEffect(() => {
    // Check if current theme is "light" and it is 6pm or if current theme is "dark" and it is 7am
    if ((localStorage.getItem('SB_THEME_SUGGEST') ==  true || localStorage.getItem('SB_THEME_SUGGEST') == null) && ((!darkMode && hour >= 18 && localStorage.getItem('SB_THEME') ===  'false') || (darkMode && hour <= 18 && hour >= 7 && localStorage.getItem('SB_THEME') ===  'true'))) {
      setOpen(true);
    }
  }, []);
  
   useEffect(()=>{
    if (hour >= 3 && hour < 12) {
      setGreeting('Good morning, sunshine!');
      setGreetingSubtitle('Let\'s make today a great day.');
    } else if (hour >= 12 && hour < 17) {
      setGreeting('Good afternoon.')
      setGreetingSubtitle('Halfway through the day, keep it up!')
    } else if (hour >= 17 && hour < 23) {
      setGreeting( 'Good evening, party animal!')
      setGreetingSubtitle( 'Looks like you\'re working hard.')
    } else {
      setGreeting( 'Hey, Night Owl!')
      setGreetingSubtitle( 'Looks like a late night...')
    }
  },[hour, greeting, greetingSubtitle])
  
  return (
      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="time-of-day" className={classes.form}
      >
        <DialogTitle id="time-of-day-title"><Schedule/> {greeting}</DialogTitle>
        <DialogContent className={classes.formControl}>
          <Typography color="primary" className={classes.subtitle}>{greetingSubtitle}</Typography>
          <DialogContentText>
            Should we switch to the {darkMode ? 'Light' : 'Dark'} theme to improve your visual experience & reduce eye strain?
          </DialogContentText>
                <Button variant={'contained'} color={'primary'} onClick={handleToggleTheme} fullWidth={true} style={{marginBottom:20}}>
                  Yes, Toggle Theme
                </Button>
                <Button variant={'contained'} color={'standard'} onClick={handleClose} fullWidth={true}>
                  No Thanks
                </Button>
  
          <FormGroup row >
            <FormControlLabel style={{margin:"5px auto auto"}}
               control={<Checkbox onChange={handleStopAsking} checked={!suggestTheme} name="remember" style={{marginTop:-5}} />}
               label="Remember decision?"
            />
          </FormGroup>
        </DialogContent>
      </Dialog>
  );
}
export default Index;

import * as React from 'react';
import { Box, Grid, Typography, Fab } from "@material-ui/core";
import { DateRange, Print } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {useCallback, useContext, useState} from "react";
import DateRangeFilterDialog from "../../../components/DateRangeFilterDialog/DateRangeFilterDialog";
import {UserContext} from "../../../lib/contexts";
import {prettyDate, minusMonth} from "../../../lib/helpers/helpers";

const useStyles = makeStyles(theme => ({
   dateRange:{
      marginTop:80,
      fontWeight:'bold',
      fontSize:23,
   },
}));
export default function DateRangeFilter() {
   const classes = useStyles();
   const {
      state: {
         showingRange,
         startDate,
         endDate
      },
      actions: {
         setShowingRange
      }
   } = useContext(UserContext);
   
  return (
    <>
       <Box>
          <Typography color={'primary'}
                      className={classes.dateRange}>
             {prettyDate(startDate)} - {prettyDate(endDate)}
          </Typography>
          <Typography color={'default'}>
             Compared with: {minusMonth(startDate)} - {minusMonth(endDate)}
          </Typography>
          <Fab color={'primary'} aria-label="change-date-range" style={{float:'right', marginTop:-60}} onClick={()=>setShowingRange(!showingRange)}>
             <DateRange />
          </Fab>
          <Fab color={'default'} aria-label="export-report" style={{float:'right', marginTop:-60, marginRight:-80}} onClick={()=>window.print()}>
             <Print />
          </Fab>
       </Box>
       { showingRange && <DateRangeFilterDialog title={'Visitor History Date-Range'} />}
    </>
  );
}

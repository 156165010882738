import { useQuery } from 'react-query';
import {getUploadPresign} from "../api";

const useUploadPresign = (uploadType) => {
  const result = useQuery('upload_presign_'+uploadType, () => getUploadPresign(uploadType), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useUploadPresign;

import React, {useCallback, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import {
  TextField,
  withStyles,
} from '@material-ui/core';
import {Add, BrightnessAuto} from '@material-ui/icons';
import { ProgressBar } from '../ProgressBar';
import AIImageGen from "./AIImageGen";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  previewButton:{
    color: theme.palette.secondary.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AIWriterDialog = ({forceOpen = false, setForceOpen, refetch, size, invisible, title = undefined, setShowingAIImage}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  useEffect(()=>{
    const self = this;
  },[])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      //setForceOpen(false)
      setShowingAIImage(false)
    }
  },[open, forceOpen, setForceOpen]);

  
  const handlePrint = useCallback((event) => {
  
  },[]);
  
  
  const handleSave = useCallback(() => {

  },[]);
  
  return (
    <>
      {!invisible && <Button className={size == 'small' ? classes.addPatientListSmall : size == 'text' ? classes.addPatientListText : classes.addPatientList} variant={'contained'}
               onClick={handleToggleOpen} disabled={open || saving}>
        {size != 'text' && <Add style={{marginBottom:-6}}/>} {size == 'text' && title ? title : 'Generate Image'}
      </Button>}
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-patient-list-dialog-form" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><BrightnessAuto/> AI Image Generation</DialogTitle>
        <DialogContent>
          {
            saving ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>
                  Choose a Photo Mode from the list and write a short prompt. SendBoth will do it&apos;s best to generate variations.
                </DialogContentText>
                <AIImageGen/>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handlePrint}
                   className={classes.previewButton}
                   disabled={saving}>
            Copy
          </Button>
          <Button onClick={handlePrint}
                   className={classes.previewButton}
                   disabled={saving}>
            Print
          </Button>
          <Button onClick={handleSave}
                  className={classes.addButton}
                  disabled={saving}>
            Save to...
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AIWriterDialog;

import React, { useContext, useMemo, useState } from 'react';

import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Switch,
  Paper,
  Grow,
  Popper,
  ButtonGroup,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import { Screen, AccountCredits } from '../../components';
import MetricCard from '../../components/MetricCard/MetricCard';
import { ContactListsCards } from './components';
import { UserContext } from '../../lib/contexts';
import { makeStyles } from '@material-ui/core/styles';
import usePermission from '../../lib/hooks/usePermission';
import { ArrowDropDown } from '@material-ui/icons';
import { Button, MenuItem } from '@material-ui/core/';
const useStyles = makeStyles(theme => ({
  screen: {
    width: '100%',
    maxWidth: '80vw',
    margin: '50px auto auto',
  },
  container: {
    width: '100%',
    maxWidth: '80vw',
    margin: '20px auto auto',
    '& h2': {
      width: '100%',
      fontSize: 20,
      marginBottom: 20,
      color: theme.palette.primary.main,
      display: 'block',
    },
  },
  patientLists: {
    marginTop: 20,
  },
}));

const DashboardScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [viewingAllTime, setViewingAllTime] = useState(false);
  const {
    state: { first_name },
  } = useContext(UserContext);

  const hasPermissionView = usePermission('contacts.view');

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const {
    state: { showingCreateContactListDialog },
    actions: { setShowingAIWriter, setShowingAIImage, setShowingCreateContactListDialog },
  } = useContext(UserContext);
  const options = useMemo(
    () => [
      { name: 'AI-Assisted Writing', onClick: () => setShowingAIWriter(true) },
      { name: 'AI Image Generation', onClick: () => setShowingAIImage(true) },
      // { name: 'Campaign', onClick: () => setShowingAIWriter(true) },
      // { name: 'Contact', onClick: () => setShowingAIWriter(true) },
      //{ name: 'Contact List', onClick: () => setShowingCreateContactListDialog(true) },
    ],
    [],
  );

  return (
    <Screen title={'Dashboard | SendBoth'} showTopNav={true} showFooter={true}>
      <Grid container alignItems="center" spacing={0} className={classes.screen}>
        <Grid item xs={2}>
          <Typography variant={'subtitle1'} style={{ marginTop: 60, marginBottom: 10 }}>
            Dashboard
          </Typography>
          <Button
            variant="contained"
            color="primary"
            ref={anchorRef}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select what to create"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            Create &nbsp;
            <ArrowDropDown />
          </Button>
        </Grid>
        <Grid item xs={6} style={{ marginLeft: 0 }}>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 1 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem key={option.name} selected={index === selectedIndex} onClick={option.onClick}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
        <Grid item xs={4}>
          <AccountCredits />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={0}
          className={classes.container}
        >
          <Typography variant={'h2'} color={'primary'}>
            Campaign Overview - Last 30 Days
            <Grid style={{ display: 'none' }} variant={'h4'} container alignItems="center" spacing={1}>
              <Grid item>Last 30 Days</Grid>
              <Grid item>
                <Switch
                  checked={viewingAllTime}
                  onChange={() => setViewingAllTime(!viewingAllTime)}
                  name="viewingAllTime"
                />
              </Grid>
              <Grid item>All Time</Grid>
            </Grid>
          </Typography>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
            <MetricCard slug={'email_scheduled'} />
            <MetricCard slug={'emails_sent'} />
            <MetricCard slug={'total_opens'} />
            <MetricCard slug={'unique_opens'} />
            <MetricCard slug={'open_rate'} />
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
            <MetricCard slug={'number_of_campaigns'} />
            <MetricCard slug={'total_clicks'} />
            <MetricCard slug={'unique_clicks'} />
            <MetricCard slug={'click_rate'} />
            <MetricCard slug={'unsubscribers'} />
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0}>
            <MetricCard slug={'sms_scheduled'} />
            <MetricCard slug={'sms_sent'} />
            <MetricCard slug={'hard_bounces'} />
            <MetricCard slug={'soft_bounces'} />
            <MetricCard slug={'total_forwards'} />
          </Grid>
          {hasPermissionView && (
            <Typography variant={'h2'} color={'primary'}>
              Quick Access - Contact Lists
            </Typography>
          )}
          {hasPermissionView && (
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
              className={classes.patientLists}
            >
              {(showingCreateContactListDialog || !showingCreateContactListDialog) && (
                <ContactListsCards setForceOpenOverride={showingCreateContactListDialog} />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Screen>
  );
};

export default DashboardScreen;

import React from 'react';
import {Button, Card, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  no_permission:{
    padding:100,
    margin:'auto',
    marginTop:200,
    textAlign:'center',
  },
  no_permission_embed:{
    background:'transparent',
    boxShadow:'none',
    padding:100,
    margin:'auto',
    marginTop:40,
    marginBottom:40,
    textAlign:'center',
  }
}));
const AskPermission = ({ title, embed = false }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  
  return (
     <>
       <Grid container spacing={1}>
         <Card className={embed ? classes.no_permission_embed : classes.no_permission}>
           <Typography>Sorry, {title} are not available for access.<br/>Please ask your Admin for Permission.</Typography>
           <Button variant={'contained'} color={'primary'} style={{marginTop:40}} onClick={() => enqueueSnackbar('Request Sent!', {variant:'success'})}>Send Request</Button>
         </Card>
       </Grid>
     </>
  );
};
export default AskPermission;

import { useQuery } from 'react-query';
import { getContact } from '../api/getContact';

const useContact = (contactId) => {
  const result = useQuery('contact_'+(contactId ?? 'all'), () => getContact(contactId), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useContact;

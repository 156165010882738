import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Typography, Menu, MenuItem, Grid, Checkbox, FormControlLabel, FormGroup, IconButton, Button } from '@material-ui/core/';
import { MoreVert, Delete, Email } from '@material-ui/icons/';
import { deleteCampaign } from '../../../lib/api/deleteCampaign';
import {replaceTime, prettyDateTime, prettyNumber} from '../../../lib/helpers/helpers';
import {useSnackbar} from "notistack";
import usePermission from "../../../lib/hooks/usePermission";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  row: {
    cursor:'pointer'
  },
}));


export default function ListItemRow({value, refetch}) {
  const { name, created_at } = value;
  const classes = useStyles();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermissionDelete = usePermission('campaigns.delete')
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleManage = () => {
    setAnchorEl(null);
    navigate("../campaign/"+value.id, { replace: true })
  };
  
  const handleDeleteCampaign = useCallback(event => {
    if(hasPermissionDelete){
      const confirm = window.confirm('Confirm Deletion: '+value.name)
      if(confirm){
        deleteCampaign(value.id).then(()=>{
          setAnchorEl(null);
          refetch();
        })
      }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  
  },[value, refetch, hasPermissionDelete]);
  
  return (
    <ListItem className={classes.row}>
      <ListItemAvatar onClick={()=>navigate("../campaign/"+value.id, { replace: true })}>
        <Avatar>
          <Email />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={prettyDateTime(created_at)} onClick={()=>navigate("../campaign/"+value.id, { replace: true })}
      />
      <ListItemSecondaryAction>
        {value.paused ? <>
          <Button variant={'contained'} color={'secondary'} onClick={()=>navigate("../campaign/"+value.id, { replace: true })}>Campaign Paused</Button>
        </> : (new Date(value.delivery_date) > new Date() || value.total_sent === null) ? <>
          <Button variant={'contained'} color={'secondary'} onClick={()=>navigate("../campaign/"+value.id, { replace: true })}>Scheduled: {prettyDateTime(value.delivery_date)}</Button>
        </> : <>
          <Button variant={'outlined'} color={'primary'} onClick={()=>navigate("../campaign/"+value.id, { replace: true })}>{prettyNumber(value.total_sent ?? 0)} Sent</Button>
          &nbsp;
          <Button variant={'outlined'} color={'primary'} onClick={()=>navigate("../campaign/"+value.id, { replace: true })}>{prettyNumber(value.total_opens ?? 0)} Opens</Button>
          &nbsp;
          <Button variant={'outlined'} color={'primary'} onClick={()=>navigate("../campaign/"+value.id, { replace: true })}>{prettyNumber(value.total_clicks ?? 0)} Clicks</Button>
        </>
        }
        &nbsp;
        <IconButton edge="end" aria-label="more"
                    aria-controls="list-management"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit">
          <MoreVert />
        </IconButton>
        <Menu
          id="list-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleManage}>Manage</MenuItem>
          {/*<MenuItem disabled={true}>Duplicate</MenuItem>*/}
          <MenuItem onClick={handleDeleteCampaign}>{value.delivery_date ? 'Archive' : 'Delete'}</MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

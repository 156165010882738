import React, { useCallback, useEffect, useState} from 'react';
import { Button, List, ListItem, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import ProgressBar from '../ProgressBar/ProgressBar.js';

import {useDropzone} from 'react-dropzone';
import {postContactBulkImport} from "../../lib/api/postContactBulkImport";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    padding:20
  },
  fileList:{
    maxWidth:'60%',
    margin:'20px auto auto',
    display: 'block',
  },
  dropzone:{
    maxWidth:'60%',
    margin:'20px auto auto',
    padding:30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  dropzoneActive:{
    maxWidth:'60%',
    margin:'20px auto auto',
    padding:30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  uploadButton:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin:'20px auto 0px',
    height:50,
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    },
    '&:disabled':{
      color: theme.palette.common.black,
    '& *':{
      color: theme.palette.common.black,
    },
    }
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const ImportContacts = ({refetch}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar()
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState([]);
  
  const {acceptedFiles, getRootProps, getInputProps, isDragActive, inputRef} = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    getFilesFromEvent: event => myCustomFileGetter(event)
  
  });
  async function myCustomFileGetter(event) {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    
    for (var i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      
      Object.defineProperty(file, 'myProp', {
        value: true
      });
      
      files.push(file);
    }
    return files;
  }
  
  
  const handleContactUpload = useCallback(() => {
    setSaving(true)
    const rawData = files
    
    postContactBulkImport(rawData).then((response)=>{
      if(response && response.status == "success"){
        enqueueSnackbar('Success! Contacts uploaded.', {variant:'success'})
        setFiles([])
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        inputRef.current.value = ''
        refetch()
      }else{
        enqueueSnackbar('Something went wrong, try again.', {variant:'error'})
      }
      setSaving(false);
    })
  },[files])
  
  useEffect(()=>{
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
    
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        //console.log(binaryStr)
        //return binaryStr
        setFiles(binaryStr);
      }
      return reader.readAsArrayBuffer(file)
    })
  },[acceptedFiles])
  
    return (
      <>
        <Typography className={classes.title}>Let&apos;s import any of your existing contacts.</Typography>
  
        <section className={classes.container}>
          <div {...getRootProps({className: isDragActive ? classes.dropzoneActive : classes.dropzone})}>
            <input {...getInputProps()} />
            <p>Drag &apos;n&apos; drop any CSV / Excel, or click to select files</p>
          </div>
          <aside className={classes.fileList}>
            <Typography>Selected Files:</Typography>
            <List>
              {acceptedFiles.map((file, index) => (
                 <ListItem key={index}>
              {file.name} - {file.size} bytes
                </ListItem>
                ))}
            </List>
          </aside>
          {saving ?
             <ProgressBar style={{margin:30}}/>
             :
             <Button color={'primary'} className={classes.uploadButton} variant={'contained'}
                     onClick={handleContactUpload} disabled={files.length === 0 || saving}>
               <Add /> <Typography>Upload</Typography>
             </Button>}
        </section>
      </>
    );
}

export default ImportContacts;

import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { Button, Box, Drawer, Menu, MenuItem, Grid, Typography, Paper, LinearProgress } from "@material-ui/core";
import { SaveAlt, Delete } from "@material-ui/icons";
import { Screen, DownloadProgressDialog } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from '../../lib/contexts';
import { ContactsContext, ContactsContextProvider } from '../../lib/contexts/ContactsContext';
import useContacts from '../../lib/hooks/useContacts.js';
import { postExport, deleteContact } from '../../lib/api/';
import { ContactTable, ViewContact } from './components/';

import { AddContactDialog, ProgressBar } from '../../components';
import {useSnackbar} from "notistack";
import usePermission from "../../lib/hooks/usePermission";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    padding:10
  },
  content:{
    margin:'-20px auto auto',
    width:'100%',
     backgroundColor: theme.palette.backgrounds.paper,
   },
   title: {
  margin: theme.spacing(4, 0, 2),
  },
noneFound:{
  padding:40,
     margin: 'auto',
     textAlign:'center',
     '& *':{
    display:'block',
       margin: 'auto',
       textAlign:'center',
  }
},
   contactDrawer:{
   }
}));

const ContactsScreenWrapper = () => {
  return (
<ContactsContextProvider>
   <ContactsScreen/>
</ContactsContextProvider>
  );
};

const ContactsScreen = () =>{
   const classes = useStyles();
   const {contactId} = useParams();
   const {
      state: { first_name },
   } = useContext(UserContext);
   const {
      state: { selectedContacts, viewingContact },
      actions: { setViewingContact, resetViewingContact },
   } = useContext(ContactsContext);
   const { isLoading, data, refetch } = useContacts(null, false, viewingContact);
   const tableData = useMemo(()=>data && !data.error ? data : [],[data]);
   const [isDownloading, setIsDownloading] = useState(false);
   const [exportMenuOpen, setExportMenuOpen] = useState(null);
   
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const hasPermissionDelete = usePermission('contacts.delete')
   
   useEffect(()=>{
      if(contactId){
         setViewingContact(contactId)
      }
   },[contactId]);
   
   const handleExportMenu = useCallback((event) => {
      setExportMenuOpen(event.currentTarget)
   },[])
   const handleExportMenuClose = useCallback(()=>{
      setExportMenuOpen(false)
   },[])
   
   const handleFileExport = useCallback((type, format) => {
      if(type && format){
         setIsDownloading(type);
         postExport(type, format).then((result)=>{
            if(result.status === 200){
               const data = result.data
               const prefix = 'SendBoth_Export_';
               const fileName = prefix+type + "." + format;
               const blob = new Blob([format === 'csv' ? data : JSON.stringify(data)], { type: format === 'csv' ? 'text/csv' : 'text/json' })
               // Create an anchor element and dispatch a click event on it to trigger a download
               const a = document.createElement('a')
               a.download = fileName
               a.href = window.URL.createObjectURL(blob)
               const clickEvt = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: true,
               })
               a.dispatchEvent(clickEvt)
               a.remove()
            }else{
               alert('Something went wrong, please try again.');
            }
            setIsDownloading(null);
            setExportMenuOpen(null);
         }).catch(()=>{
            alert('Something went wrong, please try again.');
            setIsDownloading(null);
            setExportMenuOpen(null);
         })
      }
   },[])
   
   const handleDeleteSelected = useCallback(event => {
      if(hasPermissionDelete){
         const confirm = window.confirm('Confirm Deletion of '+selectedContacts.length+' Contact'+ (selectedContacts.length>1 ? 's' : '') + '?')
         if(confirm){
            deleteContact(selectedContacts).then(()=>{
               refetch();
            })
         }
      }else{
         enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
      }
   },[selectedContacts, refetch, hasPermissionDelete]);
   
   return (
      <Screen title={'Contacts'} showTopNav={true} showFooter={true} permission={'contacts.view'}>
         <Drawer anchor={'right'} open={viewingContact} onClose={resetViewingContact} className={classes.contactDrawer}>
            {viewingContact && <ViewContact refetch={refetch}/>}
         </Drawer>
         <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
         >
               <Typography variant={'subtitle1'}>
                  Contacts
               </Typography>
               <div className={classes.content}>
                  <Grid
                     container
                     direction="row"
                     justifyContent="space-evenly"
                     alignItems="center"
                     spacing={1}
                     className={classes.container}
                  >
                     {isLoading ?
                        <ProgressBar /> :
                        <>
                           {tableData ? <>
                                 <Grid item xs={5}>
                                    <Typography variant="h6" className={classes.title}>
                                       Total Contacts: {tableData ? tableData.length : 0}
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={2}>
                                    <AddContactDialog mode={'add'} size={'text'} title={'Add a Contact'} refetch={refetch}/>
                                 </Grid>
                                 <Grid item xs={2}>
                                    <AddContactDialog mode={'import'} size={'text'} title={'Bulk Import'} refetch={refetch}/>
                                 </Grid>
                                 <Grid item xs={2}>
                                    <Button disabled={!tableData.length} color={'default'} variant={'contained'} style={{ width:160,height:50}}
                                            onClick={handleExportMenu}>
                                       <SaveAlt/>
                                       Export
                                    </Button>
                                    <Menu
                                       id="export-menu"
                                       anchorEl={exportMenuOpen}
                                       open={Boolean(exportMenuOpen)}
                                       onClose={handleExportMenuClose}
                                       anchorOrigin={{ vertical: 'center', horizontal: 'left'}}
                                    >
                                       <MenuItem onClick={()=>handleFileExport("users", "csv")}>CSV</MenuItem>
                                       <MenuItem onClick={()=>handleFileExport("users", "json")}>JSON</MenuItem>
                                    </Menu>
                                    {isDownloading && <DownloadProgressDialog type={isDownloading} />}
                                 </Grid>
                                 <Grid item xs={1}>
                                    <Button disabled={!selectedContacts.length} color={'default'} variant={'contained'} style={{ height:50}}
                                            onClick={handleDeleteSelected}>
                                       <Delete/>
                                    </Button>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <ContactTable data={tableData}/>
                                 </Grid>
                              </>
                              :
                              <>
                                 <Typography variant="h6" className={classes.noneFound}>
                                    No Contacts were found.
                                    <AddContactDialog size={'text'} title={'Add a Contact'}/>
                                 </Typography>
                              </>
                           }
                        </>
                     }
                  </Grid>
               </div>
         </Grid>
      </Screen>
   )
}
export default ContactsScreenWrapper;

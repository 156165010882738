import { useQuery } from 'react-query';
import {getPhoneNumberSearch} from "../api";

const usePhoneNumberSearch = (phrase, filterType, numberType) => {
  
  const result = useQuery('phone_number_availability_'+phrase+filterType+numberType, () => getPhoneNumberSearch(phrase, filterType, numberType), {
    refetchOnWindowFocus: false,
    staleTime: 5000//0000,
  });
  return result;
};

export default usePhoneNumberSearch;

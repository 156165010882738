import React, {useCallback, useState, useEffect, useMemo, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField, Typography, useMediaQuery, useTheme,
  withStyles,
} from '@material-ui/core';
import {Add, Tune, Create, GroupAdd, Delete, Group, BrightnessAuto} from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';
import {postContactListPreview} from "../../lib/api";
import {prettyPhone, toUnderScoreSlug} from "../../lib/helpers/helpers";
import MarkdownEditor from "@uiw/react-markdown-editor";
import useAI from "../../lib/hooks/useAI";
import AutoSuggest from '@avcs/autosuggest';
import ContextWriting from "./components/ContextWriting";
import Summarize from "./components/Summarize";
import KeywordExtraction from "./components/KeywordExtraction";


const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  previewButton:{
    color: theme.palette.secondary.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AIWriter = ({promptMode = 'AI-Context-Writing'}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [saving, setSaving] = useState(false);
  const [prompt, setPrompt] = useState(promptMode);
  const [debounceQuery, setDebounceQuery] = useState('');
  const [input, setInput] = useState();
  
  const [contextSummary, setContextSummary] = useState();
  const [autocompleteSummary, setAutocompleteSummary] = useState();
  const [grammerSummary, setGrammerSummary] = useState();
  
  const {isLoading: isLoadingAI, isRefetching: isRefetchingAI, data: ai, refetch: refetchAI} = useAI('writer_completion', toUnderScoreSlug(prompt), debounceQuery);
  const { prompt:previousPrompt, response } = useMemo(()=>ai ?? [], [ai]);
  const textareaRef = useRef(null);
  const timeoutIdRef = useRef(null);
  
  useEffect(() => {
    /*
    if (response && response.choices && response.choices.length && response.choices[0] && response.choices[0].text) {
      let newMarkdown = response.choices[0].text;
      if (newMarkdown !== undefined) {
        setMarkdown(markdown + newMarkdown);
      }
    }*/
    if (response) {
      if (response.context && response.context.choices.length && response.context.choices[0] && response.context.choices[0].text) {
        setContextSummary(response.context.choices[0].text)
      }
      if (response.autocomplete && response.autocomplete.choices.length && response.autocomplete.choices[0] && response.autocomplete.choices[0].text) {
        setAutocompleteSummary(response.autocomplete.choices[0].text)
      }
      if (response.grammer && response.grammer.choices.length && response.grammer.choices[0] && response.grammer.choices[0].text) {
        setGrammerSummary(response.grammer.choices[0].text)
      }
    }
    }, [response]);
  
  
  useEffect(() => {
    if (input) { // && input.slice(-2) === "::" || input && input.slice(-2) === "//"
      timeoutIdRef.current = setTimeout(() => {
        setDebounceQuery(input);
      }, 500);
      return () => {
        clearTimeout(timeoutIdRef.current);
      };
    }
  }, [input]);

  
  return (
    <>
                <form noValidate>
                  <Grid item xs={12}>
                       <Select
                          fullWidth
                          aria-label="select from personalities"
                          value={prompt}
                          style={{marginBottom:40}}
                       >
                         {['AI-Context-Writing', 'General Auto-Complete', 'Keyword Extraction', 'Legal Agreement', 'Provide Outline', 'Summarize', 'Summarize Data'].map((item,index)=> <MenuItem key={index} value={item}
                                                                       onClick={(e)=>setPrompt(item)}>
                           <span>{item}</span>
                         </MenuItem>
                         )}
                       </Select>
                  </Grid>
                  {prompt === 'AI-Context-Writing' && <ContextWriting input={input} setInput={setInput} prompt={prompt} debounceQuery={debounceQuery}/>}
                  {prompt === 'Keyword Extraction' && <KeywordExtraction input={input} setInput={setInput} prompt={prompt} debounceQuery={debounceQuery}/>}
                  {prompt === 'Summarize' && <Summarize input={input} setInput={setInput} prompt={prompt} debounceQuery={debounceQuery}/>}
                </form>
    </>
  );
}

export default AIWriter;

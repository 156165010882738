import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction, List, Button, Divider, TextField, Fab, Select, MenuItem
} from '@material-ui/core';
import {Close, Delete, Edit, Save} from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { ContactsContext } from '../../../lib/contexts/ContactsContext';
import useContact from "../../../lib/hooks/useContact";
import {prettyDateTime, toCapitalize} from "../../../lib/helpers/helpers";
import ContactTable from "./ContactTable";
import useActivity from "../../../lib/hooks/useActivity";
import {deleteContactFromHashtag, deleteContactFromList, updateContact} from "../../../lib/api";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import AddContactsToListsDialog from "../../../components/AddContactsToListsDialog/AddContactsToListsDialog";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useContactLists from "../../../lib/hooks/useContactLists";
import useContactHashtags from "../../../lib/hooks/useContactHashtags";
import AddContactsToHashtagsDialog from "../../../components/AddContactsToHashtagsDialog/AddContactsToHashtagsDialog";

import locales from '../../../lib/constants/locales'
import Autocomplete from "@material-ui/lab/Autocomplete";
import timezones from "../../../lib/constants/timezones";
import top_sources from "../../../lib/constants/top_sources";
import all_countries from "../../../lib/constants/countries";

const useStyles = makeStyles(theme => ({
  viewingContactBox:{
    padding:40,
    minWidth:'65vh',
    width:'65vw'
  },
  avatar:{
    height: 100,
    width: 100,
  },
  address:{
     '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
     },
  }
}));
export default function ViewContact({refetch}) {
  const classes = useStyles();
  const {
    state: { viewingContact },
    actions: { resetViewingContact },
  } = useContext(ContactsContext);
  
  const { isLoading, data, refetch:refetchContact } = useContact(viewingContact);
  const { isLoading:isLoadingList, data:dataList, refetch:refetchList } = useContactLists(viewingContact);
  const { isLoading:isLoadingHashtags, data:dataHashtags, refetch:refetchHashtags } = useContactHashtags(viewingContact);
  
  const [contactData, setContactData] = useState({} );
  const { isLoading:activityLoading, data:rawActivityData, refetch:activityRefetch } = useActivity(viewingContact);
  const activityData = useMemo(()=>rawActivityData && !rawActivityData.error ? rawActivityData : [],[rawActivityData]);
  
  const [saving, setSaving] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [organization, setOrganization] = useState('');
  const [dob, setDob] = useState('');
  const [source, setSource] = useState('');
  const [locale, setLocale] = useState('');
  const [timezone, setTimezone] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  
  useEffect(()=>{
    if(data && data.length > 0){
      setContactData(data && !data.error ? data[0] : {})
      setNotFound(false);
    }else{
      setNotFound(true);
    }
  },[data])
  
  useEffect(()=>{
    setFirstName(contactData.first_name)
    setLastName(contactData.last_name)
    setUsername(contactData.username)
    setPhoneNumber(contactData.phone_number)
    setEmail(contactData.email)
    setGender(contactData.gender)
    setOrganization(contactData.organization)
    setDob(contactData.dob)
    setSource(contactData.source)
    setLocale(contactData.locale)
    setTimezone(contactData.timezone)
    setAddress1(contactData.address_1)
    setAddress2(contactData.address_2)
    setCity(contactData.city)
    setState(contactData.state)
    setPostalCode(contactData.postal_code)
    setCountry(contactData.country)
  },[contactData])
  
  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode)
  },[editMode])
  
  const saveContact = useCallback(() => {
    setSaving(true);
    const details = {
      first_name: firstName,
      last_name: lastName,
      username,
      email,
      gender,
      organization,
      dob,
      source,
      locale,
      city,
      state,
      country,
      phone_number: phoneNumber,
      postal_code: postalCode,
      address_1: address1,
      address_2: address2
    };
    
  updateContact(viewingContact, details).then((result)=>{
    setSaving(false);
    setEditMode(false);
    refetchContact();
    });
  },[
    refetch,
    viewingContact,
    firstName,
    lastName,
    username,
    email,
    gender,
    organization,
    dob,
    source,
    locale,
    city,
    state,
    country,
    phoneNumber,
    postalCode,
    address1,
    address2,
  ]
);
  
  const handleClose = useCallback(() =>{
    refetch();
    resetViewingContact();
  },[resetViewingContact, refetch])
  
  const handleContactEdit = useCallback((param, value) => {
    if(param && value && contactData){
      console.log(param,value)
      let modified = contactData;
      modified[param] = value
  
      console.log(modified[param])
      setContactData(modified)
    }
  },[contactData]);
  
  const handleListDelete = useCallback((list) => {
    const confirm = window.confirm('Confirm Deletion: '+list.list_name)
    if(confirm){
      deleteContactFromList( list.id, viewingContact).then(()=>{
        refetchList();
      })
    }
  },[viewingContact, refetchList]);
  
  const handleHashtagDelete = useCallback((tag) => {
    const confirm = window.confirm('Confirm Deletion: #'+tag.hashtag)
    if(confirm){
      deleteContactFromHashtag(tag.id, viewingContact).then(()=>{
        refetchHashtags();
      })
    }
  },[viewingContact, refetchHashtags]);
  
  
  return (
     <Box className={classes.viewingContactBox}>
       {saving && <ProgressBar style={{maxWidth:200,margin:'0 auto'}}/> }
       <Fab style={{float:'right', marginTop:-20}} onClick={handleClose}>
         <Close/>
       </Fab>
       <Fab style={{float:'right', marginTop:-20, marginRight:20}} color={editMode ? 'secondary' : 'primary'} onClick={toggleEditMode} disabled={saving || notFound}>
         <Edit/>
       </Fab>
       {editMode && <Fab style={{float: 'right', marginTop: -20, marginRight: 20}} color={'primary'} disabled={saving || notFound}
             onClick={saveContact}>
         <Save/>
       </Fab>}
       <Typography variant={'h5'} color={'primary'}>Details</Typography>
       <br/>
       <Divider/><br/>
       {notFound && !isLoading && <Typography variant={'h5'} color={'primary'} style={{textAlign:'center', margin:'30vh auto'}}>Contact Not Found</Typography>}
       <form className={classes.root} noValidate autoComplete="off">
         {contactData && !notFound && <Grid container>
           <Grid item xs={11}>
             <Grid container spacing={3}>
               <Grid item xs={2}>
                 <Avatar size={'large'} src={contactData.profile_photo}
                                         className={classes.avatar}/>
               </Grid>
               <Grid item xs={3}>
                 <TextField
                    variant={'outlined'}
                    id="first_name"
                    label={"First Name:"}
                    defaultValue={''}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />
               </Grid>
               <Grid item xs={3}>
                 <TextField
                    variant={'outlined'}
                    id="last_name"
                    label={"Last Name:"}
                    defaultValue={''}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />
               </Grid>
               <Grid item xs={3}>
                 <TextField
                    variant={'outlined'}
                    id="username"
                    label={"Username:"}
                    defaultValue={''}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />
               </Grid>
               <Grid item xs={2}>
                 <ListItemText primary={'Last Updated:'} secondary={prettyDateTime(contactData.updated_at)}/>
               </Grid>
               <Grid item xs={3}>
                 <TextField
                    variant={'outlined'}
                    id="phone_number"
                    label={"Phone Number:"}
                    defaultValue={''}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />
               </Grid>
               <Grid item xs={3}>
                 <TextField
                    variant={'outlined'}
                    id="email"
                    label={"Email:"}
                    defaultValue={''}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />
               </Grid>
               <Grid item xs={3}>
                 <TextField
                    variant={'outlined'}
                    id="gender"
                    label={"Gender:"}
                    defaultValue={''}
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />
               </Grid>
               <Grid item xs={2}></Grid>
               <Grid item xs={3}>
                 <TextField
                    variant={'outlined'}
                    id="organization"
                    label={"Organization:"}
                    defaultValue={''}
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />
               </Grid>
               <Grid item xs={3}>
                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                 <DatePicker
                    variant={'outlined'}
                    id="dob"
                    label={"Birthday:"}
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => <TextField
   
                       {...inputProps}
                                                        InputLabelProps={{shrink: true}}
                                                        variant={'outlined'}
                                                        label={"Birthday:"}
                       ref={inputRef}
                       InputProps={{
                         readOnly: !editMode,
                         endAdornment: editMode ? InputProps?.endAdornment : null
                       }}
                    />}
                 />
                 </LocalizationProvider>
               </Grid>
               <Grid item xs={3}>
                 {
                   !editMode ?
                      <TextField
                         variant={'outlined'}
                         id="address"
                         label={"Address:"}
                         defaultValue={''}
                         multiline={true}
                         value={
                            (contactData.address_1 ? contactData.address_1+'\r\n' : '') +
                            (contactData.address_2 ? contactData.address_2+'\r\n' : '') +
                            (contactData.city ? contactData.city+'\r\n' : '') +
                            (contactData.state ? contactData.state+'\r\n' : '') +
                            (contactData.postal_code ? contactData.postal_code+'\r\n' : '') +
                            (contactData.country ?? '')
                         }
                         InputLabelProps={{shrink: true}}
                         InputProps={{readOnly: true}}
                      />
                      :
                      <Grid container className={classes.address}>
                        <TextField
                           size="small"
                           variant={'outlined'}
                           id="address_1"
                           label={"Address 1:"}
                           defaultValue={''}
                           value={address1}
                           onChange={(e) => setAddress1(e.target.value)}
                           InputLabelProps={{shrink: true}}
                           InputProps={{readOnly: !editMode}}
                        />
                        <TextField
                           size="small"
                           variant={'outlined'}
                           id="address_2"
                           label={"Address 2:"}
                           defaultValue={''}
                           value={address2}
                           onChange={(e) => setAddress2(e.target.value)}
                           InputLabelProps={{shrink: true}}
                           InputProps={{readOnly: !editMode}}
                        />
                        <TextField
                           size="small"
                           variant={'outlined'}
                           id="city"
                           label={"City:"}
                           defaultValue={''}
                           value={city}
                           onChange={(e) => setCity(e.target.value)}
                           InputLabelProps={{shrink: true}}
                           InputProps={{readOnly: !editMode}}
                        />
                        <TextField
                           size="small"
                           variant={'outlined'}
                           id="state"
                           label={"State:"}
                           defaultValue={''}
                           value={state}
                           onChange={(e) => setState(e.target.value)}
                           InputLabelProps={{shrink: true}}
                           InputProps={{readOnly: !editMode}}
                        />
                        <TextField
                           size="small"
                           variant={'outlined'}
                           id="postal_code"
                           label={"Postal Code:"}
                           defaultValue={''}
                           value={postalCode}
                           onChange={(e) => setPostalCode(e.target.value)}
                           InputLabelProps={{shrink: true}}
                           InputProps={{readOnly: !editMode}}
                        />
  
                        {
                          editMode ?
                             <Autocomplete
                                fullWidth={true}
                                id="countries-autocomplete"
                                //onChange={(e,v)=>setCountry(v)}
                                options={all_countries}
                                defaultValue={'United States'}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option) => (
                                   <>
                                     <span><img style={{width:30, marginRight: 20}} src={option.flag}/></span>
                                     {option.name} ({option.code}) {option.phone}
                                   </>
                                )}
                                filterSelectedOptions
                                groupBy={(option) => option ? option.name[0].toUpperCase() : option.offset}
                                renderInput={(params) => (
                                   <TextField
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                      label="Country:"
                                      placeholder="United States"
                                   />
                                )}
                             />
                             :
                        <TextField
                           size="small"
                           variant={'outlined'}
                           id="country"
                           label={"Country:"}
                           defaultValue={''}
                           value={country}
                           onChange={(e) => setCountry(e.target.value)}
                           InputLabelProps={{shrink: true}}
                           InputProps={{readOnly: !editMode}}
                        />}
                      </Grid>
                 }
               </Grid>
               <Grid item xs={2}></Grid>
               
               <Grid item xs={3}>
                 {
                    editMode ?
                       <Autocomplete
                   id="locale-autocomplete"
                   //onChange={(e,v)=>setCampaignRecipientLists(v)}
                   options={locales}
                   defaultValue={'en_US'}
                   getOptionLabel={(option) => option.name + " / " + (option.value || '').toUpperCase()}
                   filterSelectedOptions
                   groupBy={(option) => option ? option.name[0].toUpperCase() : undefined}
                   renderInput={(params) => (
                   <TextField
                 {...params}
                   variant="outlined"
                   label="Select Locale:"
                   placeholder="EN_US, FR_CA, etc"
                   />
                   )}
                   />
                       :
                       <TextField
                          variant={'outlined'}
                          id="locale"
                          label={"Locale:"}
                          defaultValue={'en_US'}
                          value={locale}
                          onChange={(e) => setLocale(e.target.value)}
                          InputLabelProps={{shrink: true}}
                          InputProps={{readOnly: !editMode}}
                       />
                 }
               </Grid>
               <Grid item xs={3}>
                 {
                   editMode ?
                      <Autocomplete
                         id="timezone-autocomplete"
                         //onChange={(e,v)=>setCampaignRecipientLists(v)}
                         options={timezones}
                         defaultValue={'Eastern Standard Time'}
                         getOptionLabel={(option) => option.text}
                         filterSelectedOptions
                         groupBy={(option) => option ? option.abbr[0].toUpperCase() : option.offset}
                         renderInput={(params) => (
                            <TextField
                               {...params}
                               variant="outlined"
                               label="Select Timezone:"
                               placeholder="ET, CT, etc"
                            />
                         )}
                      />
                      :
                      <TextField
                         variant={'outlined'}
                         id="timezone"
                         label={"Timezone:"}
                         defaultValue={''}
                         value={timezone}
                         onChange={(e) => setTimezone(e.target.value)}
                         InputLabelProps={{shrink: true}}
                         InputProps={{readOnly: !editMode}}
                      />
                 }
               </Grid>
               <Grid item xs={3}>
                 {
                 editMode ?
                    <Autocomplete
                       freeSolo
                       id="sources-autocomplete"
                       //onChange={(e,v)=>setCampaignRecipientLists(v)}
                       options={top_sources}
                       defaultValue={'Instagram.com'}
                       getOptionLabel={(option) => toCapitalize((option.value || ''))}
                       filterSelectedOptions
                       renderInput={(params) => (
                          <TextField
                             {...params}
                             variant="outlined"
                             label="Source:"
                             placeholder="Instagram.com"
                          />
                       )}
                    />
                    :
                 <TextField
                    variant={'outlined'}
                    id="source"
                    label={"Source:"}
                    defaultValue={''}
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{readOnly: !editMode}}
                 />}
               </Grid>
               <Grid item xs={2}></Grid>
             </Grid>
             <br/>
             <Divider/>
             <br/>
             <Typography variant={'h5'} color={'primary'}>Metrics</Typography>
             <br/>
             <Grid container item
                   direction="row"
                   justifyContent="space-between"
                   alignItems="center"
             >
               <Button variant={'contained'} color={'primary'}>Emails Sent:<br/>{contactData && contactData.metrics ? contactData.metrics.emails_sent : 0}</Button>
               <Button variant={'contained'} color={'primary'}>SMS Sent:<br/>{contactData && contactData.metrics ? contactData.metrics.sms_sent : 0}</Button>
               <Button variant={'contained'} color={'primary'}>Emails Opened:<br/>{contactData && contactData.metrics ? contactData.metrics.emails_opened : 0}</Button>
               <Button variant={'contained'} color={'primary'}>Links Clicked:<br/>{contactData && contactData.metrics ? contactData.metrics.links_clicked : 0}</Button>
             </Grid>
             <br/>
             <Divider/>
             <br/>
             <Grid container item
                   direction="row"
                   justifyContent="space-between"
                   alignItems="flex-start"
                   spacing={4}
             >
               <Grid item xs={6}>
                 <Grid container item xs={12}
                       direction="row"
                       justifyContent="space-between"
                       alignItems="center"
                 >
                   <Grid item xs={10}>
                     <Typography variant={'h5'} color={'primary'}>Lists:</Typography>
                   </Grid>
                   <Grid item xs={2}>
                     <AddContactsToListsDialog size={'small'} viewingContact={viewingContact} refetch={refetchList}/>
                   </Grid>
                   <Grid item xs={12}>
                     <List style={{maxHeight:250, overflowY:'auto'}}>
                     {dataList && dataList.map(list=><ListItem key={list}>
                       <ListItemText primary={list.list_name}/>
                      <ListItemSecondaryAction>
                        <Button size={'small'} onClick={()=>handleListDelete(list)}><Delete/></Button>
                      </ListItemSecondaryAction>
                     </ListItem>
                     )}
                     </List>
                   </Grid>
                 </Grid>
               </Grid>
               <Grid item xs={6}>
                 <Grid container item xs={12}
                       direction="row"
                       justifyContent="space-between"
                       alignItems="center"
                 >
                   <Grid item xs={10}>
                     <Typography variant={'h5'} color={'primary'}>Tags:</Typography>
                   </Grid>
                   <Grid item xs={2}>
                     <AddContactsToHashtagsDialog size={'small'} refetch={refetchHashtags}/>
                   </Grid>
                   <Grid item xs={12}>
                     <List style={{maxHeight:250, overflowY:'auto'}}>
                     {dataHashtags && dataHashtags.map(tag=><ListItem key={tag}>
                       <ListItemText primary={`#${tag.hashtag}`}/>
                       <ListItemSecondaryAction>
                         <Button size={'small'} onClick={()=>handleHashtagDelete(tag)}><Delete/></Button>
                       </ListItemSecondaryAction>
                     </ListItem>
                     )}
                     </List>
                   </Grid>
                 </Grid>
               </Grid>
             </Grid>
             <br/>
             <Divider/>
             <br/>
             <Typography variant={'h5'} color={'primary'}>Activity</Typography>
             <br/>
             <ContactTable data={activityData}/>
    
           </Grid>
           <Grid item xs={1}>
           </Grid>
         </Grid>}
       </form>
     </Box>
  );
}

import { useQuery } from 'react-query';
import { getPrices } from '../api/getPrices';

const usePrices = () => {
  const result = useQuery('price_list', () => getPrices(), {
    refetchOnWindowFocus: false,
    staleTime: 50000,
  });
  return result;
};

export default usePrices;

import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import {Add, Tune, Create, GroupAdd, Delete, Phone} from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';
import {postApiKey, postVerifiedDomain} from "../../lib/api";
import CopyAPIKey from "../CopyAPIKey/CopyAPIKey";
import {useSnackbar} from "notistack";
import usePermission from "../../lib/hooks/usePermission";
import PhoneNumberPicker from "./PhoneNumberPicker";
import useCheckoutToken from "../../lib/hooks/useCheckoutToken";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const BuyPhoneNumberDialog = ({forceOpen = false, style, setForceOpen, refetch, size, invisible = true, title = undefined}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('organization_settings.edit')
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    if(saved){
      refetch()
    }
    setOpen(!open);
    if(setForceOpen && forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen, setForceOpen, saved]);

  return (
     <>
       {!invisible && <Button size={size} style={style} variant={'contained'} color={'primary'} onClick={hasPermission ? handleToggleOpen : () => enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})} disabled={open || saving}>
         {title ?? 'New Phone Number'}
       </Button>}
       <Dialog
          maxWidth={'md'}
          fullWidth={true}
          open={open}
          onClose={handleToggleOpen}
          aria-labelledby="add-api-key-dialog-form" className={classes.form}
       >
         <DialogTitle id="add-api-key-dialog-title"><Phone/> Purchase a Phone Number</DialogTitle>
         <PhoneNumberPicker handleToggleOpen={handleToggleOpen}/>
       </Dialog>
     
     </>
  );
}

export default BuyPhoneNumberDialog;

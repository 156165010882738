import React, {Component, useCallback, useState} from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  FormHelperText,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItemText, ListItemSecondaryAction, Button, Switch, Divider
} from "@material-ui/core";
import {AddAPIKey, AddApiKeyDialog, CopyAPIKey, CopyEmbedCode, ProgressBar} from '../../../components'
import { DOC_HOST } from '../../../lib/constants/constants'
import useApiKeys from "../../../lib/hooks/useApiKeys";
import {updateVerifiedDomain} from "../../../lib/api/updateVerifiedDomain";
import {updateApiKey} from "../../../lib/api/updateApiKey";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  title:{
    marginTop:20,
    marginBottom:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const APIKeys = () => {
  const classes = useStyles();
  const theme = useTheme();
  
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [apiKey, setApiKey] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const {isLoading, isRefetching, data: apiKeys, refetch} = useApiKeys();
  
  const handleUpdateApiKey = useCallback((apiKey, updateType) => {
    if(apiKey){
      setSaving(true);
      const options = {
           active: false,
         }
      updateApiKey(apiKey.id, options).then(()=>{
        setSaving(false);
        refetch()
      })
    }
  },[refetch])
  
    return (
      <Box className={classes.container}>
        <Typography className={classes.title}>API Keys allow you to utilize SendBoth with our <a href={DOC_HOST} rel="noreferrer" target={'_blank'}>Developer Docs</a>, perfect for custom integrations in your favorite language.</Typography>
        {
          isLoading || isRefetching || saving ?
             <ProgressBar style={{margin:150}}/>
             :
          apiKeys.length ?
           <List>
             {apiKeys.map(item=>{
               return <ListItem key={item.id}>
                 <ListItemText primary={item.name} secondary={item.id.split('-')[0]+'.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'}/>
                 <ListItemSecondaryAction>
                   {item.active ?
                      <Button variant={'contained'} color={"secondary"} onClick={()=>handleUpdateApiKey(item)}>Revoke</Button>
                      : <Button variant={'contained'} color={"secondary"} disabled={true}>Revoked</Button>}
                 </ListItemSecondaryAction>
               </ListItem>
             })}
             
             <Divider style={{marginTop:20,marginBottom:20}}/>
             <ListItem>
               <ListItemText primary={''} secondary={''}/>
               <ListItemSecondaryAction>
                 <AddApiKeyDialog refetch={refetch} />
               </ListItemSecondaryAction>
             </ListItem>
           </List>
           :
        <>
          <Typography className={classes.title}>Let&apos;s generate your first API Key now.</Typography>
          <AddApiKeyDialog refetch={refetch} style={{margin:'auto',display:'block'}} size={'large'} />
        </>
        }
      </Box>
    );
}

export default APIKeys;

import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import useBillingInvoices from '../hooks/useBillingInvoices';
import useBillingSubscriptions from '../hooks/useBillingSubscriptions';
import { UserContext } from './UserContext';

export const BillingContext = createContext({});
export const BillingContextProvider = ({ children, props }) => {
  const {
    state: {user_id},
  } = useContext(UserContext);
  
  const [chosenPlan, setChosenPlan] = useState();
  const [checkingOut, setCheckingOut] = useState(false);
  

 // const { data: invoices } = useBillingInvoices(user_id);
 // const { data: subscriptions } = useBillingSubscriptions(user_id);
 
  
  
  
  return (
    <BillingContext.Provider
      value={{
        actions: {
           setChosenPlan,
           setCheckingOut,
        },
        state: {
           chosenPlan,
           checkingOut,
        },
      }}
      {...props}
    >
      {children}
    </BillingContext.Provider>
  );
};

import  axios from 'axios'

export const loadNewAppointments = async (rowsPerPage, page, token) => {
  const apiURL = `${process.env.REACT_APP_API}/api/admin/list_appointments`

  const requestParams = {
    headers: {cache: 'no-cache'},
    params: {
      per_page: rowsPerPage * 2,
      page: page > 0 ? page : 1,
      token: token,
    }
  }

  try {
    const { data } = await axios.get(apiURL, requestParams)
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateAppointment = async (params) => {
  const apiURL = `${process.env.REACT_APP_API}/api/admin/update_appointment`

  const requestParams = {
    headers: {cache: 'no-cache'},
    params: params
  }

  try {
    const response = await axios.get(apiURL, requestParams);
    return response;
  } catch (error) {
    console.error(error);
  }
};
import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getMetric = async (metricName, metricType, campaignId = null, startDate, endDate) => {
  const apiURL =
     metricType ?
        API_HOST + '/metrics/'+metricType+'/' + metricName+(campaignId != null ? '?campaign_id='+campaignId : '?start_date='+startDate+'&end_date='+endDate) :
        API_HOST + '/metrics/' + metricName+(campaignId != null ? '?campaign_id='+campaignId : '?start_date='+startDate+'&end_date='+endDate);
  
  
  const { token, userID } = useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

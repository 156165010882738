import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  makeStyles,
  ListItemText, ListItemSecondaryAction, Button, Switch, Divider,
} from "@material-ui/core";
import { ProgressBar} from '../../../components'
import { updateUser} from "../../../lib/api";
import useUser from "../../../lib/hooks/useUser";
const useStyles = makeStyles(theme => ({
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const NotificationSettings = () => {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const {isLoading, isRefetching, data: dataUser, refetchUser} = useUser();
  const user = useMemo(()=>dataUser ? dataUser.user : [],[dataUser])
  const { notification_settings } = user;
  const [rows, setRows] = useState([])
  
  useEffect(()=>{
    setRows(notification_settings)
  },[notification_settings])
  
  const groups = [
    { title:'Get notified about our latest features:', slug: 'latest_features' },
    { title:'Get notified when your weekly report is ready:', slug: 'weekly_report' },
    { title:'Get notified when your monthly report is ready:', slug: 'monthly_report' },
    { title:'Get notified when someone replies to you via SMS:', slug: 'sms_reply' },
    { title:'Get notified when a Split-Test Winner has been chosen:', slug: 'split_test_winner' },
    { title:'Get notified when a Campaign finishes:', slug: 'campaign_finished' },
    { title:'Get notified when a new Contact is created via an Embed Widget:', slug: 'widget_contact_created' },
    { title:'Get notified when a new Teammate Invite has been sent:', slug: 'invite_sent' }, //done
    { title:'Get notified when a new Teammate joins or is removed:', slug: 'new_teammate' }, //done
    { title:'Get notified when an API key is created or removed:', slug: 'new_api_key' }, //done
    { title:'Get notified when a Verified Domain is added or removed:', slug: 'new_verified_domain' }, //done
  ]
  
  const updateValue = useCallback((permission)=>{
    const modifiedRows = doesExist(permission) ? rows.filter(item=>!item.includes(permission)) : rows ? [...rows, permission] : [permission]
    setRows(modifiedRows)
  },[rows])
  
  const doesExist = useCallback((permission)=>{
    return Boolean(rows && rows.includes(permission))
  },[rows])
  
  const handleSave = useCallback(()=>{
    setSaving(true)
    updateUser({
      notification_settings: rows
    }).then(()=>{
      setSaving(false)
      refetchUser();
    })
  },[rows])
  
  return (
       <Box className={classes.container}>
         <Typography className={classes.title}>Easily manage your notification settings.</Typography>
         {
           isLoading || isRefetching || saving ?
              <ProgressBar style={{margin:150}}/>
              :
              groups.length &&
                 <List>
                   {groups.map(item=>{
                     return <ListItem key={item}>
                       <ListItemText primary={item.title}/>
                       <ListItemSecondaryAction>
                         <Switch checked={doesExist(item.slug)} onChange={()=>updateValue(item.slug)} />
                       </ListItemSecondaryAction>
                     </ListItem>
                   })}
                   
                   <Divider style={{marginTop:20,marginBottom:20}}/>
                   <ListItem>
                     <ListItemText primary={''} secondary={''}/>
                     <ListItemSecondaryAction>
                       <Button variant={'contained'} color={'standard'} style={{marginRight:10}}>Billing Notifications</Button>
                       <Button variant={'contained'} color={'primary'} onClick={handleSave}>Save</Button>
                     </ListItemSecondaryAction>
                   </ListItem>
                 </List>
         }
       </Box>
    );
}

export default NotificationSettings;

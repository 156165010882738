import * as React from 'react';
import {  Grid, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import useMetric from "../../../lib/hooks/useMetric";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {useParams} from "react-router";
import {useContext} from "react";
import {ThemeContext} from "../../../lib/contexts";
export default function VisitorsByHour() {
   const {campaignId} = useParams();
   const { darkMode } = useContext(ThemeContext);
   const {isLoading, isRefetching, data, refetch} = useMetric('visitors_by_hour', 'visitor', campaignId);
  return (
    <>
      <Grid item xs={3}>
         <Typography variant={'h2'} color={'primary'} style={{marginTop:0}}>
           Visitors By Hour
        </Typography>
         {isLoading || isRefetching && <ProgressBar style={{margin:25}}/>}
         {!(isLoading || isRefetching) && data && <Chart
            options={{
               colors: ['#D9534F', '#FF9800','#546E7A'],
               chart: {
                  type: 'bar',
               },
               plotOptions: {
                  bar: {
                     horizontal: false,
                     columnWidth: '55%',
                     endingShape: 'rounded'
                  },
               },
               stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
               },
               dataLabels: {
                  enabled: false
               },
               xaxis: {
                  categories: data.metric.labels ?? [],
                  labels: {
                     show: true,
                     style: {
                        colors: darkMode ? "#fff" : "#355c7d"
                     }
                  }
               },
               yaxis: {
                  labels: {
                     show: true,
                     style: {
                        colors: darkMode ? "#fff" : "#355c7d"
                     }
                  }
               },
               legend: {
                  labels: {
                     show: true,
                     useSeriesColors: true,
                  }
               }
            }}
            series={[{
               name: 'Desktop',
               data: data.metric.desktop ?? []
            }, {
               name: 'Tablet',
               data: data.metric.tablet ?? []
            }, {
               name: 'Mobile',
               data: data.metric.mobile ?? []
            }
            ]}
            type="bar"
            height={250}
         />}
      </Grid>
    </>
  );
}

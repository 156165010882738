import React, { useContext } from 'react';
import { useParams } from 'react-router';

import { Grid, Typography } from "@material-ui/core";
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from '../../lib/contexts';
import { ListofLists } from './components';
import { SpecificList } from './components';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
}));

const ListManagementScreen = () => {
  const classes = useStyles();
  const {listId} = useParams();
  const {
    state: { first_name },
  } = useContext(UserContext)
  
  return (
    <Screen title={'Viewing List'} showTopNav={true} showFooter={true} permission={'contacts.view'} showBreadcrumbs={listId ? [
      { name: 'Contact Lists', path: '/lists' },
      { name: 'Viewing List', path: '' }
    ] : false}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Typography variant={'subtitle1'}>
               {listId ? 'Viewing List' : 'List Management'}
            </Typography>
            {!listId && <ListofLists/>}
            {listId && <SpecificList/>}
          </Grid>
    </Screen>
  );
};
export default ListManagementScreen;

import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Tune, Create, GroupAdd, Delete } from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';
import {postApiKey, postVerifiedDomain} from "../../lib/api";
import CopyAPIKey from "../CopyAPIKey/CopyAPIKey";
import {useSnackbar} from "notistack";
import usePermission from "../../lib/hooks/usePermission";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const AddApiKeyDialog = ({forceOpen = false, style, setForceOpen, refetch, size, invisible, title = undefined}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [nickname, setNickname] = useState('');
  const [apiKey, setApiKey] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('api_keys.edit')
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    if(saved){
      refetch()
    }
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen, setForceOpen, saved]);
  
  const handleInputChange = useCallback((event) => {
    setNickname(event.target.value);
  },[]);
  
  const handleSave = useCallback(() => {
    const clean = nickname.trim();
    if(clean.length > 3){
      setSaving(true);
      postApiKey(clean).then(response=>{
        if(response && response.key){
          setApiKey(response.key);
          setSaved(true);
          setNickname('')
          //refetch();
        }
        setSaving(false);
        //setOpen(false);
      })
    }
  },[nickname]);
  
  
  return (
     <>
       {!invisible && <Button size={size} style={style} variant={'contained'} color={'primary'} onClick={hasPermission ? handleToggleOpen : () => enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})} disabled={open || saving}>
         {title ?? 'New API Key'}
       </Button>}
       <Dialog
          maxWidth={'sm'}
          fullWidth={true}
          open={open}
          onClose={handleToggleOpen}
          aria-labelledby="add-api-key-dialog-form" className={classes.form}
       >
         <DialogTitle id="add-api-key-dialog-title"><GroupAdd/>Add a new API Key</DialogTitle>
         <DialogContent>
           {
             apiKey ?<>
                  <DialogContentText>
                    WARNING: This API Key will only be shown ONCE. Be sure to save it before closing.
                  </DialogContentText>
                  <CopyAPIKey api={apiKey} />
                </>
                :
             saving ?
                <ProgressBar style={{margin:'50px auto 50px'}}/>
                :
                <>
                  <DialogContentText>
                    Please enter a nickname to generate your new API Key.
                  </DialogContentText>
                  <form noValidate>
                    <AdminGreenTextField
                       autoFocus={true}
                       value={nickname}
                       onChange={handleInputChange}
                       variant={'outlined'}
                       fullWidth={true}
                       label={<><Create/> Nickname</>} placeholder={'e.g., Landing Page'}
                    />
                  </form>
                </>
           }
         </DialogContent>
         <DialogActions>
           <Button onClick={handleToggleOpen}
                   className={classes.cancelButton}
                   disabled={saving}>
             {saved ? 'Close' : 'Cancel'}
           </Button>
           <Button onClick={handleSave}
                   className={classes.addButton}
                   disabled={saving || saved}>
             Generate Key
           </Button>
         </DialogActions>
       </Dialog>
     
     </>
  );
}

export default AddApiKeyDialog;

import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { ProgressBar } from '../ProgressBar';
import {updateUser} from "../../lib/api";
import {Key} from "@mui/icons-material";
import {toCapitalizeFromUnderScore} from "../../lib/helpers/helpers";
import {useSnackbar} from "notistack";
import usePermission from "../../lib/hooks/usePermission";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));

const ManagePermissionsDialog = ({forceOpen = false, setForceOpen, refetch, user, invisible, title = undefined, style, handleInvitePermissions = undefined}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('organization_members.edit')
  
  useEffect(()=>{
    setRows(user.permissions)
  },[user.permissions])
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(undefined)
    }
  },[open, forceOpen, setForceOpen]);
  

  const defaultPerms = [
    {
      type:'visitors.view',
      value: true
    },
    
    {
      type:'contacts.view',
      value: true
    },
    {
      type:'contacts.edit',
      value: true
    },
    {
      type:'contacts.delete',
      value: false
    },
  
    {
      type:'campaigns.view',
      value: true
    },
    {
      type:'campaigns.edit',
      value: true
    },
    {
      type:'campaigns.delete',
      value: true
    },
    {
      type:'campaigns.publish',
      value: true
    },
    
    {
      type:'inbox.view',
      value: true
    },
    {
      type:'inbox.delete',
      value: true
    },
    {
      type:'inbox.publish',
      value: true
    },
  
    {
      type:'templates.view',
      value: true
    },
    {
      type:'templates.edit',
      value: true
    },
    {
      type:'templates.delete',
      value: true
    },
    {
      type:'organization_settings.view',
      value: true
    },
    {
      type:'organization_settings.edit',
      value: true
    },
    {
      type:'organization_members.view',
      value: true
    },
    {
      type:'organization_members.edit',
      value: true
    },
    {
      type:'organization_members.delete',
      value: true
    },
    {
      type:'api_keys.view',
      value: true
    },
    {
      type:'api_keys.edit',
      value: true
    },
    {
      type:'verified_domains.view',
      value: true
    },
    {
      type:'verified_domains.edit',
      value: true
    },
    {
      type:'billing_settings.view',
      value: true
    },
    {
      type:'billing_settings.edit',
      value: true
    },
    {
      type:'assets.view',
      value: true
    },
    {
      type:'assets.edit',
      value: true
    },
    {
      type:'assets.delete',
      value: true
    },
  ]
  
  // todo - before adding new permissions after production launch, merge user.permissions with defaultPerms so the latest types are always available for edit
  const [rows, setRows] = useState(user && user.permissions ? user.permissions : defaultPerms)
  
  
//  const groups = [...new Set(rows.map(item=>item.type.split('.')[0]))] // re-sorts when all are empty or all are false
  const groups = ['visitors', 'contacts', 'campaigns', 'inbox', 'templates', 'assets', 'organization_settings', 'organization_members', 'api_keys', 'verified_domains', 'billing_settings'] // force the order
  
  const handleSave = useCallback(() => {
    setSaving(true);
    const options = {
      permissions: rows
    }
    updateUser(options, user.id).then(()=>{
      setSaving(false);
      setForceOpen(undefined)
      refetch()
    })
  },[refetch, rows])
  
  const updateValue = useCallback((permission, type)=>{
    let modifiedRow =  rows.filter(item=>item.type.includes(permission) && item.type.includes(type))[0]
    const otherRows =  rows.filter(item=>item.type != modifiedRow.type)
    modifiedRow.value = !modifiedRow.value
    setRows([...otherRows, modifiedRow])
  },[rows])
  
  const retrieveValue = useCallback((permission, type)=>{
    return rows.filter(item=>item.type.includes(permission) && item.type.includes(type))[0].value
  },[rows])
  
  const doesExist = useCallback((permission, type)=>{
    return Boolean(rows && rows.filter(item=>item.type.includes(permission) && item.type.includes(type))[0])
  },[rows])
  
  return (
    <>
      {!invisible && <Button variant={'contained'} color={'primary'} onClick={hasPermission ? handleToggleOpen : () => enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})} disabled={open || saving} style={style}>
        {title ?? 'Manage Permissions'}
      </Button>}
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="manage-permissions-dialog-form" className={classes.form}
      >
        <DialogTitle id="manage-permissions-dialog-title"><Key/>Manage Permissions for: {user && user.access_code ? user.name : user.first_name} {user.last_name}</DialogTitle>
        <DialogContent>
          {
            saving ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText style={{textAlign:'center'}}>
                  Quickly adjust various permissions for your teammates.
                </DialogContentText>
                <form noValidate>
                  <TableContainer component={Box}>
                    <Table className={classes.table} aria-label="member permissions" size="small" >
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell align="center">View</TableCell>
                          <TableCell align="center">Manage</TableCell>
                          <TableCell align="center">Delete</TableCell>
                          <TableCell align="center">Send</TableCell>
                        </TableRow>
                      </TableHead>
  
                      {groups.map((item) => {
                        const permission = false; //item.type.split('.')[1]
                        return <TableBody key={item}>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {(() => {
                                switch (item) {
                                  case 'contacts':
                                    return 'Contacts & Contact Lists'
                                  case 'assets':
                                    return 'Asset Management'
                                  default:
                                    return toCapitalizeFromUnderScore(item)
                                }
    
                              })()}
                            </TableCell>
                            <TableCell align="center">{ doesExist(item, 'view') && <Switch checked={retrieveValue(item, 'view')} onChange={()=>updateValue(item, 'view')} /> }</TableCell>
                            <TableCell align="center">{ doesExist(item, 'edit') && <Switch checked={retrieveValue(item, 'edit')} onChange={()=>updateValue(item, 'edit')} /> }</TableCell>
                            <TableCell align="center">{ doesExist(item, 'delete') && <Switch checked={retrieveValue(item, 'delete')} onChange={()=>updateValue(item, 'delete')} /> }</TableCell>
                            <TableCell align="center">{ doesExist(item, 'publish') && <Switch checked={retrieveValue(item, 'publish')} onChange={()=>updateValue(item, 'publish')} /> }</TableCell>
                          </TableRow>
                        </TableBody>
                      })}
                    </Table>
                  </TableContainer>
                </form>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleInvitePermissions !== undefined ? ()=>handleInvitePermissions(user, rows) : handleSave}
                  className={classes.addButton}
                  disabled={saving}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ManagePermissionsDialog;

import React, { useContext, useCallback, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { Button, Paper, Grid, Typography, TextField} from "@material-ui/core";
import ReactDOMServer from 'react-dom/server';
import { Screen, ProgressBar } from '../../../components';
import { makeStyles } from "@material-ui/core/styles";
import 'grapesjs-preset-newsletter';
import { GrapesjsReact } from 'grapesjs-react';
import 'grapesjs/dist/css/grapes.min.css';
import { UserContext } from '../../../lib/contexts';
import useTemplates from '../../../lib/hooks/useTemplates';
import {duplicateTemplate, getAI, updateTemplate} from '../../../lib/api';
import {useNavigate} from "react-router-dom";
import useMergeTags from "../../../lib/hooks/useMergeTags";
import usePermission from "../../../lib/hooks/usePermission";
import {useSnackbar} from "notistack";
import {deleteTemplate} from "../../../lib/api/deleteTemplate";
import {LocalOffer, PhotoFilter, Textsms} from "@material-ui/icons";

const mergeTagPlugin = (editor) => {
  const newType = 'merge-tags';
  
  // Define a component with `textable` property
  editor.Components.addType(newType, {
    model: {
      defaults: {
        tagName: 'span',
        textable: true,
        placeholder: '{{ VARIABLE-1 }}',
      },
      getInnerHTML() {
        return this.get('placeholder');
      },
    },
    // The view below it's just an example for creating a different UX
    view: {
      events: {
        'change': 'updatePlaceholder',
      },
      updatePlaceholder(ev) {
        this.model.set({ placeholder: ev.target.value });
      },
      onAttrUpdate() {
        const viewStyle = 'padding: 0px 5px 1px; border-radius: 3px; border: 1px solid #b300fd';
        this.el.setAttribute('style', viewStyle);
      },
      onRender() {
        const { model, el } = this;
        const select = document.createElement('select');
        const options = [ "FIRST_NAME", "LAST_NAME", "EMAIL", "PHONE_NUMBER", "PROFILE_PHOTO", "BDAY", "USERNAME", "ORGANIZATION", "ADDRESS", "ADDRESS1", "ADDRESS2", "CITY", "STATE", "POSTAL_CODE", "COUNTRY", "LOCALE", "TIMEZONE" ];
        select.innerHTML = options.map(item =>
           `<option value="{{ ${item} }}">${item}</option>`
        ).join('');
        el.innerHTML = '';
        el.appendChild(select);
        select.setAttribute('style', 'padding: 0; border: none; appearance: none;');
        select.value = model.get('placeholder');
      },
    }
  });
  
  editor.Blocks.add(newType, {
    media: ReactDOMServer.renderToString(<LocalOffer style={{width:44,height:44}}/>),
    label: "Merge Tags",
    content: { type: newType },
    select: true,
  });
  
}
const aiTextPlugin = (editor) => {
  const newType = 'ai-text';
  
  // Define a component with `textable` property
  editor.Components.addType(newType, {
    model: {
      defaults: {
        tagName: 'div',
        textContent: "Generating..."
      },
      getInnerHTML() {
        return this.get('textContent');
      },
      async init() {
        this.on('change:textContent', this.handlePropChange, this);
        const prompt = window.prompt("Enter your Prompt for the AI:", "")
        await getAI('writer_completion', "auto_complete_from_template", prompt).then((aiResponse)=>{
          const { response } = aiResponse;
          if (response && response.choices && response.choices.length && response.choices[0] && response.choices[0].text) {
            console.log('response text', response.choices[0].text);
            this.set('textContent', response.choices[0].text);
          }
        });
      },
      initToolbar_disabled() {
        var model = this;
        if(!model.get('toolbar')) {
          var tb = [];
          tb.push({
            attributes: {class: 'fa fa-repeat'}, // icon class from font awesome
            command: 'refresh-ai', //here you need to use your command
          });
          if(model.get('draggable')) {
            tb.push({
              attributes: {class: 'fa fa-arrows'},
              command: 'tlb-move',
            });
          }
          if(model.get('copyable')) {
            tb.push({
              attributes: {class: 'fa fa-clone'},
              command: 'tlb-clone',
            });
          }
          if(model.get('removable')) {
            tb.push({
              attributes: {class: 'fa fa-trash-o'},
              command: 'tlb-delete',
            });
          }
          //model.set('toolbar', tb); // todo - reactive when we can refresh the ai
        }
      },
      handlePropChange() {
        //console.log('The value of textContent', this.get('textContent'));
        this.view.onRender() // force re-rendering when we have a response from AI
      },
    },
    // The view below it's just an example for creating a different UX
    view: {
      init() {
      },
      events: {
        'change': 'updatePlaceholder',
      },
      updatePlaceholder(ev) {
        const { model, el } = this;
        el.innerHTML = model.get('textContent');
      },
      onAttrUpdate() {
        const viewStyle = 'padding: 0px 5px 1px; border-radius: 3px; border: 1px solid #b300fd';
        this.el.setAttribute('style', viewStyle);
      },
      onRender() {
        const { model, el } = this;
        el.innerHTML = model.get('textContent');
        //select.setAttribute('style', 'padding: 0; border: none; appearance: none;');
        //select.value = model.get('placeholder');
        //const attrs = this.model.getAttributes();
        //el.innerText = attrs['customProp'];
      },
    }
  });
  editor.Blocks.add(newType, {
    media: ReactDOMServer.renderToString(<Textsms style={{width:44,height:44}}/>),
    label: "AI Text",
    content: { type: newType },
    select: true,
  });
}
const aiImagePlugin = (editor) => {
  const newType = 'ai-image';
  
  // Define a component with `textable` property
  editor.Components.addType(newType, {
    model: {
      defaults: {
        tagName: 'div',
        textContent: "Generating...",
        alt: "Generating...",
        src: ""
      },
      getInnerHTML() {
        return this.get('textContent');
      },
      async init() {
        this.on('change:src', this.handlePropChange, this);
        this.on('change:textContent', this.handlePropChange, this);
        const prompt = window.prompt("Enter your Prompt for the AI, be as detailed as you want:", "")
        await getAI('image_generation', "auto_complete_from_template", prompt).then((aiResponse)=>{
          const { response } = aiResponse;
          if (response) {
            console.log('response ', response);
            this.set('src', response);
          }
        });
      },
      handlePropChange() {
        //console.log('The value of textContent', this.get('textContent'));
        this.view.onRender() // force re-rendering when we have a response from AI
      },
    },
    // The view below it's just an example for creating a different UX
    view: {
      init() {
      },
      events: {
        'change': 'updatePlaceholder',
      },
      updatePlaceholder(ev) {
        const { model, el } = this;
        el.innerHTML = model.get('textContent');
      },
      onAttrUpdate() {
        const viewStyle = 'padding: 0px 5px 1px; border-radius: 3px; border: 1px solid #b300fd';
        this.el.setAttribute('style', viewStyle);
      },
      onRender() {
        const { model, el } = this;
        const imgSrc = model.get('src')
        if(imgSrc){
          el.innerHTML = ''
          const img = document.createElement('img');
          img.setAttribute('alt', model.get('alt'));
          img.setAttribute('style', 'width:580px; height: 580px; margin: 0; padding: 0; border: none; appearance: none;');
          img.src = model.get('src');
          el.appendChild(img)
        }else{
          el.innerHTML = model.get('textContent');
        }
        //select.setAttribute('style', 'padding: 0; border: none; appearance: none;');
        //select.value = model.get('placeholder');
        //const attrs = this.model.getAttributes();
        //el.innerText = attrs['customProp'];
        
      },
    }
  });
  
  editor.Blocks.add(newType, {
    media: ReactDOMServer.renderToString(<PhotoFilter style={{width:44,height:44}}/>),
    label: "AI Image",
    content: { type: newType },
    select: true,
  });
  
}

const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'30px auto auto',
    padding:20,
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  subtitle1:{
    marginBottom:20
  },
  editor:{
    marginTop:20
  },
}));

const EditTemplate = () => {
  const classes = useStyles();
  const {templateId} = useParams();
  const navigate = useNavigate();
  const hasPermissionTemplatesEdit = usePermission('templates.edit')
  const hasPermissionTemplatesDelete = usePermission('templates.delete')
  const { enqueueSnackbar } = useSnackbar()
  const { isLoading, data, refetch } = useTemplates(templateId);
  const { isLoading:isLoadingMergeTags, data:dataMergeTags, refetch:refetchMergeTags } = useMergeTags(templateId);
  const templateSlug = useMemo(()=>`SB_template_${templateId}`, [templateId]);
  const currentTemplate = useMemo(()=>data && !data.error ? data : [],[data]);
  const currentTemplateUnescaped = useMemo(()=> data && !data.error ? JSON.parse(String(data.raw_template).replace(/&quot;/g, '"')) : [],[data]);
//  const [currentTemplateUnescaped, setCurrentTemplateUnescaped] = useState(null);
  //const templateEndpoint = useMemo(()=>`${API_HOST}/templates/${templateId}`, [API_HOST, templateId]);
  
  useEffect(()=>{
   // setCurrentTemplateUnescaped(data && !data.error ? JSON.parse(String(data.raw_template).replace(/&quot;/g, '"')) : [])
    setTemplateName(currentTemplate.name)
    setSmsSummary(currentTemplate.sms_template)
  },[currentTemplate])
  
  const [editor, setEditor] = useState();
  const [templateName, setTemplateName] = useState();
  const [saving, setSaving] = useState(false);
  const [smsSummary, setSmsSummary] = useState('');
  const {
    state: { first_name },
  } = useContext(UserContext);
  
  const handleEditor = useCallback((e) => {
    setEditor(e)
    const commands = e.Commands;
    commands.add('refresh-ai', (editor, sender) => {
      console.log('here', editor, sender)
    });
  
  },[])
  
  const resetTemplate = useCallback(() => {
    const editedContent = localStorage.setItem(templateSlug, {});
    if(editedContent){
    //  setCurrentTemplateUnescaped({});
    }
  },[templateSlug])
  
  
  const saveTemplate = useCallback(() => {
    if(hasPermissionTemplatesEdit){
      const htmlWithCss = editor.runCommand('gjs-get-inlined-html');
      const editedContent = localStorage.getItem(templateSlug);
      if(editedContent && templateId && templateName){
        setSaving(true);
        const options = {
          template:{
            name: templateName,
            sms_template: smsSummary,
            html_template: htmlWithCss,
            raw_template: editedContent,
          }
        }
        updateTemplate(templateId, options).then(()=>{
          setSaving(false);
        })
      }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[templateSlug, templateId, templateName, smsSummary, editor, hasPermissionTemplatesEdit])
  
  const handleDeleteTemplate = useCallback(() => {
    if(hasPermissionTemplatesDelete){
      const confirm = window.confirm('Confirm Deletion: '+templateName)
      if(confirm) {
        setSaving(true);
        deleteTemplate(templateId).then(response => {
          if (response.success) {
            navigate("../templates/", {replace: true})
          } else {
            //
          }
        })
        setSaving(false);
      }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[templateId, templateName, hasPermissionTemplatesDelete])
  
  const handleDuplicateTemplate = useCallback(() => {
      setSaving(true);
      duplicateTemplate(templateId).then(response=>{
        if(response.success){
          navigate("../template/"+response.id, { replace: true })
        }else{
          //
        }
      })
    setSaving(false);
  },[templateId])
  
  return (
    <Screen title={(currentTemplate.public ? 'Viewing' : 'Editing')+' Template '+currentTemplate.name} permission={'templates.edit'} showTopNav={true} showFooter={true} showBreadcrumbs={[
      { name: 'Templates', path: '/templates' },
      { name: (currentTemplate.public ? 'Viewing' : 'Editing'), path: '' },
      { name: '"'+currentTemplate.name+'"', path: '' },
    ]}>
      <Grid
        container
        alignItems="center"
        spacing={0}
        className={classes.screen}
      >
        <Paper
           className={classes.container}>
  
          <Grid
             container
             direction="row"
             justifyContent="space-evenly"
             alignItems="center"
             spacing={2}
             item xs={12}
          >
            <Grid item xs={12}>
              {!currentTemplate.public && <>
                <Typography variant={'h2'}>Name</Typography>
                <TextField
                 id="template_name"
                 defaultValue={currentTemplate.name}
                 value={templateName}
                 onChange={(e) => setTemplateName(e.target.value)}
              /></>}
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h2'}>SMS Template</Typography>
              <TextField
                 fullWidth={true}
                multiline={true}
                id={'sms_summary'}
                value={smsSummary}
                defaultValue={'A SMS-friendly summary of your Email Template.'}
                 onChange={(e) => setSmsSummary(e.target.value)}
                />
            </Grid>
            <Grid item xs={4}></Grid>
            {currentTemplate.public ?
               <>
                 <Grid item xs={2} style={{margin:"auto",textAlign:'center'}}>
                   <Button disabled={isLoading || saving || (!templateId)} variant={'contained'} color={'primary'} onClick={handleDuplicateTemplate}>Start Editing</Button>
                 </Grid>
               </>
               :
            <>
              <Grid item xs={1}>
                <Button disabled={isLoading || saving || (!templateId)} variant={'contained'} color={'primary'} onClick={saveTemplate}>Save</Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                   onClick={handleDeleteTemplate}
                   disabled={isLoading || saving} variant={'contained'} color={'default'}>Delete</Button>
              </Grid>
            </>
            }
            
            
            <Grid item xs={12} className={classes.editor}>
              {isLoading || saving && <ProgressBar style={{margin:20}}/>}
              <Typography variant={'h2'}>Email Template</Typography>
              {!isLoading && currentTemplateUnescaped && <GrapesjsReact
                 onInit={handleEditor}
                 id='grapesjs-react'
                 plugins={[
                   'gjs-preset-newsletter',
                   'gjs-blocks-basic',
                   aiTextPlugin,
                   aiImagePlugin,
                   mergeTagPlugin,
                 ]}
                 projectData={currentTemplateUnescaped}
                 storageManager={{
                   type: 'local',
                   autosave: true,
                   stepsBeforeSave: 1,
                   autoload: true,
                   
                   //type: 'remote',
                  // stepsBeforeSave: 3,
                   options: {
                     local:{
                       key: templateSlug,
                     },
                   remote: {
                  // urlLoad: templateEndpoint,
                   //urlStore: templateEndpoint,
                     contentTypeJson: true,
                   // The `remote` storage uses the POST method when stores data but the json-server API requires PATCH.
                   fetchOptions: opts => (opts.method === 'POST' ?  { method: 'PATCH' } : {}),
                   // As the API stores projects in this format `{id: 1, data: projectData }`,
                   // we have to properly update the body before the store and extract the
                   // project data from the response result.
                   onStore: data => ({ id: templateId, raw_template: data }),
                   onLoad: result => result.raw_template,
                 }
                 }
                 }
                 }
              />}
            </Grid>
  
  
            <Grid item xs={12} style={{display:'none'}}>
              {Boolean(dataMergeTags) && !currentTemplate.public && <>
                <Typography variant={'h2'}>Merge Tags</Typography>
  
                {isLoadingMergeTags ?
                <ProgressBar style={{margin:40}}/>
                   :
                   <>
                     {Boolean(dataMergeTags) && dataMergeTags.map(item=>{
                       return (
                          <>
                            <TextField
                               id="template_name"
                               defaultValue={currentTemplate.name}
                               value={templateName}
                               onChange={(e) => setTemplateName(e.target.value)}
                            />
                          </>
                       )
                     })}
                   </>
                }
                
                </>}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Screen>
  );
};
export default EditTemplate;

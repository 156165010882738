import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  progressBar:{
    backgroundColor: theme.palette.primary.main
  },
  progressBarColor:{
    backgroundColor: theme.palette.secondary.main
  },
  progressBarLoading:{
    margin:'50px auto 50px',
  },
}));
const ProgressBar = ({style}) => {
  const classes = useStyles();
  return (
            <LinearProgress className={classes.progressBarLoading} classes={{colorPrimary: classes.progressBar, barColorPrimary: classes.progressBarColor}} style={style}/>
  );
};

export default ProgressBar;

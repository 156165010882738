import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Routes, Route, BrowserRouter as Router, useParams } from 'react-router-dom';
import './App.css';
import { CssBaseline, CircularProgress } from '@material-ui/core/';
import { ThemeProvider } from '@material-ui/styles';
import { DarkTheme, LightTheme } from './lib/themes';
//import { ReactSlackChat } from 'react-slack-chat';

//import { ADMIN_PAGE_ROUTE, HOME_PAGE_ROUTE, SETTINGS_PAGE_ROUTE, PUBLIC_URL } from './lib/constants/routes';
import { AdminScreen, InboxScreen, LoginScreen, NotFoundScreenInternal } from './screens';
import {
  CenteredContent,
  Screen,
  PageLoadError,
  UniversalSearch,
  TimeOfDayDialog,
  ChoosePlanDialog,
  AddVerifiedDomainDialog,
} from './components';
//import CacheBuster from './lib/helpers/cacheBuster';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  DashboardScreen,
  ContactsScreen,
  ListManagementScreen,
  CampaignsScreen,
  SettingsScreen,
  ReportsScreen,
  WidgetsScreen,
  TemplatesScreen,
  EditTemplate,
} from './screens/';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { ProgressBar } from './components/ProgressBar';
import {
  UserContext,
  UserContextProvider,
  OrganizationContext,
  OrganizationContextProvider,
  ThemeContext,
  BillingContextProvider,
  CampaignContextProvider,
} from './lib/contexts/';
import OnboardingDialog from './components/OnboardingDialog/OnboardingDialog';
import { getClientHost } from './lib/constants/constants';
import useSession from './lib/hooks/useSession';

import ImportContacts from './components/OnboardingDialog/ImportContacts';
import VisitorsScreen from './screens/VisitorsScreen/VisitorsScreen';
import { AssetManagementScreen } from './screens/AssetManagementScreen';
import { SnackbarProvider } from 'notistack';

import ReactGA from 'react-ga';
import BuyPhoneNumberDialog from './components/BuyPhoneNumberDialog/BuyPhoneNumberDialog';
import Joyride from 'react-joyride';
import { tutorialSteps } from './lib/constants/tutorials';
import AIWriterDialog from './components/AiWriterDialog/AIWriterDialog';
import { AIImageDialog } from './components/AiWriterDialog';
import { EmbeddableChat } from './public_components/EmbeddableChat';
import { FAQScreen } from './screens/FAQScreen';
import { GlossaryScreen } from './screens/GlossaryScreen';
const App = () => {
  ReactGA.initialize('UA-252441591-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
  const {
    state: {
      user_id,
      plan,
      organization,
      phoneNumbers,
      showingSearch,
      showingHelp,
      showingAIWriter,
      showingAIImage,
      showPlanRequiredDialog,
      showOnboardRequiredDialog,
      showPhoneRequiredDialog,
      showVerifiedDomainRequiredDialog,
    },
    actions: {
      forceLogout,
      logout,
      setShowingSearch,
      setShowingHelp,
      setShowingAIWriter,
      setShowingAIImage,
      setShowPlanRequiredDialog,
      setShowOnboardRequiredDialog,
      setShowPhoneRequiredDialog,
      setShowVerifiedDomainRequiredDialog,
    },
  } = useContext(UserContext);
  const { isLoading: sessionLoading, data: sessionDataRaw, refetch: sessionRefetch } = useSession();
  const sessionData = useMemo(
    () => (sessionDataRaw && !sessionDataRaw.error ? sessionDataRaw.user : {}),
    [sessionDataRaw],
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  //  const [showOnboardRequiredDialog, setShowOnboardRequiredDialog] = useState(organization && Boolean( !organization.id));
  //  const [showPhoneRequiredDialog, setShowPhoneRequiredDialog] = useState(Boolean(!phoneNumbers) && Boolean(plan));
  const [pageAccess, setPageAccess] = useState(['login', 'dashboard']);
  const pageSlug = useMemo(
    () => window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
    [window.location],
  );
  //console.log('userObject', userState)
  const {
    state: { code: userRole },
  } = useContext(OrganizationContext);

  useEffect(() => {
    if (!plan) {
      // setShowPlanRequiredDialog(true);
    }
  }, [plan, pageSlug]);

  useEffect(() => {
    if (plan && showPlanRequiredDialog) {
      // setShowPlanRequiredDialog(false);
    }
  }, [plan, showPlanRequiredDialog]);

  useEffect(() => {
    if (sessionData.id || sessionLoading) {
      // logged in or loading
    } else {
      forceLogout();
      //logout()
    }
  }, [sessionData]);

  useEffect(() => {
    if (userRole === '' && pageSlug && pageSlug !== 'docs') {
      // logout()
    }
  }, [userRole]);

  //alert(user_id);
  useEffect(() => {
    setError(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    const callback = event => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyE') {
        event.preventDefault();
        setShowingAIWriter(true);
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyF') {
        event.preventDefault();
        setShowingSearch(true);
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyH') {
        event.preventDefault();
        setShowingHelp(true);
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit1') {
        event.preventDefault();
        window.location.href = getClientHost() + '/';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit2') {
        event.preventDefault();
        window.location.href = getClientHost() + '/contacts';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit3') {
        event.preventDefault();
        window.location.href = getClientHost() + '/lists';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit4') {
        event.preventDefault();
        window.location.href = getClientHost() + '/campaigns';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit5') {
        event.preventDefault();
        window.location.href = getClientHost() + '/reports';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit6') {
        event.preventDefault();
        window.location.href = getClientHost() + '/templates';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit7') {
        event.preventDefault();
        window.location.href = getClientHost() + '/widgets';
      }
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'Digit8') {
        event.preventDefault();
        window.location.href = getClientHost() + '/settings';
      }
      if (event.code === 'Escape') {
        //  setShowingSearch(false)
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);

  const switchComponent = useCallback(slug => {
    switch (slug) {
      case 'login':
        return <LoginScreen />;
      case 'office_admin':
        return <AdminScreen />;
      case 'settings':
        return <></>; //<SettingsScreen />;
      case 'dashboard':
        return <DashboardScreen />;
      case 'homepage':
      case '/':
      default:
        return <DashboardScreen />;
    }
  }, []);

  const renderRoute = useCallback(() => {
    //const { slug } = useParams(); // - not working
    const slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    if (!user_id) {
      return <LoginScreen />;
    } else {
      return pageAccess.includes(slug) ? <>{switchComponent(slug)}</> : <NotFoundScreenInternal />;
    }
  }, [user_id, pageAccess, useParams]);

  if (loading || error) {
    return (
      <Screen>
        <CenteredContent>
          {loading ? <CircularProgress color={'primary'} /> : <PageLoadError message={'Page Load Error'} />}
        </CenteredContent>
      </Screen>
    );
  }

  const wrappedGlossaryScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <GlossaryScreen />
    </React.Suspense>
  );
  const wrappedDashboardScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <DashboardScreen />
    </React.Suspense>
  );
  const wrappedContactsScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <ContactsScreen />
    </React.Suspense>
  );
  const wrappedListManagementScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <ListManagementScreen />
    </React.Suspense>
  );
  const wrappedCampaignsScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <CampaignsScreen />
    </React.Suspense>
  );
  const wrappedReportsScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <ReportsScreen />
    </React.Suspense>
  );
  const wrappedTemplatesScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <TemplatesScreen />
    </React.Suspense>
  );
  const wrappedTemplateEditScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <EditTemplate />
    </React.Suspense>
  );
  const wrappedWidgetsScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <WidgetsScreen />
    </React.Suspense>
  );
  const wrappedSettingsScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <SettingsScreen />
    </React.Suspense>
  );
  const wrappedVisitorsScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <VisitorsScreen />
    </React.Suspense>
  );
  const wrappedInboxScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <InboxScreen />
    </React.Suspense>
  );
  const wrappedAssetManagementScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <AssetManagementScreen />
    </React.Suspense>
  );
  const wrappedFAQScreen = (
    <React.Suspense fallback={<ProgressBar />}>
      <FAQScreen />
    </React.Suspense>
  );

  //  const wrappedPatientsScreen = <React.Suspense fallback={<ProgressBar/>}><PatientsScreen/></React.Suspense>
  //  const wrappedBillingScreen = <React.Suspense fallback={<ProgressBar/>}><BillingScreen/></React.Suspense>
  //  const wrappedAPIDocScreen = <React.Suspense fallback={<ProgressBar/>}><APIDocScreen/></React.Suspense>

  const handleJoyrideCallback = data => {
    const { status, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem('SB_TUTORIAL', true);
    }
    // console.log(type, data);
  };
  return (
    <>
      <Router basename={'/'}>
        <Routes>
          {user_id ? (
            <>
              <Route path="/test" element={<ImportContacts />} />
              <Route path="/" element={wrappedDashboardScreen} />
              <Route path="/login" element={wrappedDashboardScreen} />
              <Route path="/glossary" element={wrappedGlossaryScreen} />

              <Route path="/dashboard" element={wrappedDashboardScreen} />
              <Route path="/visitors" element={wrappedVisitorsScreen} />
              <Route path="/inbox" element={wrappedInboxScreen} />
              <Route path="/contacts" element={wrappedContactsScreen} />
              <Route path="/contact" element={wrappedContactsScreen} />
              <Route path="/contact/:contactId" element={wrappedContactsScreen} />
              <Route path="/lists" element={wrappedListManagementScreen} />
              <Route path="/lists/:listId" element={wrappedListManagementScreen} />
              <Route path="/list/" element={wrappedListManagementScreen} />
              <Route path="/list/:listId" element={wrappedListManagementScreen} />
              <Route path="/campaigns" element={wrappedCampaignsScreen} />
              <Route path="/campaigns/:campaignId" element={wrappedCampaignsScreen} />
              <Route path="/campaign/" element={wrappedCampaignsScreen} />
              <Route path="/campaign/:campaignId" element={wrappedCampaignsScreen} />
              <Route path="/reports" element={wrappedReportsScreen} />
              <Route path="/templates" element={wrappedTemplatesScreen} />
              <Route path="/templates/:templateId" element={wrappedTemplateEditScreen} />
              <Route path="/template/:templateId" element={wrappedTemplateEditScreen} />
              <Route path="/widgets" element={wrappedWidgetsScreen} />
              <Route path="/widgets/:pageSlug" element={wrappedWidgetsScreen} />
              <Route path="/assets" element={wrappedAssetManagementScreen} />
              <Route path="/assets/:pageSlug" element={wrappedAssetManagementScreen} />
              <Route path="/settings" element={wrappedSettingsScreen} />
              <Route path="/settings/:pageSlug" element={wrappedSettingsScreen} />
            </>
          ) : (
            <>
              <Route path="*" element={<LoginScreen />} />
            </>
          )}
          <Route path="/help" element={wrappedFAQScreen} />
          <Route path="/help/tag/:tag" element={wrappedFAQScreen} />
          <Route path="/help/:question" element={wrappedFAQScreen} />
          <Route path="/help/:faqID/:question" element={wrappedFAQScreen} />
          <Route path="/privacy" element={<NotFoundScreenInternal />} />
          <Route path="/terms" element={<NotFoundScreenInternal />} />
          <Route path="*" element={<NotFoundScreenInternal />} />
          {/*
        <Route path=":slug" element={renderRoute()} />
        <Route path=":slug/:subSlug" element={renderRoute()} />
        
        <Route exact path="/:slug?/:sub_slug?" element={renderRoute} />*/}
        </Routes>
        {/*showPlanRequiredDialog && pageSlug !== 'billing' && <SetupPlanDialog setShowPlanRequiredDialog={setShowPlanRequiredDialog}/>*/}
        {showOnboardRequiredDialog && user_id && (
          <OnboardingDialog setShowOnboardRequiredDialog={setShowOnboardRequiredDialog} />
        )}
        {showPlanRequiredDialog && user_id && (
          <ChoosePlanDialog forceOpen={true} setShowPlanRequiredDialog={setShowPlanRequiredDialog} />
        )}
        {showPhoneRequiredDialog && pageSlug !== 'phone_numbers' && user_id && (
          <BuyPhoneNumberDialog forceOpen={true} setShowPhoneRequiredDialog={setShowPhoneRequiredDialog} />
        )}
        {showVerifiedDomainRequiredDialog && pageSlug !== 'domains' && user_id && (
          <AddVerifiedDomainDialog
            forceOpen={true}
            setShowVerifiedDomainRequiredDialog={setShowVerifiedDomainRequiredDialog}
          />
        )}
        {(showingSearch || showingHelp) && <UniversalSearch />}
        {showingAIWriter && <AIWriterDialog forceOpen={true} setShowingAIWriter={setShowingAIWriter} />}
        {showingAIImage && <AIImageDialog forceOpen={true} setShowingAIImage={setShowingAIImage} />}
        {organization &&
          !showOnboardRequiredDialog &&
          !showPlanRequiredDialog &&
          !showPhoneRequiredDialog &&
          !localStorage.getItem('SB_TUTORIAL') && (
            <Joyride
              steps={tutorialSteps}
              continuous
              hideCloseButton
              scrollToFirstStep
              showProgress
              showSkipButton
              callback={handleJoyrideCallback}
            />
          )}
        <EmbeddableChat
          buttonGradient
          organizationID={'6bf28d38-f266-4e22-afcb-8b77f5714b86'}
          // colors={['#eb4e7c', '#e78a51', '#9e9ea8', '#f6f9fc']}
          colors={['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']}
        />
        {/*user_id && <ReactSlackChat
         botName={user_id} // VisitorID, CorpID, Email, IP address etc.
         apiToken='eG94Yi00Mjc0NTI5MTUwOTc3LTQ1OTY5MzQzNjE4NjAtQjdQaHI2NldIUld1cWJxbHJ5d3FtMTlS'
         channels={[
           {
             name: 'support',
           }
         ]}
         //defaultChannel={'support'}
         helpText='Need Help?'
         themeColor='#ea9c87'
         userImage={"https://robohash.org/" + user_id}
         singleUserMode={true}
         debugMode={true}
         closeChatButton={true}
         defaultMessage={"How can we help?"}
      />*/}
      </Router>
      <TimeOfDayDialog />
    </>
  );
};
const ThemeContainerOld = () => {
  const {
    state: { primary_color, secondary_color },
  } = useContext(OrganizationContext);
  return (
    <ThemeProvider theme={LightTheme(primary_color, secondary_color)}>
      {/*<CacheBuster />*/}
      <CssBaseline />
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const ThemeContainer = ({ children }) => {
  const {
    state: { primary_color, secondary_color },
  } = useContext(OrganizationContext);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem('SB_THEME') === 'true' ||
      (!localStorage.getItem('SB_THEME') &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches),
  );
  const toggleTheme = useCallback(() => {
    setDarkMode(!darkMode);
    localStorage.setItem('SB_THEME', !darkMode);
  }, [darkMode]);
  const chosenTheme = useMemo(
    () => (darkMode ? DarkTheme(primary_color, secondary_color) : LightTheme(primary_color, secondary_color)),
    [darkMode],
  );
  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
      <ThemeProvider theme={chosenTheme}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          variant={'info'}
          autoHideDuration={4000}
          preventDuplicate={true}
          maxSnack={6}
        >
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
const AppContainer = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <OrganizationContextProvider>
        <ThemeContainer>
          <UserContextProvider>
            <BillingContextProvider>
              <CampaignContextProvider>
                <App />
              </CampaignContextProvider>
            </BillingContextProvider>
          </UserContextProvider>
        </ThemeContainer>
      </OrganizationContextProvider>
    </QueryClientProvider>
  );
};

export default AppContainer;

import {useEffect, useState} from "react";

export const ENVIRONMENT = [
  // always point native to production
  ['production', location.origin.includes('capacitor://localhost')],
  ['development', !location.origin.includes('capacitor://localhost') && !location.origin.includes('sendboth.com')],
  ['staging', location.origin.includes('staging.sendboth.com')],
  ['production', !location.origin.includes('staging.sendboth.com') && location.origin.includes('sendboth.com')]
].find(([name, isActive]) => isActive)[0]

export const getSubdomainClientHost = (subdomain) =>
  ({
    development: `http://${subdomain}.localhost:3012`,
    staging: `https://${subdomain}.staging.sendboth.com`,
    production: `https://${subdomain}.sendboth.com`
  }[ENVIRONMENT])

export const getClientHost = () =>
  ({
    development: `http://localhost:3012`,
    staging: `https://staging.sendboth.com`,
    production: `https://sendboth.com`
  }[ENVIRONMENT])

export const API_HOST = {
  development: 'http://localhost:5005/api/v1',
  staging: 'https://staging.api.sendboth.com/api/v1',
  production: 'https://api.sendboth.com/api/v1'
}[ENVIRONMENT]

export const DOC_HOST = {
  development: 'http://localhost:5005/api-docs',
  staging: 'https://staging.api.sendboth.com/api-docs',
  production: 'https://api.sendboth.com/api-docs'
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: 'UA-137832774-xx',
  production: 'UA-137832774-xx'
}[ENVIRONMENT]

export const DownloadUrlForOS = () => {
  const [url, setUrl] = useState(null);
  
  useEffect(() => {
    const userAgent = navigator.userAgent;
    
    if (userAgent.includes('Macintosh')) {
      // Check if the user is on an Apple Silicon Mac
      if (userAgent.includes('arm')) {
        // Set the URL for the Mac/Apple Silicon download file
        setUrl('https://s3.amazonaws.com/my-bucket/mac-apple-silicon-download.dmg');
      } else {
        // Set the URL for the Mac/Intel download file
        setUrl('https://s3.amazonaws.com/my-bucket/mac-intel-download.dmg');
      }
    } else if (userAgent.includes('Windows')) {
      // Set the URL for the Windows download file
      setUrl('https://s3.amazonaws.com/my-bucket/windows-download.exe');
    }
  }, []);
  
  return url;
}
export const INDUSTRY_SECTORS = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing"
];

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const postOrganizationJoin = async (organizationID, inviteCode) => {
  const apiURL = API_HOST + '/organizations/join?id='+(organizationID ?? '')+(inviteCode ? '&invite='+inviteCode : '');
  const { token, userID } = useCurrentUser();
  
  try {
    const { data } = await axios.post(apiURL,{},{
      headers: {
      'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
    }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

import React, { useCallback, useEffect, useMemo, useState} from 'react';
import {
  Grid,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  FormControl,
  TextField,
  Divider, ListItemText, ListItemSecondaryAction, Button, Avatar
} from "@material-ui/core";
import timezones from "../../../lib/constants/timezones";
import Autocomplete from "@material-ui/lab/Autocomplete";
import locales from "../../../lib/constants/locales";
import useUser from "../../../lib/hooks/useUser";
import UploadPhotoDialog from "../../../components/UploadPhotoDialog/UploadPhotoDialog";
import { updateUser} from "../../../lib/api";
import useDebounce from "../../../lib/hooks/useDebounce";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const AboutYou = ({hideSave = false}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const {isLoading, isRefetching, data, refetch} = useUser();
  const user = useMemo(()=>data ? data.user : undefined,[data])
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [locale, setLocale] = useState();
  const [timezone, setTimezone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [editPic, setEditPic] = useState('');
  
  const firstNameThrottled = useDebounce(firstName)
  const lastNameThrottled = useDebounce(lastName)
  
  const handleRefetch = useCallback(() => {
    setAvatar(`https://sendboth.s3.amazonaws.com/profile_photos/${user.id}_profile.png?r=${Math.random()}`)
  })
  
  useEffect(()=>{
    if(user && user.id){
      setFirstName(user.first_name)
      setLastName(user.last_name)
      setLocale(locales.filter(item=>item.value === user.locale)[0])
      setTimezone(timezones.filter(item=>String(item.offset === user.timezone))[0])
      setAvatar(`https://sendboth.s3.amazonaws.com/profile_photos/${user.id}_profile.png`)
    }
  },[user, locales, timezones])
  
  useEffect(()=>{
    if(hideSave && (firstNameThrottled || lastNameThrottled || locale || timezone)){
      handleSave()
    }
  },[hideSave, firstNameThrottled, lastNameThrottled, locale, timezone])
  
  const handleSave = useCallback(()=>{
    updateUser({
      first_name: firstNameThrottled,
      last_name: lastNameThrottled,
      locale: locale ? locale.value : '',
      timezone: timezone ? timezone.offset : '',
    }).then(r =>{
     //
    })
  },[firstNameThrottled, lastNameThrottled, locale, timezone])
 
    return (
      <>
      <Grid container spacing={1}>
        <Grid item xs={2} style={{textAlign:'center', alignItems:'center'}}>
          <FormControl variant="outlined">
            <Typography htmlFor="profile_pic"style={{marginTop:5}}>Profile Picture</Typography>
            {avatar && <Avatar id="profile_pic" style={{width: 80, height: 80, margin: '50px auto auto'}} src={avatar}
                     onClick={() => setEditPic(true)}/>}
            <UploadPhotoDialog forceOpen={editPic} setForceOpen={setEditPic} invisible={true} uploadType={'profile_photo'} refetch={handleRefetch}/>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <TextField
            required={true}
            key='first_name'
            id='first_name'
            label='First Name'
            value={firstName}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setFirstName(event.target.value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            required={true}
            key='last_name'
            id='last_name'
            label='Last Name'
            value={lastName}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setLastName(event.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={5}>
          {locales && <Autocomplete
             id="locale-autocomplete"
             onChange={(e, v) => setLocale(v)}
             options={locales}
             defaultValue={{value: 'en_US', name: 'English (United States)'}}
             value={locale}
             getOptionLabel={(option) => option.name + " / " + (option.value || '').toUpperCase()}
             filterSelectedOptions
             groupBy={(option) => option ? option.name[0].toUpperCase() : undefined}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Locale:"
                   placeholder="EN_US, FR_CA, etc"
                />
             )}
          />}
        </Grid>
        <Grid item xs={5}>
          {timezones && <Autocomplete
             id="timezone-autocomplete"
             onChange={(e, v) => setTimezone(v)}
             options={timezones}
             defaultValue={{
               "value": "Eastern Standard Time",
               "abbr": "EST",
               "offset": -5,
               "isdst": false,
               "text": "(UTC-05:00) Eastern Time (US & Canada)",
               "utc": [
                 "America/Detroit",
                 "America/Havana",
                 "America/Indiana/Petersburg",
                 "America/Indiana/Vincennes",
                 "America/Indiana/Winamac",
                 "America/Iqaluit",
                 "America/Kentucky/Monticello",
                 "America/Louisville",
                 "America/Montreal",
                 "America/Nassau",
                 "America/New_York",
                 "America/Nipigon",
                 "America/Pangnirtung",
                 "America/Port-au-Prince",
                 "America/Thunder_Bay",
                 "America/Toronto"
               ]
             }}
             value={timezone}
             getOptionLabel={(option) => option.text}
             filterSelectedOptions
             groupBy={(option) => option ? option.abbr[0].toUpperCase() : option.offset}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Timezone:"
                   placeholder="ET, CT, etc"
                />
             )}
          />}
        </Grid>
        {!hideSave && <Grid item xs={12}>
          <List>
            <Divider style={{marginTop: 20, marginBottom: 20}}/>
            <ListItem>
              <ListItemText primary={''} secondary={''}/>
              <ListItemSecondaryAction>
                <Button variant={'contained'} color={'primary'} onClick={handleSave}>Save</Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>}

      </Grid>
      </>
    );
}

export default AboutYou;

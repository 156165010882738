import React, { useCallback, useState } from 'react';

import {
  Card,
  CardContent, Grid, IconButton,  Menu, MenuItem,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { toCapitalize } from '../../../lib/helpers/helpers';
import {MoreVert, Stars} from '@material-ui/icons';
import { deleteContactList } from '../../../lib/api/deleteContactList';
import { useNavigate } from 'react-router-dom';
import usePermission from "../../../lib/hooks/usePermission";
import {deleteMailboxMessage} from "../../../lib/api";
import {useSnackbar} from "notistack";
const useStyles = makeStyles(theme => ({
  listCard:{
    cursor: 'pointer',
    color: theme.palette.primary.paperText,
    fontWeight: 'bold',
    marginRight:25,
    marginBottom:25,
    minWidth:214,
    maxWidth:400,
    height:50,
    padding:0
  },
  moreButton:{
    marginTop:-5,
    marginRight:-10,
    padding:0
  }
}));

const IndividualCard = ({listItem, refetch}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { id, list_name, dynamic_settings } = listItem;
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar()
  const hasPermissionDelete = usePermission('contacts.delete')
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const managePatientList = useCallback(event => {
    setAnchorEl(event.currentTarget);
  },[])
  
  const deleteList = useCallback(event => {
    if(hasPermissionDelete){
      const confirm = window.confirm('Confirm Deletion: '+list_name)
      if(confirm){
        deleteContactList(id).then(()=>{
          setAnchorEl(null);
          refetch();
        })
      }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[id, hasPermissionDelete])
  
  return (
          <>
            <Card key={'patient_list_'+id} className={classes.listCard}>
              <CardContent>
                <Grid
                  container
                  justifyContent={'space-between'}>
                  <Grid item onClick={()=>navigate("../list/"+id, { replace: true })}>
                    {dynamic_settings && <Stars style={{marginBottom:-6,marginRight:5}}/>}
                    {toCapitalize(list_name)}
                  </Grid>
                  <Grid item className={classes.moreButton}>
                    <IconButton
                      onClick={managePatientList}
                      size={'small'}
                    >
                      <MoreVert/>
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
  
            <Menu
              id="card-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={()=>navigate("../list/"+id, { replace: true })}>Manage</MenuItem>
              <MenuItem onClick={deleteList}>Delete</MenuItem>
            </Menu>
          </>
  );
};

export default IndividualCard;

import React, { useMemo } from 'react';
import { List, Typography } from '@material-ui/core';
import MessageItemRow from './MessageItemRow';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { prettyPhone } from '../../../lib/helpers/helpers';
import useOrganization from '../../../lib/hooks/useOrganization';

export default function MessageList({ setViewingConvo, convos, isLoadingMailbox, refetch }) {
  const oldmessages = [
    {
      id: 1,
      campaign_name: 'Thanksgiving Newsletter',
      chat_id: null,
      campaign_id: 123,
      contact_id: 1231,
      from_phone_number: '+15024578803',
      from_carrier: 'Verizon',
      to_phone_number: '+15024578803',
      to_carrier: 'T-Mobile',
      message: 'Hello from a SMS campaign response',
      media: ['https://...', 'https://...'],
      created_at: new Date(),
    },
    {
      id: 2,
      campaign_name: 'Black Friday',
      chat_id: 1234,
      campaign_id: null,
      contact_id: null,
      from_email: 'me@austincameron.com',
      message: 'Hello from a Chat Box',
      media: [],
      created_at: new Date(),
    },
    {
      id: 3,
      campaign_name: '',
      chat_id: null,
      campaign_id: null,
      contact_id: 122,
      message: 'Hello from random SMS',
      media: ['..'],
      created_at: new Date(),
    },
  ];
  const { data: organization } = useOrganization();
  return (
    <>
      {isLoadingMailbox && <ProgressBar style={{ margin: 100 }} />}
      {!isLoadingMailbox && convos && convos.length === 0 && (
        <Typography color={'textSecondary'} style={{ textAlign: 'center', marginTop: 212, marginBottom: 212 }}>
          No Messages.
        </Typography>
      )}

      <List>
        {!isLoadingMailbox &&
          convos &&
          convos.map((messages, index) => {
            const lastMessage = messages.messages.filter(item => item.identifier != organization.id)[ // messages.messages.filter(item => item.from_phone_number != chosen_phone_number)[0]
              messages.messages.length - 1
            ];
            const badgeCount = messages.messages.filter(item => !item.seen).length;
            return (
              lastMessage && (
                <MessageItemRow
                  value={lastMessage}
                  key={index}
                  onClick={() => setViewingConvo(messages)}
                  badgeCount={badgeCount}
                  refetch={refetch}
                />
              )
            );
          })}
      </List>
    </>
  );
}

import React, { useState } from 'react';

import {Paper, Tabs, Tab, CardMedia, Card, ListItemText, Box} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from '@material-ui/core';
import { Photo, PictureAsPdf} from '@material-ui/icons';
import Typography from "@material-ui/core/Typography";
import {formatBytes} from "../../../lib/helpers/helpers";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container:{
    maxHeight: 700,
    overflowY: 'scroll',
  },
  fileBox:{
    alignItems:'center',
    textAlign:'center',
  },
  fileItem:{
    margin:'40px auto',
    display:'block',
    textAlign:'center',
    padding:'32px 50px 32px',
  }
}));

const FileBrowser = () => {
  const classes = useStyles();
  const [assets, setAssets] = useState([
    {
      id:2,
      name:'photo',
      size:'12345688',
      fileType:'png',
      description:'an example of a png file',
      src:'https://picsum.photos/180'
    },
     {
    id:1,
    name:'pdf file',
    size:'123456',
    fileType:'pdf',
    description:'An example of a pdf file',
    src:'https://aws'
  },
     {
    id:3,
    name:'photo',
    size:'1234562322',
    fileType:'jpg',
    description:'an example of a jpg file',
    src:'https://picsum.photos/181'
  },
     {
    id:4,
    name:'photo',
    size:'12345634534',
    fileType:'jpg',
    description:'an example of a jpg file',
    src:'https://picsum.photos/177'
  },
    {
      id:22,
      name:'photo',
      size:'12345688',
      fileType:'png',
      description:'an example of a png file',
      src:'https://picsum.photos/183'
    },
     {
    id:32,
    name:'photo',
    size:'1234562322',
    fileType:'jpg',
    description:'an example of a jpg file',
    src:'https://picsum.photos/182'
  },
     {
    id:42,
    name:'photo',
    size:'12345634534',
    fileType:'jpg',
    description:'an example of a jpg file',
    src:'https://picsum.photos/178'
  },
    {
      id:1,
      name:'pdf file',
      size:'123456',
      fileType:'pdf',
      description:'An example of a pdf file',
      src:'https://aws'
    },
    {
      id:3,
      name:'photo',
      size:'1234562322',
      fileType:'jpg',
      description:'an example of a jpg file',
      src:'https://picsum.photos/181'
    },
    {
      id:4,
      name:'photo',
      size:'12345634534',
      fileType:'jpg',
      description:'an example of a jpg file',
      src:'https://picsum.photos/177'
    },
    {
      id:22,
      name:'photo',
      size:'12345688',
      fileType:'png',
      description:'an example of a png file',
      src:'https://picsum.photos/183'
    },
  ]); // useAssets()
  return (
    <Grid item container className={classes.container} xs={12} spacing={3} justifyContent={'space-evenly'} alignItems={'center'}>
        {assets && assets.map(item => {
          return <Grid item key={item.id} xs={3}>
            <Card elevation={4} className={classes.fileBox}>
              {['png', 'jpg', 'gif'].some(type => item.fileType.includes(type)) ? item.src ? <img src={item.src} /> : <Photo/> : <Box className={classes.fileItem}><PictureAsPdf fontSize={'large'}/></Box>}
            <Typography>{item.name}</Typography>
              <ListItemText primary={item.description} secondary={formatBytes(item.size)}/>
            </Card>
          </Grid>
        })}
    </Grid>
  );
};

export default FileBrowser;

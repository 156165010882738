import React, { useCallback, useEffect, useMemo, useState} from 'react';
import {
  Select,
  MenuItem,
  InputLabel,
  Grid,
  List,
  ListItem,
  makeStyles,
  useTheme,
  useMediaQuery,
  FormControl,
  TextField,
  Divider, ListItemText, ListItemSecondaryAction, Button, InputAdornment, IconButton, CircularProgress
} from "@material-ui/core";
import { INDUSTRY_SECTORS } from '../../../lib/constants/constants'
import Autocomplete from "@material-ui/lab/Autocomplete";
import locales from "../../../lib/constants/locales";
import timezones from "../../../lib/constants/timezones";
import useOrganization from "../../../lib/hooks/useOrganization";
import {Check, Close} from "@material-ui/icons";
import useVerifiedDomains from "../../../lib/hooks/useVerifiedDomains";
import {toCleanEmailPrefix, toCleanString} from "../../../lib/helpers/helpers";
import useSubdomainAvailability from "../../../lib/hooks/useSubdomainAvailability";
import useDebounce from "../../../lib/hooks/useDebounce";
import {updateOrganization, updateUser} from "../../../lib/api";
import {useSnackbar} from "notistack";
import usePermission from "../../../lib/hooks/usePermission";
import all_countries from "../../../lib/constants/countries";
import AddVerifiedDomainDialog from "../../../components/AddVerifiedDomainDialog/AddVerifiedDomainDialog";
import isBadWord from "../../../lib/constants/bad_words";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const AboutOrganization = ({hideSave = false, onBoardMode = false}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('organization_settings.edit')
  
  const [companyName, setCompanyName] = useState('');
  const [companySubdomain, setCompanySubdomain] = useState('');
  const [defaultReply, setDefaultReply] = useState('');
  const [defaultReplyDomain, setDefaultReplyDomain] = useState('');
  const [newDomain, setNewDomain] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyCountryPhone, setCompanyCountryPhone] = useState('+1');
  const [companyType, setCompanyType] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [companyLocale, setCompanyLocale] = useState('');
  const [companyTimezone, setCompanyTimezone] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [GA, setGA] = useState('');
  const companyNameThrottled = useDebounce(companyName)
  const companySubdomainThrottled = useDebounce(companySubdomain)
  const defaultReplyThrottled = useDebounce(defaultReply)
  const defaultReplyDomainThrottled = useDebounce(defaultReplyDomain)
  const companyWebsiteThrottled = useDebounce(companyWebsite)
  const companyPhoneThrottled = useDebounce(companyPhone)
  const companyDescriptionThrottled = useDebounce(companyDescription)
  const GAThrottled = useDebounce(GA)
  
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  const {isLoading: isLoadingDomains, isRefetching: isRefetchingDomains, data: domains, refetchDomains} = useVerifiedDomains();
  const {isLoading: isLoadingSubdomainAvailability, isRefetching: isRefetchingSubdomainAvailability, data: subdomainAvailability, refetchSubdomainAvailability} = useSubdomainAvailability(companySubdomainThrottled);
  const verifiedDomains = useMemo(()=>domains && domains.length ? domains.map(item=>item.domain) : [defaultReplyDomain],[domains])
  
  useEffect(()=>{
    if(hideSave && (subdomainAvailability || defaultReplyThrottled ||  defaultReplyDomainThrottled ||  companyNameThrottled ||  companyWebsiteThrottled ||  companyPhoneThrottled ||  companyDescriptionThrottled ||  companySize ||  companyType ||  companyLocale ||  companyTimezone ||  GAThrottled)){
      handleSave()
    }
  },[hideSave, subdomainAvailability, defaultReplyThrottled, defaultReplyDomainThrottled, companyNameThrottled, companyWebsiteThrottled, companyPhoneThrottled, companyDescriptionThrottled, companySize, companyType, companyLocale, companyTimezone, GAThrottled])
  
  const handleSave = useCallback(()=>{
    if(hasPermission){
      if(subdomainAvailability){
        updateOrganization({
          subdomain: companySubdomainThrottled
        })
      }
      updateOrganization({
        name: companyNameThrottled,
        default_email: defaultReplyThrottled+"@"+defaultReplyDomainThrottled,
        website: companyWebsiteThrottled,
        phone_number: companyPhoneThrottled ? companyCountryPhone+companyPhoneThrottled : null,
        organization_size: companySize,
        organization_type: companyType,
        description: companyDescriptionThrottled,
        google_analytics: GAThrottled,
        timezone: companyTimezone.offset,
        locale: companyLocale ? companyLocale.value : '',
      }).then(()=>{
        // success
        enqueueSnackbar('Saved!', {variant:'success'})
      })
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[hasPermission, subdomainAvailability, defaultReplyThrottled, defaultReplyDomainThrottled, companyNameThrottled, companyWebsiteThrottled, companyPhoneThrottled, companyDescriptionThrottled, companySize, companyType, companyLocale, companyTimezone, GAThrottled])
  
  
  useEffect(()=>{
    if(organization){
      setCompanyName(organization.name)
      setCompanySubdomain(organization.subdomain)
      setDefaultReply(organization.default_email ? organization.default_email.split('@')[0] : '')
      setDefaultReplyDomain(organization.default_email ? organization.default_email.split('@')[1] : '')
      setCompanyWebsite(organization.website)
      setCompanyPhone(organization.phone_number)
      setCompanySize(organization.organization_size)
      setCompanyType(organization.organization_type)
      setCompanyDescription(organization.description)
      setCompanyLocale(locales.filter(item=>item.value === organization.locale)[0])
      setCompanyTimezone(timezones.filter(item=>String(item.offset === organization.timezone))[0])
      setGA(organization.google_analytics)
    }
  },[organization, verifiedDomains, timezones, locales])
  
  const handleCompanyChange = (event) => {
    setCompanySize(event.target.value);
  };
  const handleTypeChange = (event) => {
    setCompanyType(event.target.value);
  };
  
  const handleNewDomain = (event) => {
    event.stopPropagation()
    setNewDomain(true)
  }
    return (
      <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
             required={true}
             key='company_name'
             id='company_name'
             label='Company Name'
             value={companyName}
             margin='normal'
             variant='outlined'
             fullWidth={true}
             type={'text'}
             onChange={()=>setCompanyName(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
             required={true}
             key='Subdomain'
             id='Subdomain'
             label='SendBoth Username'
             value={companySubdomain}
             margin='normal'
             variant='outlined'
             fullWidth={true}
             error={!subdomainAvailability || companySubdomain.length === 0}
             type={'text'}
             onChange={()=>setCompanySubdomain(toCleanString(event.target.value))}
             InputProps={{
               startAdornment: <InputAdornment position="start">
                   app.sendboth.com/
               </InputAdornment>,
               endAdornment: <InputAdornment position="end">
                 <IconButton
                    aria-label="is username available?"
                 >
                   {isLoadingSubdomainAvailability ? <CircularProgress size={30}/> : subdomainAvailability && !isBadWord(companySubdomain) ? <Check /> : <Close />}
                 </IconButton>
               </InputAdornment>,
             }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            key='website'
            id='website'
            label='Website'
            value={companyWebsite}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setCompanyWebsite(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required={true}
            key='phone'
            id='phone'
            label='Phone'
            value={companyPhone}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setCompanyPhone(event.target.value.replace(/[^0-9]/g, '').substring(0,10))}
            InputProps={{
              maxLength: 10,
              mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
              startAdornment: <InputAdornment position="start">
                <Select
                   aria-label="select from country phone codes"
                   value={companyCountryPhone}
                >
                  {all_countries.map((item)=> <MenuItem key={item.phone} value={item.phone}
                                                                onClick={(e)=>setCompanyCountryPhone(item.phone)}>
                    <img style={{height:30,width:30}} src={item.flag}/>&nbsp;&nbsp;{item.phone}
                  </MenuItem>
                  )}
                </Select>
              </InputAdornment>,
            }}
          />
        </Grid>
        {!onBoardMode && <Grid item xs={6}>
          <Autocomplete
             id="locale-autocomplete"
             onChange={(e, v) => setCompanyLocale(v)}
             options={locales}
             value={companyLocale}
             defaultValue={companyLocale}
             getOptionLabel={(option) => option.name + " / " + (option.value || '').toUpperCase()}
             filterSelectedOptions
             groupBy={(option) => option ? option.name[0].toUpperCase() : undefined}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Locale:"
                   placeholder="EN_US, FR_CA, etc"
                />
             )}
          />
        </Grid>}
        {!onBoardMode && <Grid item xs={6}>
          <Autocomplete
             id="timezone-autocomplete"
             onChange={(e, v) => setCompanyTimezone(v)}
             options={timezones}
             value={companyTimezone}
             defaultValue={companyTimezone}
             getOptionLabel={(option) => option.text}
             filterSelectedOptions
             groupBy={(option) => option ? option.abbr[0].toUpperCase() : option.offset}
             renderInput={(params) => (
                <TextField
                   {...params}
                   variant="outlined"
                   label="Select Timezone:"
                   placeholder="ET, CT, etc"
                />
             )}
          />
        </Grid>}
        <Grid item xs={6}>
          <TextField
             required={true}
             key='DefaultReply'
             id='DefaultReply'
             label='Default Reply Email'
             value={defaultReply}
             margin='normal'
             variant='outlined'
             fullWidth={true}
             type={'text'}
             onChange={()=>setDefaultReply(toCleanEmailPrefix(event.target.value))}
             InputProps={{
               endAdornment: <InputAdornment position="end">
                 <Select
                    aria-label="select from verified domains"
                    value={defaultReplyDomain}
                 >
                   {verifiedDomains.map((item,index)=> <MenuItem key={index} value={item}
                                                                 onClick={(e)=>setDefaultReplyDomain(item)}>@{item}</MenuItem>
                   )}
                   <MenuItem onClick={handleNewDomain}>Add Domain</MenuItem>
                 </Select>
                 <AddVerifiedDomainDialog refetch={refetchDomains} forceOpen={newDomain} invisible={true} />
               </InputAdornment>,
             }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" style={{width:'100%', marginTop:16}}>
            <InputLabel id="company-size-label">Company Size</InputLabel>
            <Select
              required={true}
              labelId="company-size-label"
              id="company-size-outlined"
              value={companySize}
              onChange={handleCompanyChange}
              label="Company Size"
            >
              <MenuItem value={'1-10'}>Less than 10</MenuItem>
              <MenuItem value={'11-50'}>11-50</MenuItem>
              <MenuItem value={'51-99'}>51-99</MenuItem>
              <MenuItem value={'100'}>100+</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" style={{width:'100%', marginTop:16}}>
            <InputLabel id="company-size-label">Company Type</InputLabel>
            <Select
              required={true}
              labelId="company-type-label"
              id="company-type-outlined"
              value={companyType}
              onChange={handleTypeChange}
              label="Company Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {INDUSTRY_SECTORS.map((item, index)=>{
                return (<MenuItem key={index} value={item}>{item}</MenuItem>)
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            key='description'
            id='description'
            label='Company Description'
            placeholder={'We produce...'}
            value={companyDescription}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setCompanyDescription(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key='ga'
            id='ga'
            label='Google Analytics'
            placeholder={'UA-XXXXX'}
            value={GA}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setGA(event.target.value)}
          />
        </Grid>
        {!hideSave && <Grid item xs={12}>
          <List>
            <Divider style={{marginTop: 20, marginBottom: 20}}/>
            <ListItem>
              <ListItemText primary={''} secondary={''}/>
              <ListItemSecondaryAction>
                <Button variant={'contained'} color={'primary'} onClick={handleSave} >Save</Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>}
      </Grid>
      </>
    );
}
const debounce = (fn, delay = 1000) => {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(()=>fn(...args), delay)
  }
}
export default AboutOrganization;

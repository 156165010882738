import { useQuery } from 'react-query';
import {getMergeTags} from "../api";

const useMergeTags = (templateId) => {
  const result = useQuery('mergetags_'+(templateId ?? 'all'), () => getMergeTags(templateId), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useMergeTags;

import { useQuery } from 'react-query';
import {getApiKeys} from "../api";

const useApiKeys = () => {
  const result = useQuery('api_keys', () => getApiKeys(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useApiKeys;

import {createContext, useEffect, useMemo, useState} from 'react';
import useOrganization from "../hooks/useOrganization";
import useVerifiedDomains from "../hooks/useVerifiedDomains";

export const CampaignContext = createContext({});
export const CampaignContextProvider = ({ children, props }) => {
   const [step, setStep] = useState(0);
   
   const [selectedCampaign, setSelectedCampaign] = useState([]);
   const [viewingCampaign, setViewingCampaign] = useState(null);
   
   const [saving, setSaving] = useState(false);
   const [campaignType, setCampaignType] = useState('email' || null);
   const [campaignName, setCampaignName] = useState('' || null);
   const [campaignFromName, setCampaignFromName] = useState('' || null);
   const [campaignFromDomain, setCampaignFromDomain] = useState('' || null);
   const [campaignFromEmail, setCampaignFromEmail] = useState('' || null);
   const [campaignSubject, setCampaignSubject] = useState('' || null);
   const [campaignTemplate, setCampaignTemplate] = useState(null);
   const [campaignSmsTemplate, setCampaignSmsTemplate] = useState('Generic');
   const [campaignRecipientLists, setCampaignRecipientLists] = useState([]);
   const [campaignRecipientContacts, setCampaignRecipientContacts] = useState([]);
   const [deliveryDate, setDeliveryDate] = useState(null);
   const [deliveryTime, setDeliveryTime] = useState(null);
   const [batchDelivery, setBatchDelivery] = useState(false);
   const [batchCount, setBatchCount] = useState(2);
   const [batchTime, setBatchTime] = useState(5);
   const [timewarpDelivery, setTimewarpDelivery] = useState(false);
   const [aiDelivery, setAiDelivery] = useState(false);
   const [recurringType, setRecurringType] = useState('once');
   
   const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
   const {isLoading: isLoadingDomains, isRefetching: isRefetchingDomains, data: domains, refetchDomains} = useVerifiedDomains();
   const verifiedDomains = useMemo(()=>domains && domains.length ? domains.map(item=>item.domain) : [campaignFromEmail],[domains])
   
   useEffect(()=>{
      if(organization){
         setCampaignSubject("The latest from "+organization.name)
         setCampaignFromName(organization.default_email ? organization.default_email.split('@')[0] : '')
         setCampaignFromEmail(organization.default_email ? organization.default_email.split('@')[0] : '')
         //setCampaignFromEmail(organization.default_email ?? organization.default_email)
         setCampaignFromDomain(organization.default_email ? organization.default_email.split('@')[1] : '')
      }
   },[organization, verifiedDomains])
  
  return (
    <CampaignContext.Provider
      value={{
        actions: {
           setSelectedCampaign,
           setViewingCampaign,
           setSaving,
           setCampaignType,
           setCampaignName,
           setCampaignFromName,
           setCampaignFromEmail,
           setCampaignFromDomain,
           setCampaignSubject,
           setCampaignTemplate,
           setCampaignSmsTemplate,
           setCampaignRecipientLists,
           setCampaignRecipientContacts,
           setStep,
           setDeliveryDate,
           setDeliveryTime,
           setBatchDelivery,
           setBatchCount,
           setBatchTime,
           setTimewarpDelivery,
           setAiDelivery,
           setRecurringType,
        },
        state: {
           selectedCampaign,
           saving,
           campaignType,
           campaignName,
           campaignSubject,
           campaignTemplate,
           campaignSmsTemplate,
           campaignRecipientLists,
           campaignRecipientContacts,
           campaignFromName,
           campaignFromEmail,
           campaignFromDomain,
           step,
           viewingCampaign,
           deliveryDate,
           deliveryTime,
           batchDelivery,
           batchCount,
           batchTime,
           timewarpDelivery,
           aiDelivery,
           recurringType,
        }
      }}
      {...props}
    >
      {children}
    </CampaignContext.Provider>
  );
};

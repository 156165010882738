import React, { useContext, useState, useCallback } from 'react';

import { Paper, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,  ListItemIcon,
  ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import { PatientTabsContext } from '../../../lib/contexts/';
import { Close, Person } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container:{
    display:'inline-flex',
    overflowX: 'scroll',
  },
}));

const WidgetTabs = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const handleChange = useCallback((event,newValue)=>{
    setTab(newValue);
  },[])
/*
  const {
    state: { activeTab, openTabs },
    actions: { setActiveTab, closePatientTab },
  } = useContext(PatientTabsContext);
  */
  return (
    <Grid item className={classes.container}>
      
      <Paper className={classes.root}>
        <Tabs
           value={tab}
           onChange={handleChange}
           indicatorColor="primary"
           textColor="primary"
           centered
        >
          <Tab label="Embeddable Sign-Up Form" />
          <Tab label="Embeddable Contact Form" />
          <Tab label="Branded Landing Page" />
          <Tab label="Pop-Up on Page Leave" />
        </Tabs>
      </Paper>
    </Grid>
  );
};

export default WidgetTabs;

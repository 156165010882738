import { useQuery } from 'react-query';
import { getEmbeddableChat } from '../api';
const useEmbeddableChat = (organizationId, contactId, visitorId) => {
  const result = useQuery(
    'chat_' + organizationId + '_' + contactId + '_' + visitorId,
    () => getEmbeddableChat(organizationId, contactId, visitorId),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 3000,
      refetchIntervalInBackground: 5000,
    },
  );
  return result;
};

export default useEmbeddableChat;

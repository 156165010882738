import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const postContactsToLists = async (listIds, contactIds) => {
  const apiURL = API_HOST + '/contact_lists_connections';
  const { token, userID } = useCurrentUser();
  
  try {
    const { data } = await axios.post(apiURL,{
      contact_lists_connections:{
          contact_list_ids: listIds,
          contact_ids: contactIds,
        }
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data.data;
  } catch (error) {
    console.error(error);
  }
};

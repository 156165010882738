import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getContacts = async (listId,exclude=false, contactId = null) => {
  const apiURL = API_HOST +
     (
        contactId
        ?
        '/contacts/'+contactId
        :
        listId
        ?
        '/contacts?listID='+listId+'&exclude='+exclude
        :
        '/contacts'
     );
  const { token, userID } = useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, AvatarGroup } from '@material-ui/lab/';
import {
  makeStyles,
  Avatar,
  Box,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Paper,
} from '@material-ui/core';
import { Close, Flag, Help, LiveHelp, Send } from '@material-ui/icons';
import Convo from './Convo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import useCurrentUser from '../../lib/hooks/useCurrentUser';
import useEmbeddableChat from '../../lib/hooks/useEmbeddableChat';
import useOrganization from '../../lib/hooks/useOrganization';
import useOrganizationFaqs from '../../lib/hooks/useOrganizationFaqs';
import { prettyDateTime, truncate } from '../../lib/helpers/helpers';
import { opacity } from '../../lib/themes';
import cn from 'classnames';

const useStyles = makeStyles(theme => ({
  fabLeft: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 40,
    left: 40,
    transition: 'all 0.3s ease',
    '& *': {
      overflow: 'hidden',
      transition: 'all 0.3s ease',
    },
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  fabRight: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 40,
    right: 40,
    transition: 'all 0.3s ease',
    '& *': {
      overflow: 'hidden',
      transition: 'all 0.3s ease',
    },
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  chatFormClosed: {
    display: 'none',
    position: 'absolute',
    zIndex: -1,
  },
  chatForm: {
    borderStyle: 'solid',
    borderWidth: 2,
    backdropFilter: 'blur(5px)',
    borderRadius: 5,
    minWidth: 400,
    maxWidth: 500,
    maxHeight: 700,
    display: 'block',
    position: 'absolute',
    zIndex: 1010,
    bottom: 70,
    right: 0,
    fontSize: 14,
  },
  chatFormDark: {
    borderColor: theme.palette.grey['500'],
    backgroundColor: opacity('#0F1418', 0.8) || 'rgba(255,255,255, 0.3)' || theme.palette.backgrounds.paper,
    boxShadow: '0 0 16px 0 rgba(255, 255, 255, 0.5) !important',
    color: theme.palette.primary.paperText,
  },
  chatFormLight: {
    borderColor: 'transparent' || theme.palette.grey['200'],
    backgroundColor: 'rgba(255,255,255, 0.2)' || theme.palette.backgrounds.paper,
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.2) !important',
    color: theme.palette.primary.paperText,
  },
  closeButton: {
    float: 'right',
    marginRight: 0,
    color: theme.palette.primary.contrastText,
  },
  clear: {
    clear: 'both',
  },
  pageTitle: {
    fontSize: 24,
    marginBottom: 0,
  },
  title: {
    fontSize: 16,
  },
  statusText: {
    fontSize: 14,
  },
}));

const EmbeddableChat = ({
  variant = 'right',
  buttonGradient = false,
  forceOpen = false,
  organizationID = null,
  contactID = null,
  colors = ['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc'],
}) => {
  const classes = useStyles();
  const [chatOpen, setChatOpen] = useState(false);
  const [isConvoMode, setIsConvoMode] = useState('');
  const [newMessage, setNewMessage] = useState(null);
  const [faqQuery, setFaqQuery] = useState('');
  const { isLoading: isLoadingFaq, data: faqs, refetch: refetchFaq } = useOrganizationFaqs(organizationID);
  const { userID } = useCurrentUser();
  const darkMode = useMemo(
    () =>
      localStorage.getItem('SB_THEME') === 'true' ||
      (!localStorage.getItem('SB_THEME') &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches),
    [localStorage.getItem('SB_THEME')],
  );

  const visitorID = useMemo(() => {
    if (!contactID && !userID) {
      // no contact or user, so assume visitor
      if (localStorage.getItem('SB')) {
        return localStorage.getItem('SB');
      } else {
        return localStorage.setItem('SB', uuidv4());
      }
    } else {
      return null;
    }
  }, [contactID, userID]);
  const {
    isLoading: isLoadingConvo,
    isRefetching: isRefetchingConvo,
    data: convo,
    refetch: refetchConvo,
  } = useEmbeddableChat(organizationID, contactID, visitorID);

  const {
    isLoading: isLoadingOrganization,
    data: organization,
    refetch: refetchOrganization,
  } = useOrganization(organizationID);
  const {
    primary_color,
    secondary_color,
    name,
    subdomain,
    show_powered_by,
    system_status, // 'Issue with Unsubcribe Metrics for region US-East-1, estimated fix at 18:00',
    system_status_last_updated,
  } = organization ? organization : [];

  useEffect(() => {
    if (convo && convo.length) {
      setIsConvoMode(true);
    }
  }, [convo]);

  useEffect(() => {
    if (forceOpen) {
      setChatOpen(forceOpen);
    }
  }, [forceOpen]);

  const handleToggleOpen = useCallback(() => {
    setChatOpen(!chatOpen);
  }, [chatOpen]);

  const handleFAQStart = useCallback((e, v) => {
    setFaqQuery(v);
  }, []);

  useEffect(() => {
    const callback = event => {
      if (!isConvoMode) {
        if (event.code === 'Enter') {
          event.preventDefault();
          setIsConvoMode(true);
        }
      }
      if (event.code === 'Escape') {
        if (isConvoMode) {
          setIsConvoMode(false);
        } else {
          setChatOpen(false);
        }
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [isConvoMode]);

  return (
    <>
      <div className={variant == 'right' ? classes.fabRight : classes.fabLeft}>
        <Fab
          onClick={handleToggleOpen}
          aria-label="live chat"
          style={{
            color: '#fff',
            backgroundColor: colors ? colors[1] : 'initial',
            backgroundImage: buttonGradient ? `linear-gradient(to right, ${colors[0]}, ${colors[1]})` : 'initial',
            transition: 'background-image 0.3s ease',
          }}
          onMouseEnter={event => {
            if (buttonGradient) {
              const newGradient = `linear-gradient(to right, ${colors[1]}, ${colors[0]})`;
              event.target.style.backgroundImage = newGradient;
            }
          }}
          onMouseLeave={event => {
            if (buttonGradient) {
              const originalGradient = `linear-gradient(to right, ${colors[0]}, ${colors[1]})`;
              event.target.style.backgroundImage = originalGradient;
            }
          }}
        >
          <LiveHelp />
        </Fab>
        {chatOpen && (
          <Paper
            className={
              chatOpen
                ? cn(classes.chatForm, darkMode ? classes.chatFormDark : classes.chatFormLight)
                : classes.chatFormClosed
            }
          >
            <Grid
              container
              item
              xs={12}
              justifyContent={'space-between'}
              alignItems={'center'}
              style={{ margin: '5px auto auto', textAlign: 'center' }}
            >
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography className={classes.pageTitle}>
                  <LiveHelp style={{ marginBottom: -4 }} /> Live Chat
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={handleToggleOpen} className={classes.closeButton}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>

            <DialogContent>
              <>
                <DialogContentText style={{ textAlign: 'center' }}>What can we do to help?</DialogContentText>

                {!isConvoMode && (
                  <Card style={{ marginTop: 10 }}>
                    <CardContent>
                      <Typography className={classes.title}>Start a conversation</Typography>
                      <AvatarGroup max={6} style={{ margin: '15px auto 15px', display: 'inline-flex' }}>
                        <Avatar alt="Remy Sharp" src="https://randomuser.me/api/portraits/women/1.jpg" />
                        <Avatar alt="Travis Howard" src="https://randomuser.me/api/portraits/men/2.jpg" />
                        <Avatar alt="Cindy Baker" src="https://randomuser.me/api/portraits/women/3.jpg" />
                        <Avatar alt="Trevor Walker" src="https://randomuser.me/api/portraits/men/4.jpg" />
                        <Avatar alt="Agnes Henderson" src="https://randomuser.me/api/portraits/women/5.jpg" />
                        <Avatar alt="Ben Remy" src="https://randomuser.me/api/portraits/men/6.jpg" />
                        <Avatar alt="Aba Mauriello" src="https://randomuser.me/api/portraits/women/7.jpg" />
                      </AvatarGroup>

                      <TextField
                        autoFocus={false}
                        value={newMessage}
                        onChange={e => {
                          setNewMessage(e.target.value);
                        }}
                        variant={'outlined'}
                        fullWidth={true}
                        label={
                          <>
                            <Send style={{ marginBottom: -6 }} /> New Conversation
                          </>
                        }
                        placeholder={'I need assistance with...'}
                      />
                    </CardContent>
                  </Card>
                )}

                {!isConvoMode && !isLoadingFaq && faqs && (
                  <>
                    <DialogContentText style={{ marginTop: 15, textAlign: 'center' }}>- or -</DialogContentText>

                    <Card style={{ marginTop: 10 }}>
                      <CardContent>
                        <Autocomplete
                          id="locale-autocomplete"
                          onChange={handleFAQStart}
                          options={faqs}
                          value={faqQuery}
                          defaultValue={faqQuery}
                          getOptionLabel={option => option.question}
                          filterSelectedOptions
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant={'outlined'}
                              fullWidth={true}
                              autoFocus={false}
                              label={
                                <>
                                  <Help style={{ marginBottom: -6 }} /> Find an answer quickly
                                </>
                              }
                              placeholder={'Search our Help Center'}
                            />
                          )}
                        />
                      </CardContent>
                    </Card>
                  </>
                )}

                {!isConvoMode && (
                  <Card style={{ marginTop: 15, minHeight: 50, height: 'auto' }}>
                    <CardContent style={{ paddingBottom: 5, paddingTop: 5 }}>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        style={{ margin: '5px auto auto', textAlign: 'center' }}
                      >
                        <Grid item xs={1}>
                          <Flag />
                        </Grid>
                        <Grid item xs={10}>
                          <Tooltip title={system_status ?? ''}>
                            <Typography className={classes.statusText}>
                              Status: {truncate(system_status ? system_status : 'No Issues', 50)}
                            </Typography>
                          </Tooltip>
                          <Typography color={'textSecondary'} className={classes.statusText}>
                            Updated: {prettyDateTime(system_status_last_updated ?? new Date())}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )}
              </>
            </DialogContent>
            {isLoadingConvo && <ProgressBar style={{ margin: '20px auto 20px' }} />}
            {isConvoMode && (
              <Convo
                colors={colors}
                initialMessage={newMessage}
                organizationID={organizationID}
                visitorID={visitorID}
                contactID={contactID}
                convo={convo}
                refetch={refetchConvo}
                setInitialMessage={setNewMessage}
              />
            )}
            {show_powered_by && (
              <DialogActions style={{ marginTop: 5 }}>
                <DialogContentText style={{ textAlign: 'center', paddingTop: 15, margin: '-25px auto 0px' }}>
                  <a
                    href={'https://sendboth.com?referrer=' + window.location.href}
                    target={'_blank'}
                    rel="noreferrer"
                    style={{ color: 'inherit' }}
                  >
                    Powered by SendBoth
                  </a>
                </DialogContentText>
              </DialogActions>
            )}
          </Paper>
        )}
      </div>
    </>
  );
};
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export default EmbeddableChat;

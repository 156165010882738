import React, {useCallback, useState, useEffect, useMemo, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import {Add, Tune, Create, GroupAdd, Delete, Group, BrightnessAuto} from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';
import {postContactListPreview} from "../../lib/api";
import {prettyPhone, toUnderScoreSlug} from "../../lib/helpers/helpers";
import MarkdownEditor from "@uiw/react-markdown-editor";
import useAI from "../../lib/hooks/useAI";
import AutoSuggest from '@avcs/autosuggest';
import AIWriter from "./AIWriter";


const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  previewButton:{
    color: theme.palette.secondary.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const AIWriterDialog = ({forceOpen = false, setForceOpen, refetch, size, invisible, title = undefined, setShowingAIWriter}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [promptMode, setPromptMode] = useState('AI-Context-Writing');
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  useEffect(()=>{
    const self = this;
  },[])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      //setForceOpen(false)
      setShowingAIWriter(false)
    }
  },[open, forceOpen, setForceOpen]);

  
  const handlePrint = useCallback((event) => {
  
  },[]);
  
  
  const handleSave = useCallback(() => {

  },[]);
  
  return (
    <>
      {!invisible && <Button className={size == 'small' ? classes.addPatientListSmall : size == 'text' ? classes.addPatientListText : classes.addPatientList} variant={'contained'}
               onClick={handleToggleOpen} disabled={open || saving}>
        {size != 'text' && <Add style={{marginBottom:-6}}/>} {size == 'text' && title ? title : 'Start Writing'}
      </Button>}
      <Dialog
        maxWidth={"xl"}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-patient-list-dialog-form" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><BrightnessAuto/> AI Writer</DialogTitle>
        <DialogContent>
          {
            saving ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>
                  Choose a prompt from the list and start writing. SendBoth can help with grammar, spelling, auto-completion, outlining, and more.
                  <br/>Pro-Tip: For the best results, use Markdown formatting when writing.
                </DialogContentText>
                <AIWriter promptMode={promptMode}/>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handlePrint}
                   className={classes.previewButton}
                   disabled={saving}>
            Copy
          </Button>
          <Button onClick={handlePrint}
                   className={classes.previewButton}
                   disabled={saving}>
            Print
          </Button>
          <Button onClick={handleSave}
                  className={classes.addButton}
                  disabled={saving}>
            Save to...
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AIWriterDialog;

import React, {useCallback, useState, useEffect, useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {  Backup } from '@material-ui/icons';
import { ProgressBar } from '../ProgressBar';
import useUploadPresign from "../../lib/hooks/useUploadPresign";
import {toCapitalizeFromUnderScore} from "../../lib/helpers/helpers";
import {useDropzone} from "react-dropzone";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  },
  dropzone:{
    maxWidth:'60%',
    margin:'20px auto auto',
    padding:30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  dropzoneActive:{
    maxWidth:'60%',
    margin:'20px auto auto',
    padding:30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
}));


const UploadPhotoDialog = ({forceOpen = false, children, setForceOpen, refetch, size, invisible = false, title = undefined, uploadType = 'Profile Picture'}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [email, setEmail] = useState('');
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  
  
  const {isLoading:presignIsLoading, isRefetching:presignIsRefetching, data:presignData, refetch:presignRefetch} = useUploadPresign(uploadType);
  const presignedUploadUrl = useMemo(()=>presignData ? presignData.presigned_url : null,[presignData])
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen('')
    }
  },[open, forceOpen, setForceOpen]);
  
  const handleInputChange = useCallback((event) => {
    setEmail(event.target.value);
  },[]);
  
  const handleUpload = useCallback(async () => {
    // Let the caller know that a file has been selected and a fetch is beginning.
    
    // Upload the image to our pre-signed URL.
    const response = await fetch(
       new Request(presignedUploadUrl, {
         method: 'PUT',
         body: file,
         headers: new Headers({
           'Content-Type': 'image/*',
         }),
       }),
    );
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      //onError();
      console.log('error')
      console.log(response)
      return;
    }else{
      //console.log(response)
      setSaved(response.url)
    }
    // Let the caller know that the upload is done, so that it can send the URL
    // to the backend again, persisting it to the database.
    //onUploadReady();
  },[file])
  
  
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useS3Upload({
    presignedUploadUrl,
    onUploadStart: event => console.log(event),
    onUploadReady: event => console.log(event),
    onError: event => console.log(event),
    // callbacks here
    getFilesFromEvent: event => myCustomFileGetter(event)
  });
  async function myCustomFileGetter(event) {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    
    for (var i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      
      Object.defineProperty(file, 'myProp', {
        value: true
      });
      
      files.push(file);
    }
    console.log(files);
    return files;
  }
  useEffect(()=>{
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
        //console.log(binaryStr)
        setFile(binaryStr);
      }
      return reader.readAsArrayBuffer(file)
    })
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
        setThumbnail(binaryStr);
      }
      return reader.readAsDataURL(file)
    })
  },[acceptedFiles])
  useEffect(()=>{
    if(saved){
      // save url to db - but dont really need to since we know what it is ahead of time
      refetch();
      setForceOpen(false);
    }
  },[saved])
  return (
    <>
      {!invisible && <Button variant={'contained'} color={'primary'} onClick={handleToggleOpen} disabled={open || saving}>
        {title ?? 'Upload'}
      </Button>}
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-verified-domain-dialog-form" className={classes.form}
      >
        <DialogTitle id="add-verified-domain-dialog-title"><Backup/>Upload a {toCapitalizeFromUnderScore(uploadType)}</DialogTitle>
        <DialogContent>
          {
            saving || !presignedUploadUrl ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <div {...getRootProps({className: isDragActive ? classes.dropzoneActive : classes.dropzone})}>
                  {thumbnail && <Avatar src={thumbnail} variant={'square'} style={{width:250,height:250,margin:'25px auto',display:'block'}}/>
                  }
                  <p>Drag &apos;n&apos; drop any JPG / PNG, or click to select a Photo</p>
                  <input {...getInputProps()} />
                </div>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleUpload}
                  className={classes.addButton}
                  disabled={saving || saved}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  );
}
const useS3Upload = ({
                       presignedUploadUrl,
                       onUploadStart,
                       onUploadReady,
                       onError,
                     }) => {
  
  return useDropzone({
    accept: 'image/*',
    multiple: false,
    disabled: typeof presignedUploadUrl !== 'string',
  });
}
export default UploadPhotoDialog;

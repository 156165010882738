import React, { useState, useEffect, useMemo, useRef} from 'react';
import { Grid } from '@material-ui/core/';

import {
  Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { toUnderScoreSlug} from "../../../lib/helpers/helpers";
import MarkdownEditor from "@uiw/react-markdown-editor";
import useAI from "../../../lib/hooks/useAI";
import AutoSuggest from '@avcs/autosuggest';
const Summarize = ({prompt, debounceQuery, setInput, input}) => {
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  
  const [toddlerSummary, setToddlerSummary] = useState();
  const [childSummary, setChildSummary] = useState();
  const [teenSummary, setTeenSummary] = useState();
  const [adultSummary, setAdultSummary] = useState();
  
  const {isLoading: isLoadingAI, isRefetching: isRefetchingAI, data: ai, refetch: refetchAI} = useAI('writer_completion', toUnderScoreSlug(prompt), debounceQuery);
  const { response } = useMemo(()=>ai ?? [], [ai]);
  const textareaRef = useRef(null);
  
  useEffect(() => {
    if (response) {
      if (response.toddler && response.toddler.choices.length && response.toddler.choices[0] && response.toddler.choices[0].text) {
        setToddlerSummary(response.toddler.choices[0].text)
      }
      if (response.child && response.child.choices.length && response.child.choices[0] && response.child.choices[0].text) {
        setChildSummary(response.child.choices[0].text)
      }
      if (response.teen && response.teen.choices.length && response.teen.choices[0] && response.teen.choices[0].text) {
        setTeenSummary(response.teen.choices[0].text)
      }
      if (response.adult && response.adult.choices.length && response.adult.choices[0] && response.adult.choices[0].text) {
        setAdultSummary(response.adult.choices[0].text)
      }
    }
    }, [response]);
  
  return (
    <>
                  <Grid item xs={12}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                  <Grid item xs={matchSM ? 12 : 6}>
                    <Typography>Editor</Typography>
                    <MarkdownEditor
                       ref={textareaRef}
                       visible={true}
                       style={{minHeight:400, height:610}}
                       //value={markdown}
                       source={input}
                       onChange={(value, viewUpdate) => setInput(value)}
                    />
                  </Grid>
                  <Grid item xs={matchSM ? 12 : 6}>
                    <Grid item xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}>
                      <Grid item xs={6}>
                        <Typography>Summarize (Toddler)</Typography>
                        <MarkdownEditor.Markdown
                           style={{height:300, overflowY:'auto'}}
                           source={toddlerSummary}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>Summarize (Child)</Typography>
                        <MarkdownEditor.Markdown
                           style={{height:300, overflowY:'auto'}}
                           source={childSummary}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>Summarize (Teen)</Typography>
                        <MarkdownEditor.Markdown
                           style={{height:300, overflowY:'auto'}}
                           source={teenSummary}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>Summarize (Adult)</Typography>
                        <MarkdownEditor.Markdown
                           style={{height:300, overflowY:'auto'}}
                           source={adultSummary}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>
    </>
  );
}

export default Summarize;

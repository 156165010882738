const locales = [
  { "value" : "af", "name" : "Afrikaans"},
  { "value" : "af_NA", "name" : "Afrikaans (Namibia)"},
  { "value" : "af_ZA", "name" : "Afrikaans (South Africa)"},
  { "value" : "ak", "name" : "Akan"},
  { "value" : "ak_GH", "name" : "Akan (Ghana)"},
  { "value" : "sq", "name" : "Albanian"},
  { "value" : "sq_AL", "name" : "Albanian (Albania)"},
  { "value" : "sq_XK", "name" : "Albanian (Kosovo)"},
  { "value" : "sq_MK", "name" : "Albanian (Macedonia)"},
  { "value" : "am", "name" : "Amharic"},
  { "value" : "am_ET", "name" : "Amharic (Ethiopia)"},
  { "value" : "ar", "name" : "Arabic"},
  { "value" : "ar_DZ", "name" : "Arabic (Algeria)"},
  { "value" : "ar_BH", "name" : "Arabic (Bahrain)"},
  { "value" : "ar_TD", "name" : "Arabic (Chad)"},
  { "value" : "ar_KM", "name" : "Arabic (Comoros)"},
  { "value" : "ar_DJ", "name" : "Arabic (Djibouti)"},
  { "value" : "ar_EG", "name" : "Arabic (Egypt)"},
  { "value" : "ar_ER", "name" : "Arabic (Eritrea)"},
  { "value" : "ar_IQ", "name" : "Arabic (Iraq)"},
  { "value" : "ar_IL", "name" : "Arabic (Israel)"},
  { "value" : "ar_JO", "name" : "Arabic (Jordan)"},
  { "value" : "ar_KW", "name" : "Arabic (Kuwait)"},
  { "value" : "ar_LB", "name" : "Arabic (Lebanon)"},
  { "value" : "ar_LY", "name" : "Arabic (Libya)"},
  { "value" : "ar_MR", "name" : "Arabic (Mauritania)"},
  { "value" : "ar_MA", "name" : "Arabic (Morocco)"},
  { "value" : "ar_OM", "name" : "Arabic (Oman)"},
  { "value" : "ar_PS", "name" : "Arabic (Palestinian Territories)"},
  { "value" : "ar_QA", "name" : "Arabic (Qatar)"},
  { "value" : "ar_SA", "name" : "Arabic (Saudi Arabia)"},
  { "value" : "ar_SO", "name" : "Arabic (Somalia)"},
  { "value" : "ar_SS", "name" : "Arabic (South Sudan)"},
  { "value" : "ar_SD", "name" : "Arabic (Sudan)"},
  { "value" : "ar_SY", "name" : "Arabic (Syria)"},
  { "value" : "ar_TN", "name" : "Arabic (Tunisia)"},
  { "value" : "ar_AE", "name" : "Arabic (United Arab Emirates)"},
  { "value" : "ar_EH", "name" : "Arabic (Western Sahara)"},
  { "value" : "ar_YE", "name" : "Arabic (Yemen)"},
  { "value" : "hy", "name" : "Armenian"},
  { "value" : "hy_AM", "name" : "Armenian (Armenia)"},
  { "value" : "as", "name" : "Assamese"},
  { "value" : "as_IN", "name" : "Assamese (India)"},
  { "value" : "az", "name" : "Azerbaijani"},
  { "value" : "az_AZ", "name" : "Azerbaijani (Azerbaijan)"},
  { "value" : "az_Cyrl_AZ", "name" : "Azerbaijani (Cyrillic, Azerbaijan)"},
  { "value" : "az_Cyrl", "name" : "Azerbaijani (Cyrillic)"},
  { "value" : "az_Latn_AZ", "name" : "Azerbaijani (Latin, Azerbaijan)"},
  { "value" : "az_Latn", "name" : "Azerbaijani (Latin)"},
  { "value" : "bm", "name" : "Bambara"},
  { "value" : "bm_Latn_ML", "name" : "Bambara (Latin, Mali)"},
  { "value" : "bm_Latn", "name" : "Bambara (Latin)"},
  { "value" : "eu", "name" : "Basque"},
  { "value" : "eu_ES", "name" : "Basque (Spain)"},
  { "value" : "be", "name" : "Belarusian"},
  { "value" : "be_BY", "name" : "Belarusian (Belarus)"},
  { "value" : "bn", "name" : "Bengali"},
  { "value" : "bn_BD", "name" : "Bengali (Bangladesh)"},
  { "value" : "bn_IN", "name" : "Bengali (India)"},
  { "value" : "bs", "name" : "Bosnian"},
  { "value" : "bs_BA", "name" : "Bosnian (Bosnia & Herzegovina)"},
  { "value" : "bs_Cyrl_BA", "name" : "Bosnian (Cyrillic, Bosnia & Herzegovina)"},
  { "value" : "bs_Cyrl", "name" : "Bosnian (Cyrillic)"},
  { "value" : "bs_Latn_BA", "name" : "Bosnian (Latin, Bosnia & Herzegovina)"},
  { "value" : "bs_Latn", "name" : "Bosnian (Latin)"},
  { "value" : "br", "name" : "Breton"},
  { "value" : "br_FR", "name" : "Breton (France)"},
  { "value" : "bg", "name" : "Bulgarian"},
  { "value" : "bg_BG", "name" : "Bulgarian (Bulgaria)"},
  { "value" : "my", "name" : "Burmese"},
  { "value" : "my_MM", "name" : "Burmese (Myanmar (Burma))"},
  { "value" : "ca", "name" : "Catalan"},
  { "value" : "ca_AD", "name" : "Catalan (Andorra)"},
  { "value" : "ca_FR", "name" : "Catalan (France)"},
  { "value" : "ca_IT", "name" : "Catalan (Italy)"},
  { "value" : "ca_ES", "name" : "Catalan (Spain)"},
  { "value" : "zh", "name" : "Chinese"},
  { "value" : "zh_CN", "name" : "Chinese (China)"},
  { "value" : "zh_HK", "name" : "Chinese (Hong Kong SAR China)"},
  { "value" : "zh_MO", "name" : "Chinese (Macau SAR China)"},
  { "value" : "zh_Hans_CN", "name" : "Chinese (Simplified, China)"},
  { "value" : "zh_Hans_HK", "name" : "Chinese (Simplified, Hong Kong SAR China)"},
  { "value" : "zh_Hans_MO", "name" : "Chinese (Simplified, Macau SAR China)"},
  { "value" : "zh_Hans_SG", "name" : "Chinese (Simplified, Singapore)"},
  { "value" : "zh_Hans", "name" : "Chinese (Simplified)"},
  { "value" : "zh_SG", "name" : "Chinese (Singapore)"},
  { "value" : "zh_TW", "name" : "Chinese (Taiwan)"},
  { "value" : "zh_Hant_HK", "name" : "Chinese (Traditional, Hong Kong SAR China)"},
  { "value" : "zh_Hant_MO", "name" : "Chinese (Traditional, Macau SAR China)"},
  { "value" : "zh_Hant_TW", "name" : "Chinese (Traditional, Taiwan)"},
  { "value" : "zh_Hant", "name" : "Chinese (Traditional)"},
  { "value" : "kw", "name" : "Cornish"},
  { "value" : "kw_GB", "name" : "Cornish (United Kingdom)"},
  { "value" : "hr", "name" : "Croatian"},
  { "value" : "hr_BA", "name" : "Croatian (Bosnia & Herzegovina)"},
  { "value" : "hr_HR", "name" : "Croatian (Croatia)"},
  { "value" : "cs", "name" : "Czech"},
  { "value" : "cs_CZ", "name" : "Czech (Czech Republic)"},
  { "value" : "da", "name" : "Danish"},
  { "value" : "da_DK", "name" : "Danish (Denmark)"},
  { "value" : "da_GL", "name" : "Danish (Greenland)"},
  { "value" : "nl", "name" : "Dutch"},
  { "value" : "nl_AW", "name" : "Dutch (Aruba)"},
  { "value" : "nl_BE", "name" : "Dutch (Belgium)"},
  { "value" : "nl_BQ", "name" : "Dutch (Caribbean Netherlands)"},
  { "value" : "nl_CW", "name" : "Dutch (Cura\u00e7ao)"},
  { "value" : "nl_NL", "name" : "Dutch (Netherlands)"},
  { "value" : "nl_SX", "name" : "Dutch (Sint Maarten)"},
  { "value" : "nl_SR", "name" : "Dutch (Suriname)"},
  { "value" : "dz", "name" : "Dzongkha"},
  { "value" : "dz_BT", "name" : "Dzongkha (Bhutan)"},
  { "value" : "en", "name" : "English"},
  { "value" : "en_AS", "name" : "English (American Samoa)"},
  { "value" : "en_AI", "name" : "English (Anguilla)"},
  { "value" : "en_AG", "name" : "English (Antigua & Barbuda)"},
  { "value" : "en_AU", "name" : "English (Australia)"},
  { "value" : "en_BS", "name" : "English (Bahamas)"},
  { "value" : "en_BB", "name" : "English (Barbados)"},
  { "value" : "en_BE", "name" : "English (Belgium)"},
  { "value" : "en_BZ", "name" : "English (Belize)"},
  { "value" : "en_BM", "name" : "English (Bermuda)"},
  { "value" : "en_BW", "name" : "English (Botswana)"},
  { "value" : "en_IO", "name" : "English (British Indian Ocean Territory)"},
  { "value" : "en_VG", "name" : "English (British Virgin Islands)"},
  { "value" : "en_CM", "name" : "English (Cameroon)"},
  { "value" : "en_CA", "name" : "English (Canada)"},
  { "value" : "en_KY", "name" : "English (Cayman Islands)"},
  { "value" : "en_CX", "name" : "English (Christmas Island)"},
  { "value" : "en_CC", "name" : "English (Cocos (Keeling) Islands)"},
  { "value" : "en_CK", "name" : "English (Cook Islands)"},
  { "value" : "en_DG", "name" : "English (Diego Garcia)"},
  { "value" : "en_DM", "name" : "English (Dominica)"},
  { "value" : "en_ER", "name" : "English (Eritrea)"},
  { "value" : "en_FK", "name" : "English (Falkland Islands)"},
  { "value" : "en_FJ", "name" : "English (Fiji)"},
  { "value" : "en_GM", "name" : "English (Gambia)"},
  { "value" : "en_GH", "name" : "English (Ghana)"},
  { "value" : "en_GI", "name" : "English (Gibraltar)"},
  { "value" : "en_GD", "name" : "English (Grenada)"},
  { "value" : "en_GU", "name" : "English (Guam)"},
  { "value" : "en_GG", "name" : "English (Guernsey)"},
  { "value" : "en_GY", "name" : "English (Guyana)"},
  { "value" : "en_HK", "name" : "English (Hong Kong SAR China)"},
  { "value" : "en_IN", "name" : "English (India)"},
  { "value" : "en_IE", "name" : "English (Ireland)"},
  { "value" : "en_IM", "name" : "English (Isle of Man)"},
  { "value" : "en_JM", "name" : "English (Jamaica)"},
  { "value" : "en_JE", "name" : "English (Jersey)"},
  { "value" : "en_KE", "name" : "English (Kenya)"},
  { "value" : "en_KI", "name" : "English (Kiribati)"},
  { "value" : "en_LS", "name" : "English (Lesotho)"},
  { "value" : "en_LR", "name" : "English (Liberia)"},
  { "value" : "en_MO", "name" : "English (Macau SAR China)"},
  { "value" : "en_MG", "name" : "English (Madagascar)"},
  { "value" : "en_MW", "name" : "English (Malawi)"},
  { "value" : "en_MY", "name" : "English (Malaysia)"},
  { "value" : "en_MT", "name" : "English (Malta)"},
  { "value" : "en_MH", "name" : "English (Marshall Islands)"},
  { "value" : "en_MU", "name" : "English (Mauritius)"},
  { "value" : "en_FM", "name" : "English (Micronesia)"},
  { "value" : "en_MS", "name" : "English (Montserrat)"},
  { "value" : "en_NA", "name" : "English (Namibia)"},
  { "value" : "en_NR", "name" : "English (Nauru)"},
  { "value" : "en_NZ", "name" : "English (New Zealand)"},
  { "value" : "en_NG", "name" : "English (Nigeria)"},
  { "value" : "en_NU", "name" : "English (Niue)"},
  { "value" : "en_NF", "name" : "English (Norfolk Island)"},
  { "value" : "en_MP", "name" : "English (Northern Mariana Islands)"},
  { "value" : "en_PK", "name" : "English (Pakistan)"},
  { "value" : "en_PW", "name" : "English (Palau)"},
  { "value" : "en_PG", "name" : "English (Papua New Guinea)"},
  { "value" : "en_PH", "name" : "English (Philippines)"},
  { "value" : "en_PN", "name" : "English (Pitcairn Islands)"},
  { "value" : "en_PR", "name" : "English (Puerto Rico)"},
  { "value" : "en_RW", "name" : "English (Rwanda)"},
  { "value" : "en_WS", "name" : "English (Samoa)"},
  { "value" : "en_SC", "name" : "English (Seychelles)"},
  { "value" : "en_SL", "name" : "English (Sierra Leone)"},
  { "value" : "en_SG", "name" : "English (Singapore)"},
  { "value" : "en_SX", "name" : "English (Sint Maarten)"},
  { "value" : "en_SB", "name" : "English (Solomon Islands)"},
  { "value" : "en_ZA", "name" : "English (South Africa)"},
  { "value" : "en_SS", "name" : "English (South Sudan)"},
  { "value" : "en_SH", "name" : "English (St. Helena)"},
  { "value" : "en_KN", "name" : "English (St. Kitts & Nevis)"},
  { "value" : "en_LC", "name" : "English (St. Lucia)"},
  { "value" : "en_VC", "name" : "English (St. Vincent & Grenadines)"},
  { "value" : "en_SD", "name" : "English (Sudan)"},
  { "value" : "en_SZ", "name" : "English (Swaziland)"},
  { "value" : "en_TZ", "name" : "English (Tanzania)"},
  { "value" : "en_TK", "name" : "English (Tokelau)"},
  { "value" : "en_TO", "name" : "English (Tonga)"},
  { "value" : "en_TT", "name" : "English (Trinidad & Tobago)"},
  { "value" : "en_TC", "name" : "English (Turks & Caicos Islands)"},
  { "value" : "en_TV", "name" : "English (Tuvalu)"},
  { "value" : "en_UM", "name" : "English (U.S. Outlying Islands)"},
  { "value" : "en_VI", "name" : "English (U.S. Virgin Islands)"},
  { "value" : "en_UG", "name" : "English (Uganda)"},
  { "value" : "en_GB", "name" : "English (United Kingdom)"},
  { "value" : "en_US", "name" : "English (United States)"},
  { "value" : "en_VU", "name" : "English (Vanuatu)"},
  { "value" : "en_ZM", "name" : "English (Zambia)"},
  { "value" : "en_ZW", "name" : "English (Zimbabwe)"},
  { "value" : "eo", "name" : "Esperanto"},
  { "value" : "et", "name" : "Estonian"},
  { "value" : "et_EE", "name" : "Estonian (Estonia)"},
  { "value" : "ee", "name" : "Ewe"},
  { "value" : "ee_GH", "name" : "Ewe (Ghana)"},
  { "value" : "ee_TG", "name" : "Ewe (Togo)"},
  { "value" : "fo", "name" : "Faroese"},
  { "value" : "fo_FO", "name" : "Faroese (Faroe Islands)"},
  { "value" : "fi", "name" : "Finnish"},
  { "value" : "fi_FI", "name" : "Finnish (Finland)"},
  { "value" : "fr", "name" : "French"},
  { "value" : "fr_DZ", "name" : "French (Algeria)"},
  { "value" : "fr_BE", "name" : "French (Belgium)"},
  { "value" : "fr_BJ", "name" : "French (Benin)"},
  { "value" : "fr_BF", "name" : "French (Burkina Faso)"},
  { "value" : "fr_BI", "name" : "French (Burundi)"},
  { "value" : "fr_CM", "name" : "French (Cameroon)"},
  { "value" : "fr_CA", "name" : "French (Canada)"},
  { "value" : "fr_CF", "name" : "French (Central African Republic)"},
  { "value" : "fr_TD", "name" : "French (Chad)"},
  { "value" : "fr_KM", "name" : "French (Comoros)"},
  { "value" : "fr_CG", "name" : "French (Congo - Brazzaville)"},
  { "value" : "fr_CD", "name" : "French (Congo - Kinshasa)"},
  { "value" : "fr_CI", "name" : "French (C\u00f4te d\u2019Ivoire)"},
  { "value" : "fr_DJ", "name" : "French (Djibouti)"},
  { "value" : "fr_GQ", "name" : "French (Equatorial Guinea)"},
  { "value" : "fr_FR", "name" : "French (France)"},
  { "value" : "fr_GF", "name" : "French (French Guiana)"},
  { "value" : "fr_PF", "name" : "French (French Polynesia)"},
  { "value" : "fr_GA", "name" : "French (Gabon)"},
  { "value" : "fr_GP", "name" : "French (Guadeloupe)"},
  { "value" : "fr_GN", "name" : "French (Guinea)"},
  { "value" : "fr_HT", "name" : "French (Haiti)"},
  { "value" : "fr_LU", "name" : "French (Luxembourg)"},
  { "value" : "fr_MG", "name" : "French (Madagascar)"},
  { "value" : "fr_ML", "name" : "French (Mali)"},
  { "value" : "fr_MQ", "name" : "French (Martinique)"},
  { "value" : "fr_MR", "name" : "French (Mauritania)"},
  { "value" : "fr_MU", "name" : "French (Mauritius)"},
  { "value" : "fr_YT", "name" : "French (Mayotte)"},
  { "value" : "fr_MC", "name" : "French (Monaco)"},
  { "value" : "fr_MA", "name" : "French (Morocco)"},
  { "value" : "fr_NC", "name" : "French (New Caledonia)"},
  { "value" : "fr_NE", "name" : "French (Niger)"},
  { "value" : "fr_RE", "name" : "French (R\u00e9union)"},
  { "value" : "fr_RW", "name" : "French (Rwanda)"},
  { "value" : "fr_SN", "name" : "French (Senegal)"},
  { "value" : "fr_SC", "name" : "French (Seychelles)"},
  { "value" : "fr_BL", "name" : "French (St. Barth\u00e9lemy)"},
  { "value" : "fr_MF", "name" : "French (St. Martin)"},
  { "value" : "fr_PM", "name" : "French (St. Pierre & Miquelon)"},
  { "value" : "fr_CH", "name" : "French (Switzerland)"},
  { "value" : "fr_SY", "name" : "French (Syria)"},
  { "value" : "fr_TG", "name" : "French (Togo)"},
  { "value" : "fr_TN", "name" : "French (Tunisia)"},
  { "value" : "fr_VU", "name" : "French (Vanuatu)"},
  { "value" : "fr_WF", "name" : "French (Wallis & Futuna)"},
  { "value" : "ff", "name" : "Fulah"},
  { "value" : "ff_CM", "name" : "Fulah (Cameroon)"},
  { "value" : "ff_GN", "name" : "Fulah (Guinea)"},
  { "value" : "ff_MR", "name" : "Fulah (Mauritania)"},
  { "value" : "ff_SN", "name" : "Fulah (Senegal)"},
  { "value" : "gl", "name" : "Galician"},
  { "value" : "gl_ES", "name" : "Galician (Spain)"},
  { "value" : "lg", "name" : "Ganda"},
  { "value" : "lg_UG", "name" : "Ganda (Uganda)"},
  { "value" : "ka", "name" : "Georgian"},
  { "value" : "ka_GE", "name" : "Georgian (Georgia)"},
  { "value" : "de", "name" : "German"},
  { "value" : "de_AT", "name" : "German (Austria)"},
  { "value" : "de_BE", "name" : "German (Belgium)"},
  { "value" : "de_DE", "name" : "German (Germany)"},
  { "value" : "de_LI", "name" : "German (Liechtenstein)"},
  { "value" : "de_LU", "name" : "German (Luxembourg)"},
  { "value" : "de_CH", "name" : "German (Switzerland)"},
  { "value" : "el", "name" : "Greek"},
  { "value" : "el_CY", "name" : "Greek (Cyprus)"},
  { "value" : "el_GR", "name" : "Greek (Greece)"},
  { "value" : "gu", "name" : "Gujarati"},
  { "value" : "gu_IN", "name" : "Gujarati (India)"},
  { "value" : "ha", "name" : "Hausa"},
  { "value" : "ha_GH", "name" : "Hausa (Ghana)"},
  { "value" : "ha_Latn_GH", "name" : "Hausa (Latin, Ghana)"},
  { "value" : "ha_Latn_NE", "name" : "Hausa (Latin, Niger)"},
  { "value" : "ha_Latn_NG", "name" : "Hausa (Latin, Nigeria)"},
  { "value" : "ha_Latn", "name" : "Hausa (Latin)"},
  { "value" : "ha_NE", "name" : "Hausa (Niger)"},
  { "value" : "ha_NG", "name" : "Hausa (Nigeria)"},
  { "value" : "he", "name" : "Hebrew"},
  { "value" : "he_IL", "name" : "Hebrew (Israel)"},
  { "value" : "hi", "name" : "Hindi"},
  { "value" : "hi_IN", "name" : "Hindi (India)"},
  { "value" : "hu", "name" : "Hungarian"},
  { "value" : "hu_HU", "name" : "Hungarian (Hungary)"},
  { "value" : "is", "name" : "Icelandic"},
  { "value" : "is_IS", "name" : "Icelandic (Iceland)"},
  { "value" : "ig", "name" : "Igbo"},
  { "value" : "ig_NG", "name" : "Igbo (Nigeria)"},
  { "value" : "id", "name" : "Indonesian"},
  { "value" : "id_ID", "name" : "Indonesian (Indonesia)"},
  { "value" : "ga", "name" : "Irish"},
  { "value" : "ga_IE", "name" : "Irish (Ireland)"},
  { "value" : "it", "name" : "Italian"},
  { "value" : "it_IT", "name" : "Italian (Italy)"},
  { "value" : "it_SM", "name" : "Italian (San Marino)"},
  { "value" : "it_CH", "name" : "Italian (Switzerland)"},
  { "value" : "ja", "name" : "Japanese"},
  { "value" : "ja_JP", "name" : "Japanese (Japan)"},
  { "value" : "kl", "name" : "Kalaallisut"},
  { "value" : "kl_GL", "name" : "Kalaallisut (Greenland)"},
  { "value" : "kn", "name" : "Kannada"},
  { "value" : "kn_IN", "name" : "Kannada (India)"},
  { "value" : "ks", "name" : "Kashmiri"},
  { "value" : "ks_Arab_IN", "name" : "Kashmiri (Arabic, India)"},
  { "value" : "ks_Arab", "name" : "Kashmiri (Arabic)"},
  { "value" : "ks_IN", "name" : "Kashmiri (India)"},
  { "value" : "kk", "name" : "Kazakh"},
  { "value" : "kk_Cyrl_KZ", "name" : "Kazakh (Cyrillic, Kazakhstan)"},
  { "value" : "kk_Cyrl", "name" : "Kazakh (Cyrillic)"},
  { "value" : "kk_KZ", "name" : "Kazakh (Kazakhstan)"},
  { "value" : "km", "name" : "Khmer"},
  { "value" : "km_KH", "name" : "Khmer (Cambodia)"},
  { "value" : "ki", "name" : "Kikuyu"},
  { "value" : "ki_KE", "name" : "Kikuyu (Kenya)"},
  { "value" : "rw", "name" : "Kinyarwanda"},
  { "value" : "rw_RW", "name" : "Kinyarwanda (Rwanda)"},
  { "value" : "ko", "name" : "Korean"},
  { "value" : "ko_KP", "name" : "Korean (North Korea)"},
  { "value" : "ko_KR", "name" : "Korean (South Korea)"},
  { "value" : "ky", "name" : "Kyrgyz"},
  { "value" : "ky_Cyrl_KG", "name" : "Kyrgyz (Cyrillic, Kyrgyzstan)"},
  { "value" : "ky_Cyrl", "name" : "Kyrgyz (Cyrillic)"},
  { "value" : "ky_KG", "name" : "Kyrgyz (Kyrgyzstan)"},
  { "value" : "lo", "name" : "Lao"},
  { "value" : "lo_LA", "name" : "Lao (Laos)"},
  { "value" : "lv", "name" : "Latvian"},
  { "value" : "lv_LV", "name" : "Latvian (Latvia)"},
  { "value" : "ln", "name" : "Lingala"},
  { "value" : "ln_AO", "name" : "Lingala (Angola)"},
  { "value" : "ln_CF", "name" : "Lingala (Central African Republic)"},
  { "value" : "ln_CG", "name" : "Lingala (Congo - Brazzaville)"},
  { "value" : "ln_CD", "name" : "Lingala (Congo - Kinshasa)"},
  { "value" : "lt", "name" : "Lithuanian"},
  { "value" : "lt_LT", "name" : "Lithuanian (Lithuania)"},
  { "value" : "lu", "name" : "Luba-Katanga"},
  { "value" : "lu_CD", "name" : "Luba-Katanga (Congo - Kinshasa)"},
  { "value" : "lb", "name" : "Luxembourgish"},
  { "value" : "lb_LU", "name" : "Luxembourgish (Luxembourg)"},
  { "value" : "mk", "name" : "Macedonian"},
  { "value" : "mk_MK", "name" : "Macedonian (Macedonia)"},
  { "value" : "mg", "name" : "Malagasy"},
  { "value" : "mg_MG", "name" : "Malagasy (Madagascar)"},
  { "value" : "ms", "name" : "Malay"},
  { "value" : "ms_BN", "name" : "Malay (Brunei)"},
  { "value" : "ms_Latn_BN", "name" : "Malay (Latin, Brunei)"},
  { "value" : "ms_Latn_MY", "name" : "Malay (Latin, Malaysia)"},
  { "value" : "ms_Latn_SG", "name" : "Malay (Latin, Singapore)"},
  { "value" : "ms_Latn", "name" : "Malay (Latin)"},
  { "value" : "ms_MY", "name" : "Malay (Malaysia)"},
  { "value" : "ms_SG", "name" : "Malay (Singapore)"},
  { "value" : "ml", "name" : "Malayalam"},
  { "value" : "ml_IN", "name" : "Malayalam (India)"},
  { "value" : "mt", "name" : "Maltese"},
  { "value" : "mt_MT", "name" : "Maltese (Malta)"},
  { "value" : "gv", "name" : "Manx"},
  { "value" : "gv_IM", "name" : "Manx (Isle of Man)"},
  { "value" : "mr", "name" : "Marathi"},
  { "value" : "mr_IN", "name" : "Marathi (India)"},
  { "value" : "mn", "name" : "Mongolian"},
  { "value" : "mn_Cyrl_MN", "name" : "Mongolian (Cyrillic, Mongolia)"},
  { "value" : "mn_Cyrl", "name" : "Mongolian (Cyrillic)"},
  { "value" : "mn_MN", "name" : "Mongolian (Mongolia)"},
  { "value" : "ne", "name" : "Nepali"},
  { "value" : "ne_IN", "name" : "Nepali (India)"},
  { "value" : "ne_NP", "name" : "Nepali (Nepal)"},
  { "value" : "nd", "name" : "North Ndebele"},
  { "value" : "nd_ZW", "name" : "North Ndebele (Zimbabwe)"},
  { "value" : "se", "name" : "Northern Sami"},
  { "value" : "se_FI", "name" : "Northern Sami (Finland)"},
  { "value" : "se_NO", "name" : "Northern Sami (Norway)"},
  { "value" : "se_SE", "name" : "Northern Sami (Sweden)"},
  { "value" : "no", "name" : "Norwegian"},
  { "value" : "no_NO", "name" : "Norwegian (Norway)"},
  { "value" : "nb", "name" : "Norwegian Bokm\u00e5l"},
  { "value" : "nb_NO", "name" : "Norwegian Bokm\u00e5l (Norway)"},
  { "value" : "nb_SJ", "name" : "Norwegian Bokm\u00e5l (Svalbard & Jan Mayen)"},
  { "value" : "nn", "name" : "Norwegian Nynorsk"},
  { "value" : "nn_NO", "name" : "Norwegian Nynorsk (Norway)"},
  { "value" : "or", "name" : "Oriya"},
  { "value" : "or_IN", "name" : "Oriya (India)"},
  { "value" : "om", "name" : "Oromo"},
  { "value" : "om_ET", "name" : "Oromo (Ethiopia)"},
  { "value" : "om_KE", "name" : "Oromo (Kenya)"},
  { "value" : "os", "name" : "Ossetic"},
  { "value" : "os_GE", "name" : "Ossetic (Georgia)"},
  { "value" : "os_RU", "name" : "Ossetic (Russia)"},
  { "value" : "ps", "name" : "Pashto"},
  { "value" : "ps_AF", "name" : "Pashto (Afghanistan)"},
  { "value" : "fa", "name" : "Persian"},
  { "value" : "fa_AF", "name" : "Persian (Afghanistan)"},
  { "value" : "fa_IR", "name" : "Persian (Iran)"},
  { "value" : "pl", "name" : "Polish"},
  { "value" : "pl_PL", "name" : "Polish (Poland)"},
  { "value" : "pt", "name" : "Portuguese"},
  { "value" : "pt_AO", "name" : "Portuguese (Angola)"},
  { "value" : "pt_BR", "name" : "Portuguese (Brazil)"},
  { "value" : "pt_CV", "name" : "Portuguese (Cape Verde)"},
  { "value" : "pt_GW", "name" : "Portuguese (Guinea-Bissau)"},
  { "value" : "pt_MO", "name" : "Portuguese (Macau SAR China)"},
  { "value" : "pt_MZ", "name" : "Portuguese (Mozambique)"},
  { "value" : "pt_PT", "name" : "Portuguese (Portugal)"},
  { "value" : "pt_ST", "name" : "Portuguese (S\u00e3o Tom\u00e9 & Pr\u00edncipe)"},
  { "value" : "pt_TL", "name" : "Portuguese (Timor-Leste)"},
  { "value" : "pa", "name" : "Punjabi"},
  { "value" : "pa_Arab_PK", "name" : "Punjabi (Arabic, Pakistan)"},
  { "value" : "pa_Arab", "name" : "Punjabi (Arabic)"},
  { "value" : "pa_Guru_IN", "name" : "Punjabi (Gurmukhi, India)"},
  { "value" : "pa_Guru", "name" : "Punjabi (Gurmukhi)"},
  { "value" : "pa_IN", "name" : "Punjabi (India)"},
  { "value" : "pa_PK", "name" : "Punjabi (Pakistan)"},
  { "value" : "qu", "name" : "Quechua"},
  { "value" : "qu_BO", "name" : "Quechua (Bolivia)"},
  { "value" : "qu_EC", "name" : "Quechua (Ecuador)"},
  { "value" : "qu_PE", "name" : "Quechua (Peru)"},
  { "value" : "ro", "name" : "Romanian"},
  { "value" : "ro_MD", "name" : "Romanian (Moldova)"},
  { "value" : "ro_RO", "name" : "Romanian (Romania)"},
  { "value" : "rm", "name" : "Romansh"},
  { "value" : "rm_CH", "name" : "Romansh (Switzerland)"},
  { "value" : "rn", "name" : "Rundi"},
  { "value" : "rn_BI", "name" : "Rundi (Burundi)"},
  { "value" : "ru", "name" : "Russian"},
  { "value" : "ru_BY", "name" : "Russian (Belarus)"},
  { "value" : "ru_KZ", "name" : "Russian (Kazakhstan)"},
  { "value" : "ru_KG", "name" : "Russian (Kyrgyzstan)"},
  { "value" : "ru_MD", "name" : "Russian (Moldova)"},
  { "value" : "ru_RU", "name" : "Russian (Russia)"},
  { "value" : "ru_UA", "name" : "Russian (Ukraine)"},
  { "value" : "sg", "name" : "Sango"},
  { "value" : "sg_CF", "name" : "Sango (Central African Republic)"},
  { "value" : "gd", "name" : "Scottish Gaelic"},
  { "value" : "gd_GB", "name" : "Scottish Gaelic (United Kingdom)"},
  { "value" : "sr", "name" : "Serbian"},
  { "value" : "sr_BA", "name" : "Serbian (Bosnia & Herzegovina)"},
  { "value" : "sr_Cyrl_BA", "name" : "Serbian (Cyrillic, Bosnia & Herzegovina)"},
  { "value" : "sr_Cyrl_XK", "name" : "Serbian (Cyrillic, Kosovo)"},
  { "value" : "sr_Cyrl_ME", "name" : "Serbian (Cyrillic, Montenegro)"},
  { "value" : "sr_Cyrl_RS", "name" : "Serbian (Cyrillic, Serbia)"},
  { "value" : "sr_Cyrl", "name" : "Serbian (Cyrillic)"},
  { "value" : "sr_XK", "name" : "Serbian (Kosovo)"},
  { "value" : "sr_Latn_BA", "name" : "Serbian (Latin, Bosnia & Herzegovina)"},
  { "value" : "sr_Latn_XK", "name" : "Serbian (Latin, Kosovo)"},
  { "value" : "sr_Latn_ME", "name" : "Serbian (Latin, Montenegro)"},
  { "value" : "sr_Latn_RS", "name" : "Serbian (Latin, Serbia)"},
  { "value" : "sr_Latn", "name" : "Serbian (Latin)"},
  { "value" : "sr_ME", "name" : "Serbian (Montenegro)"},
  { "value" : "sr_RS", "name" : "Serbian (Serbia)"},
  { "value" : "sh", "name" : "Serbo-Croatian"},
  { "value" : "sh_BA", "name" : "Serbo-Croatian (Bosnia & Herzegovina)"},
  { "value" : "sn", "name" : "Shona"},
  { "value" : "sn_ZW", "name" : "Shona (Zimbabwe)"},
  { "value" : "ii", "name" : "Sichuan Yi"},
  { "value" : "ii_CN", "name" : "Sichuan Yi (China)"},
  { "value" : "si", "name" : "Sinhala"},
  { "value" : "si_LK", "name" : "Sinhala (Sri Lanka)"},
  { "value" : "sk", "name" : "Slovak"},
  { "value" : "sk_SK", "name" : "Slovak (Slovakia)"},
  { "value" : "sl", "name" : "Slovenian"},
  { "value" : "sl_SI", "name" : "Slovenian (Slovenia)"},
  { "value" : "so", "name" : "Somali"},
  { "value" : "so_DJ", "name" : "Somali (Djibouti)"},
  { "value" : "so_ET", "name" : "Somali (Ethiopia)"},
  { "value" : "so_KE", "name" : "Somali (Kenya)"},
  { "value" : "so_SO", "name" : "Somali (Somalia)"},
  { "value" : "es", "name" : "Spanish"},
  { "value" : "es_AR", "name" : "Spanish (Argentina)"},
  { "value" : "es_BO", "name" : "Spanish (Bolivia)"},
  { "value" : "es_IC", "name" : "Spanish (Canary Islands)"},
  { "value" : "es_EA", "name" : "Spanish (Ceuta & Melilla)"},
  { "value" : "es_CL", "name" : "Spanish (Chile)"},
  { "value" : "es_CO", "name" : "Spanish (Colombia)"},
  { "value" : "es_CR", "name" : "Spanish (Costa Rica)"},
  { "value" : "es_CU", "name" : "Spanish (Cuba)"},
  { "value" : "es_DO", "name" : "Spanish (Dominican Republic)"},
  { "value" : "es_EC", "name" : "Spanish (Ecuador)"},
  { "value" : "es_SV", "name" : "Spanish (El Salvador)"},
  { "value" : "es_GQ", "name" : "Spanish (Equatorial Guinea)"},
  { "value" : "es_GT", "name" : "Spanish (Guatemala)"},
  { "value" : "es_HN", "name" : "Spanish (Honduras)"},
  { "value" : "es_MX", "name" : "Spanish (Mexico)"},
  { "value" : "es_NI", "name" : "Spanish (Nicaragua)"},
  { "value" : "es_PA", "name" : "Spanish (Panama)"},
  { "value" : "es_PY", "name" : "Spanish (Paraguay)"},
  { "value" : "es_PE", "name" : "Spanish (Peru)"},
  { "value" : "es_PH", "name" : "Spanish (Philippines)"},
  { "value" : "es_PR", "name" : "Spanish (Puerto Rico)"},
  { "value" : "es_ES", "name" : "Spanish (Spain)"},
  { "value" : "es_US", "name" : "Spanish (United States)"},
  { "value" : "es_UY", "name" : "Spanish (Uruguay)"},
  { "value" : "es_VE", "name" : "Spanish (Venezuela)"},
  { "value" : "sw", "name" : "Swahili"},
  { "value" : "sw_KE", "name" : "Swahili (Kenya)"},
  { "value" : "sw_TZ", "name" : "Swahili (Tanzania)"},
  { "value" : "sw_UG", "name" : "Swahili (Uganda)"},
  { "value" : "sv", "name" : "Swedish"},
  { "value" : "sv_AX", "name" : "Swedish (\u00c5land Islands)"},
  { "value" : "sv_FI", "name" : "Swedish (Finland)"},
  { "value" : "sv_SE", "name" : "Swedish (Sweden)"},
  { "value" : "tl", "name" : "Tagalog"},
  { "value" : "tl_PH", "name" : "Tagalog (Philippines)"},
  { "value" : "ta", "name" : "Tamil"},
  { "value" : "ta_IN", "name" : "Tamil (India)"},
  { "value" : "ta_MY", "name" : "Tamil (Malaysia)"},
  { "value" : "ta_SG", "name" : "Tamil (Singapore)"},
  { "value" : "ta_LK", "name" : "Tamil (Sri Lanka)"},
  { "value" : "te", "name" : "Telugu"},
  { "value" : "te_IN", "name" : "Telugu (India)"},
  { "value" : "th", "name" : "Thai"},
  { "value" : "th_TH", "name" : "Thai (Thailand)"},
  { "value" : "bo", "name" : "Tibetan"},
  { "value" : "bo_CN", "name" : "Tibetan (China)"},
  { "value" : "bo_IN", "name" : "Tibetan (India)"},
  { "value" : "ti", "name" : "Tigrinya"},
  { "value" : "ti_ER", "name" : "Tigrinya (Eritrea)"},
  { "value" : "ti_ET", "name" : "Tigrinya (Ethiopia)"},
  { "value" : "to", "name" : "Tongan"},
  { "value" : "to_TO", "name" : "Tongan (Tonga)"},
  { "value" : "tr", "name" : "Turkish"},
  { "value" : "tr_CY", "name" : "Turkish (Cyprus)"},
  { "value" : "tr_TR", "name" : "Turkish (Turkey)"},
  { "value" : "uk", "name" : "Ukrainian"},
  { "value" : "uk_UA", "name" : "Ukrainian (Ukraine)"},
  { "value" : "ur", "name" : "Urdu"},
  { "value" : "ur_IN", "name" : "Urdu (India)"},
  { "value" : "ur_PK", "name" : "Urdu (Pakistan)"},
  { "value" : "ug", "name" : "Uyghur"},
  { "value" : "ug_Arab_CN", "name" : "Uyghur (Arabic, China)"},
  { "value" : "ug_Arab", "name" : "Uyghur (Arabic)"},
  { "value" : "ug_CN", "name" : "Uyghur (China)"},
  { "value" : "uz", "name" : "Uzbek"},
  { "value" : "uz_AF", "name" : "Uzbek (Afghanistan)"},
  { "value" : "uz_Arab_AF", "name" : "Uzbek (Arabic, Afghanistan)"},
  { "value" : "uz_Arab", "name" : "Uzbek (Arabic)"},
  { "value" : "uz_Cyrl_UZ", "name" : "Uzbek (Cyrillic, Uzbekistan)"},
  { "value" : "uz_Cyrl", "name" : "Uzbek (Cyrillic)"},
  { "value" : "uz_Latn_UZ", "name" : "Uzbek (Latin, Uzbekistan)"},
  { "value" : "uz_Latn", "name" : "Uzbek (Latin)"},
  { "value" : "uz_UZ", "name" : "Uzbek (Uzbekistan)"},
  { "value" : "vi", "name" : "Vietnamese"},
  { "value" : "vi_VN", "name" : "Vietnamese (Vietnam)"},
  { "value" : "cy", "name" : "Welsh"},
  { "value" : "cy_GB", "name" : "Welsh (United Kingdom)"},
  { "value" : "fy", "name" : "Western Frisian"},
  { "value" : "fy_NL", "name" : "Western Frisian (Netherlands)"},
  { "value" : "yi", "name" : "Yiddish"},
  { "value" : "yo", "name" : "Yoruba"},
  { "value" : "yo_BJ", "name" : "Yoruba (Benin)"},
  { "value" : "yo_NG", "name" : "Yoruba (Nigeria)"},
  { "value" : "zu", "name" : "Zulu"},
  { "value" : "zu_ZA", "name" : "Zulu (South Africa)"}
]
export default locales;

import { useQuery } from 'react-query';
import { getContactHashtags } from '../api/getContactHashtags';

const useContactHashtags = (contactId) => {
  const result = useQuery('contact_hashtags_'+(contactId ?? 'all'), () => getContactHashtags(contactId), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useContactHashtags;

import React, { Component, useState } from 'react';
import { Box, Grid, List, ListItem, FormHelperText, Typography, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import {AddAPIKey, CopyAPIKey, CopyEmbedCode} from '../../components'
import { DOC_HOST } from '../../lib/constants/constants'
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
    '& a':{
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const APIKey = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [apiKey, setApiKey] = useState(undefined);
    return (
      <Box className={classes.container}>
        <Typography className={classes.title}>Next, Copy this Embed Code into your Website. This allows you to track & grow your audience directly.</Typography>
        <CopyEmbedCode api={apiKey} />
        
        <Typography className={classes.title}>API Keys allow you to utilize SendBoth with our <a href={DOC_HOST} rel="noreferrer" target={'_blank'}>Developer Docs</a>, perfect for custom integrations in your favorite language.</Typography>
        <Typography className={classes.title}>Let&apos;s generate your first API Key now.</Typography>
        {apiKey == undefined ? <AddAPIKey setApiKey={setApiKey} /> : <CopyAPIKey api={apiKey} />}
        <FormHelperText style={{textAlign:'center'}}>Optional</FormHelperText>
      </Box>
    );
}

export default APIKey;

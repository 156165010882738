import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  InputLabel,
   Select,
   MenuItem,
  FormControlLabel,
   Switch,
  Typography,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Share, FileCopy, Create } from '@material-ui/icons';
import { CampaignContext, UserContext } from '../../../lib/contexts';
import useTemplates from '../../../lib/hooks/useTemplates';
import {TemplateListing} from '../../TemplateListing/';


import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import useOrganization from "../../../lib/hooks/useOrganization";
import {useNavigate} from "react-router-dom";


const useStyles = makeStyles(theme => ({
  container:{
    margin: 'auto',
    width: '100%',
    borderColor: theme.palette.primary.main,
    borderSize:1,
    borderStyle:'none',
    textAlign:'center'
  },
  item:{
    //display: 'block',
    margin: 'auto',
    width: '70%',
    textAlign:'center',
    '& p':{
      textAlign:'left',
      
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.primary.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.primary.main
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const PickSchedule = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const {
    state: { user_id },
  } = useContext(UserContext)
  const { isLoading, data:templateDataRaw, refetch:templateRefetch } = useTemplates();
  const templateData = useMemo(()=>templateDataRaw && !templateDataRaw.error ? templateDataRaw : [],[templateDataRaw]);
  const userTemplates = templateData ? templateData.filter(item=>item.creator === user_id) : [];
  
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetch: refetchOrganization} = useOrganization();
  const { plan } = useMemo(()=>organization ?? [], [organization]);
  
  const {
    state: {
      selectedCampaign,
      saving,
      campaignType,
      step,
      viewingCampaign,
      deliveryDate,
      deliveryTime,
      batchDelivery,
      batchCount,
      batchTime,
      timewarpDelivery,
      aiDelivery,
      recurringType,
    },
    actions: {
      setDeliveryDate,
      setDeliveryTime,
      setBatchDelivery,
      setBatchCount,
      setBatchTime,
      setTimewarpDelivery,
      setAiDelivery,
      setRecurringType,
    },
  } = useContext(CampaignContext)
  
  const handleInputChange = useCallback((event) => {
    //setName(event.target.value);
  },[]);
  
  
  const handlePlanUpgrade = useCallback((event) => {
    const confirm = window.confirm('Are you sure you wish to navigate to Billing Settings?')
    if(confirm) {
      navigate("../settings/billing", {replace: true});
    }
  },[]);
  
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
         item xs={12}
         container
         direction="row"
         justifyContent="space-between"
         alignItems="center"
         spacing={4}
         className={classes.container}
      >
        {<>
            <Grid item xs={6} className={classes.item}>
              <DatePicker
                 label="Delivery Date"
                 value={deliveryDate}
                 onChange={(newValue) => {
                   setDeliveryDate(newValue);
                 }}
                 disablePast={true}
                 renderInput={(params) => <TextField {...params}
                                                     color="primary" variant="outlined" helperText={'Specify what date the Campaign should begin.'} />}
              />
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <TimePicker
                 label="Delivery Time"
                 value={deliveryTime}
                 onChange={(newValue) => {
                   setDeliveryTime(newValue);
                 }}
                 renderInput={(params) => <TextField {...params} helperText={'Do you want to deliver at a specific time?'}
                                                     color="primary" variant="outlined" />}
              />
            </Grid>
          <Grid item xs={12} className={classes.item}>
            <Typography color={'primary'}>
              Batch Delivery?
            </Typography>
          </Grid>
          <Grid
             item xs={12}
             container
             direction="row"
             justifyContent="space-around"
             alignItems="center"
             spacing={0}
             className={classes.item} style={{marginTop:-10}}>
            <Grid item xs={8}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                     control={
                       <Switch
                          disabled={plan === "Lite" || plan === ""}
                          checked={batchDelivery}
                          onChange={()=>setBatchDelivery(!batchDelivery)}
                          name="batchDelivery"
                          color="primary"
                       />
                     }
                     label="Deliver Campaign in Batches to prevent flooding your Website all at once."
                  />
                </FormControl>
              </Grid>
            {(plan === "lite" || plan === "") && <Grid item xs={4}>
              <Button variant={'contained'} color={'primary'} onClick={handlePlanUpgrade}>Upgrade</Button>
            </Grid>}
          </Grid>
  
          {batchDelivery && <Grid container item xs={12} className={classes.container}>
            <Grid item xs={6} className={classes.item}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="batch-count-label">Batch Count</InputLabel>
                <Select
                   color="primary"
                   labelId="batch-count-label"
                   id="batch-count"
                   value={batchCount}
                   onChange={event => setBatchCount(event.target.value)}
                   label="Batch Count"
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="batch-time-label">Batch Time</InputLabel>
                <Select
                   color="primary"
                   labelId="batch-time-label"
                   id="batch-time"
                   value={batchTime}
                   onChange={event => setBatchTime(event.target.value)}
                   label="Batch Time"
                >
                  <MenuItem value={5}>5 Minutes</MenuItem>
                  <MenuItem value={10}>10 Minutes</MenuItem>
                  <MenuItem value={15}>15 Minutes</MenuItem>
                  <MenuItem value={20}>20 Minutes</MenuItem>
                  <MenuItem value={25}>25 Minutes</MenuItem>
                  <MenuItem value={30}>30 Minutes</MenuItem>
                  <MenuItem value={35}>35 Minutes</MenuItem>
                  <MenuItem value={40}>40 Minutes</MenuItem>
                  <MenuItem value={45}>45 Minutes</MenuItem>
                  <MenuItem value={50}>50 Minutes</MenuItem>
                  <MenuItem value={55}>55 Minutes</MenuItem>
                  <MenuItem value={60}>60 Minutes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Typography color="textSecondary" style={{margin:'15px auto 0px', textAlign:'center'}}>
              Will deliver to <b>{batchCount}</b> batches of contacts every <b>{batchTime}</b> minutes until complete.
            </Typography>
          </Grid>}
  
  
  
          <Grid item xs={12} className={classes.item}>
               <Typography color={'primary'}>
                Recurring Campaign?
              </Typography>
            </Grid>
            <Grid
               item xs={12}
               container
               direction="row"
               justifyContent="space-around"
               alignItems="center"
               spacing={0}
               className={classes.item} style={{marginTop:-10}}>
              <Grid item xs={8}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="recurring-count-label">Type</InputLabel>
                  <Select
                     color="primary"
                     labelId="recurring-count-label"
                     id="recurring-count"
                     value={recurringType}
                     onChange={event=>setRecurringType(event.target.value)}
                     label="Type"
                     disabled={plan === "Lite" || plan === "Basic" || plan === ""}
                  >
                    <MenuItem value={'once'}>Once</MenuItem>
                    <MenuItem value={'daily'}>Daily</MenuItem>
                    <MenuItem value={'weekly'}>Weekly</MenuItem>
                    <MenuItem value={'monthly'}>Monthly</MenuItem>
                    <MenuItem value={'annually'}>Annually</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {(plan === "lite" || plan === "basic" || plan === "") && <Grid item xs={4}>
                <Button variant={'contained'} color={'primary'} onClick={handlePlanUpgrade}>Upgrade</Button>
              </Grid>}
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <Typography color={'primary'}>
                Send with Timewarp?
              </Typography>
            </Grid>
            <Grid
               item xs={12}
               container
               direction="row"
               justifyContent="space-around"
               alignItems="left"
               spacing={0} className={classes.item}>
              <Grid item xs={8} style={{textAlign:'left'}}>
                <FormControlLabel
                   control={
                     <Switch
                        disabled={plan === "Lite" || plan === ""}
                        checked={timewarpDelivery}
                        onChange={()=>setTimewarpDelivery(!timewarpDelivery)}
                        name="timewarpDelivery"
                        color="primary"
                     />
                   }
                   label="Deliver your Campaign based on your Contact&apos;s time zone."
                />
              </Grid>
              {(plan === "lite" || plan === "") && <Grid item xs={4}>
                <Button variant={'contained'} color={'primary'} onClick={handlePlanUpgrade}>Upgrade</Button>
              </Grid>}
            </Grid>
          <Grid item xs={12} className={classes.item}>
            <Typography color={'primary'}>
              AI Optimize?
            </Typography>
          </Grid>
            <Grid
               item xs={12}
               container
               direction="row"
               justifyContent="space-around"
               alignItems="left"
               spacing={0} className={classes.item}>
              <Grid item xs={8} style={{textAlign:'left'}}>
                <FormControlLabel
                   control={
                     <Switch
                        disabled={plan === "Lite" || plan === ""}
                        checked={aiDelivery}
                        onChange={()=>setAiDelivery(!aiDelivery)}
                        name="aiDelivery"
                        color="primary"
                     />
                   }
                   label="Let SendBoth optimize the delivery time for maximum engagement."
                />
              </Grid>
              {(plan === "lite" || plan === "") && <Grid item xs={4}>
                <Button variant={'contained'} color={'primary'} onClick={handlePlanUpgrade}>Upgrade</Button>
              </Grid>}
            </Grid>
        </>}
      </Grid>
          </LocalizationProvider>
    </>
  );
}
export default PickSchedule;

import { useQuery } from 'react-query';
import {getAI} from "../api";

const useAI = (mode, prompt, value) => {
  const result = useQuery('ai_'+mode+'_'+prompt+'_'+value, () => getAI(mode, prompt, value), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useAI;

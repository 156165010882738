import React, { useContext, useEffect, useState } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import packageJson from '../../../package.json';

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Tooltip,
  Box, useTheme, useMediaQuery,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  CheckBoxOutlined,
  EmailOutlined,
  HelpOutline,
  HomeOutlined,
  MenuBookOutlined, Payment,
  PollOutlined,
  SettingsOutlined,
  ShareOutlined,
} from '@material-ui/icons';
import { OrganizationContext, UserContext } from '../../lib/contexts';
import { makeStyles } from '@material-ui/core/styles';
import { Logo } from '../Logo';
import cn from 'classnames';
import { pageAccess } from '../../lib/constants/routes';
import { ShareLinkDialog } from '../ShareLinkDialog';
import { toCapitalizeFromUnderScore } from '../../lib/helpers/helpers';
import { getClientHost } from '../../lib/constants/constants';

const drawerWidth = 230;
const drawerWidthSlim = 230;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  active: {
    backgroundColor: '#fff',
    color: theme.palette.admin.contrastText,
  },
  drawer: {
    width: theme.breakpoints.up('xl') ? drawerWidth : drawerWidthSlim,
    flexShrink: 0,
    '@media print':{
      display:'none',
    }
  },
  drawerClosed: {
    width: theme.breakpoints.up('xl') ? drawerWidth : drawerWidthSlim,
    marginLeft: -1 * (theme.breakpoints.up('xl') ? drawerWidth : drawerWidthSlim),
    flexShrink: 0,
    '@media print':{
      display:'none',
    }
  },
  drawerPaper: {
    width: 'inherit',
    backgroundColor: theme.palette.admin.dark,
    color: theme.palette.admin.contrastText,
    '& *': {
      color: theme.palette.admin.contrastText,
      fontWeight: 'bold',
    },
  },
  drawerText: {
    color: theme.palette.admin.contrastText,
    padding: 0,
  },
  toolbar: theme.mixins.toolbar,
  topBox: {
    height: 87,
    backgroundColor: theme.palette.primary.main,
  },
  partnerName: {
    margin: '20px auto 20px',
    padding: 0,
    fontWeight: 'bold',
    fontSize: 14,
  },
  listItem: {
    height:55,
  },
  listItemActive: {},
  listItemText: {
    height:35,
    display: 'block',
    margin: 'auto',
    padding: '0px',
    fontWeight: 'bold',
    '& *': {
      verticalAlign:'-webkit-baseline-middle',
      color: theme.palette.admin.contrastText + ' !important',
    },
  },
  ListItemIcon:{
    verticalAlign:'-webkit-baseline-middle',
    marginRight:0,
    marginTop:0,
    height:35
  },
  sideNavList: {
    width: '100%',
    padding: 0,
    margin: 0,
    '& a': {
      display: 'contents',
    },
    '& :hover': {
      backgroundColor: theme.palette.admin.backgroundHover + ' !important',
    },
  },
  comingSoonBadge:{
    width: 100,
    fontSize:'9px !important',
    position:'relative',
    borderRadius:10,
    padding: 4,
    margin: 0,
    top: -20,
    right: -90,
    backgroundColor: theme.palette.admin.backgroundHover + ' !important',
  },
  internalInfo:{
    marginLeft: 15,
    marginBottom: 0,
    fontSize:11,
    color: theme.palette.admin.light,
  },
  internalInfoRole:{
    marginLeft: 15,
    marginBottom: -10,
    fontSize:11,
    color: theme.palette.admin.light,
  }
}));

const SideBarNavigation = () => {
  const {
    state: { code: partnerCode, name: partnerName, userRole },
  } = useContext(OrganizationContext);
  const {
    actions: { logout },
  } = useContext(UserContext);
  const classes = useStyles();
  //const [open, setOpen] = useState(false);
  const [showShareLink, setShowShareLink] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const active = '';
  let page_access = pageAccess(userRole);
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchXL = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(()=>{
      setIsOpen((matchSM || matchMD));
  },[matchSM, matchMD]);
  return (
    <Drawer
      className={isOpen ? classes.drawer + ' drawerParent' : classes.drawerClosed + ' drawerParent'}
      variant="permanent"
      classes={{
        paper: isOpen ? classes.drawerPaper : classes.drawerClosed,
      }}
      
    >
      <Box className={classes.topBox}>
        <Logo variant={'admin'} />
        {/*
        <Menu
          style={{
            padding: 0,
            margin: '0 5px 0 0',
            top: -3,
            position: 'relative'
          }}
          className={'burger'}
          buttonWidth={20}
          barColor="white"
          isActive={isOpen}
          toggleButton={() => setOpen(!isOpen)}
        />*/}
      </Box>
      <Typography className={classes.partnerName}>{partnerName}</Typography>
      <div className={classes.toolbar} style={{ display: 'none' }} />

      {page_access.map((page_obj, index) => {
        const { name, path, onclick, disabled, url } = page_obj;
        return (
          <List className={classes.sideNavList} key={`${path}_${index}`}>
            <ListItem
              selected={active === name ? true : false}
              button
              key={index}
              className={cn(classes.listItem, active === name ? classes.listItemActive : '')}
            >
              <Link to={url ? { pathname: getClientHost(partnerCode)+'/'+url } : '/'+path} target={url ? '_blank' : 'inherit'}>
                <Tooltip placement="bottom" title={name} aria-label={name}>
                  <ListItemIcon className={classes.ListItemIcon}>
                    {(() => {
                      switch (path) {
                        default:
                        case 'dashboard':
                          return <HomeOutlined />;
                        case 'patients':
                          return <PollOutlined />;
                        case 'resources':
                          return <MenuBookOutlined />;
                        case 'messages':
                          return <EmailOutlined />;
                        case 'settings':
                          return <SettingsOutlined />;
                        case 'help':
                          return <HelpOutline />;
                        case 'billing':
                          return <Payment />;
                        case 'settings#billing':
                          return <CheckBoxOutlined />;
                      }
                    })()}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  className={classes.listItemText}
                  primary={name}
                  secondary={disabled ? <span className={classes.comingSoonBadge}>{'Coming Soon'}</span> : ''}
                />
              </Link>
            </ListItem>
          </List>
        );
      })}
  
      <List className={'SideNavList'}>
        <ListItem button key="ShareURL" className={classes.listItem}>
          <a target="#" rel="noopener noreferrer" onClick={() => setShowShareLink(true)}>
            <Tooltip placement="right" title={'Log Out'} aria-label={'Share your SendBoth Link'}>
              <ListItemIcon className={classes.ListItemIcon}>
                <ShareOutlined />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText} primary={matchXL || matchLG ? "Share your SendBoth Link" : "Share Link"} />
          </a>
        </ListItem>
      </List>
      <Divider />
      <List className={'SideNavList'}>
        <ListItem button key="LogOut" className={classes.listItem}>
          <a target="#" rel="noopener noreferrer" onClick={logout}>
            <Tooltip placement="right" title={'Log Out'} aria-label={'Log Out'}>
              <ListItemIcon className={classes.ListItemIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText} primary="Log Out" />
          </a>
        </ListItem>
      </List>
      <div
        className={'bottom'}
        style={{
          margin: 'auto 0 20px auto',
          textAlign: 'left',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography className={classes.internalInfo}>
          Build: v{packageJson.version}
        </Typography>
        {userRole && <Typography className={classes.internalInfo}>
          Role: {toCapitalizeFromUnderScore(userRole)}
        </Typography>}
      </div>
      {showShareLink && <ShareLinkDialog handleClose={() => setShowShareLink(false)}/>}
    </Drawer>
  );
};

export default SideBarNavigation;

import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { Drawer, Grid, Typography } from '@material-ui/core';
import { Screen } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import { ContactsContextProvider } from '../../lib/contexts/ContactsContext';
import { ViewContact } from './components/';
import { MessageList, ViewConvo } from './components';
import useMailbox from '../../lib/hooks/useMailbox';
import { groupByConvo } from '../../lib/helpers/helpers';
const useStyles = makeStyles(theme => ({
  screen: {
    width: '100%',
    maxWidth: '80vw',
    margin: 'auto',
  },
  container: {
    width: '100%',
    maxWidth: '80vw',
    margin: 'auto',
    '& h2': {
      width: '100%',
      fontSize: 20,
      color: theme.palette.primary.main,
      display: 'block',
    },
    padding: 10,
  },
  content: {
    margin: '-20px auto auto',
    backgroundColor: theme.palette.backgrounds.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

const InboxScreenWrapper = () => {
  return (
    <ContactsContextProvider>
      <InboxScreen />
    </ContactsContextProvider>
  );
};

const InboxScreen = () => {
  const classes = useStyles();
  const { convoId } = useParams();
  const [viewingConvo, setViewingConvo] = useState(null);
  const [viewingContact, setViewingContact] = useState(null);

  const {
    isLoading: isLoadingMailbox,
    isRefetching: isRefetchingMailbox,
    data: mailbox,
    refetch: refetchMailbox,
  } = useMailbox();
  const convos = useMemo(() => (mailbox ? groupByConvo(mailbox.data) : []), [mailbox]);
  console.log(convos);
  useEffect(() => {
    if (convoId) {
      setViewingConvo(convoId);
    }
  }, [convoId]);

  useEffect(() => {
    // if new message came in
    if (convos && viewingConvo) {
      const foundID = String(viewingConvo.identifier);
      const filtered = convos.filter(item => item.identifier === foundID)[0];
      setViewingConvo(filtered);
      //setViewingConvo(convos);
    }
  }, [convos]);

  const { messages } = useMemo(() => (viewingConvo ? viewingConvo : []), [viewingConvo]);
  return (
    <Screen title={'Inbox Messages'} showTopNav={true} showFooter={true} permission={'inbox.view'}>
      <Drawer
        anchor={'right'}
        open={viewingContact}
        onClose={() => resetViewingContact}
        className={classes.contactDrawer}
      >
        {viewingContact && <ViewContact refetch={refetch} />}
      </Drawer>
      <Grid container alignItems="center" spacing={0} className={classes.screen}>
        <Typography variant={'subtitle1'}>SMS & Chat Inbox</Typography>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.container}
          xs={12}
        >
          <Grid item xs={7} className={classes.content}>
            <MessageList
              convos={convos}
              setViewingConvo={setViewingConvo}
              isLoadingMailbox={isLoadingMailbox}
              refetch={refetchMailbox}
            />
          </Grid>
          <Grid item xs={4} className={classes.content}>
            <ViewConvo convo={messages} refetch={refetchMailbox} />
          </Grid>
        </Grid>
      </Grid>
    </Screen>
  );
};
export default InboxScreenWrapper;

import React from 'react';
import {  Grid, Typography } from "@material-ui/core";
import { Screen } from '../../components';
import {DateRangeFilter, VisitorsByDay, VisitorsByHour, VisitorsByPage} from './components/index';
import { makeStyles } from "@material-ui/core/styles";
import {VisitorsByLocation, VisitorsByReferrer} from "./components";
import VisitorsTable from "./components/VisitorsTable";

const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginTop:40,
      marginBottom:40,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
}));

const VisitorsScreen = () => {
  const classes = useStyles();
  
  return (
    <Screen title={'Visitors'} showTopNav={true} showFooter={true} permission={'visitors.view'}>
          <Grid
            container
            alignItems="center"
            spacing={0}
            className={classes.screen}
          >
            <Grid item xs={8}>
              <Typography variant={'subtitle1'}>
                Your Latest Visitors
              </Typography>
            </Grid>
            <Grid item xs={4} style={{display:'none'}}>
               <DateRangeFilter />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              className={classes.container}
            >
                <VisitorsByHour/>
                <VisitorsByDay/>
                <VisitorsByPage/>
                <VisitorsByReferrer/>
                <VisitorsByLocation/>
                <VisitorsTable/>
              </Grid>
          </Grid>
    </Screen>
  );
};
export default VisitorsScreen;

import { useQuery } from 'react-query';
import {getOrganizationFaqs} from "../api";

const useOrganizationFaqs = (organizationID) => {
  const result = useQuery('organization_faqs_'+organizationID, () => getOrganizationFaqs(organizationID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganizationFaqs;

import * as React from 'react';
import {Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import useMetric from "../../../lib/hooks/useMetric";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import {useParams} from "react-router";
import {Whatshot} from "@material-ui/icons";
import {useMemo} from "react";
export default function VisitorsByReferrer() {
   const {isLoading, isRefetching, data, refetch} = useMetric('visitors_by_page', 'visitor');
   const { metric } = useMemo(()=>data !== undefined ? data : [],[data])
  return (
    <>
      <Grid item xs={2}>
         <Typography variant={'h2'} color={'primary'} style={{marginTop:0}}>
           Top Content
        </Typography>
         {isLoading || isRefetching && <ProgressBar style={{margin:25}}/>}
         {!(isLoading || isRefetching) && data && <List style={{height:245, overflowY:'auto'}}>
            {metric.map((item,index)=>{
            return (<>
                  <ListItem key={index} dense={true}>
                     <ListItemAvatar>
                        <Avatar><Whatshot/></Avatar>
                     </ListItemAvatar>
                     <ListItemText
                        primary={`#${index+1} - ${item.page}`}
                        secondary={`${item.visitors} Visitors`}
                     />
                  </ListItem>
                  <Divider/>
               </>
            )
            })
            }
         </List>
         }
      </Grid>
    </>
  );
}

import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import {
  InputAdornment, MenuItem, Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import { Share, FileCopy, Create } from '@material-ui/icons';
import { CampaignContext } from '../../../lib/contexts';
import {toCleanEmailPrefix} from "../../../lib/helpers/helpers";
import AddVerifiedDomainDialog from "../../AddVerifiedDomainDialog/AddVerifiedDomainDialog";
import useVerifiedDomains from "../../../lib/hooks/useVerifiedDomains";
import useSubdomainAvailability from "../../../lib/hooks/useSubdomainAvailability";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    width:214,
    height:50,
    padding:0,
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
    }
  },
  progressBar:{
    display: 'block',
    margin:'50px auto 50px',
    backgroundColor: theme.palette.primary.light
  },
  progressBarColor:{
    backgroundColor: theme.palette.primary.main
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));

const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const CampaignSettings = ({ api }) => {
  const classes = useStyles();
  
  const {
    state: {
      selectedCampaigns,
      saving,
      campaignType,
      campaignName,
      campaignSubject,
      campaignFromName,
      campaignFromEmail,
      campaignFromDomain,
      step,
    },
    actions: {
      setCampaignType,
      setCampaignName,
      setCampaignSubject,
      setCampaignFromName,
      setCampaignFromEmail,
      setCampaignFromDomain,
      setStep,
    },
  } = useContext(CampaignContext)
  
  const [newDomain, setNewDomain] = useState('');
  const {isLoading: isLoadingDomains, isRefetching: isRefetchingDomains, data: domains, refetchDomains} = useVerifiedDomains();
  const verifiedDomains = useMemo(()=>domains && domains.length ? domains.map(item=>item.domain) : [campaignFromEmail],[domains])

  const handleNewDomain = (event) => {
    event.stopPropagation()
    setNewDomain(true)
  }
  
  const handleInputChange = useCallback((event) => {
    //setName(event.target.value);
  },[]);
  
  
  
  return (
    <Grid
  item
  container
  xs={12}
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}>
      <Grid item xs={campaignType === 'sms' ? 12 : 6} style={{textAlign: 'center'}}>
        <AdminGreenTextField
           value={campaignName}
           onChange={event=>setCampaignName(event.target.value)}
           variant={'outlined'}
           fullWidth={true}
           label={<><Create/> Campaign Name</>} placeholder={'e.g., Fall Newsletter'}
        />
      </Grid>
      {campaignType !== 'sms' && <Grid item xs={6} style={{textAlign: 'center'}}>
        <AdminGreenTextField
           value={campaignSubject}
           onChange={event=>setCampaignSubject(event.target.value)}
           variant={'outlined'}
           fullWidth={true}
           maxLength={150}
           label={<><Create/> Email Subject</>} placeholder={'e.g., Holiday Event'}
        />
      </Grid>}
  
      {campaignType !== 'sms' && <Grid item xs={6} style={{textAlign: 'center'}}>
        <AdminGreenTextField
           value={campaignFromName}
           onChange={event=>setCampaignFromName(event.target.value)}
           variant={'outlined'}
           fullWidth={true}
           maxLength={99}
           label={<><Create/> From Name</>} placeholder={'e.g., Party Planners'}
        />
      </Grid>}
      {campaignType !== 'sms' && <Grid item xs={6}>
        <AdminGreenTextField style={{textAlign: 'center', margin: 'auto', display: 'none'}}
                             value={campaignFromEmail}
                             onChange={event=>setCampaignFromEmail(event.target.value)}
                             variant={'outlined'}
                             fullWidth={true}
                             maxLength={99}
                             label={<><Create/> From Email</>} placeholder={'e.g., noreply@example.com'}
        />
        <TextField style={{textAlign: 'center', margin: 'auto', display: 'block'}}
           required={true}
           key='FromEmail'
           id='FromEmail'
           label='From Email'
           placeholder={'e.g., noreply@example.com'}
           value={campaignFromEmail}
           margin='normal'
           variant='outlined'
           fullWidth={true}
           type={'text'}
           onChange={()=>setCampaignFromEmail(toCleanEmailPrefix(event.target.value))}
           InputProps={{
             endAdornment: <InputAdornment position="end">
               <Select
                  aria-label="select from verified domains"
                  value={campaignFromDomain}
               >
                 {verifiedDomains.map((item,index)=> <MenuItem key={index} value={item}
                                                               onClick={(e)=>setCampaignFromDomain(item)}>@{item}</MenuItem>
                 )}
                 <MenuItem onClick={handleNewDomain}>Add Domain</MenuItem>
               </Select>
               <AddVerifiedDomainDialog refetch={refetchDomains} forceOpen={newDomain} invisible={true} />
             </InputAdornment>,
           }}
        />
      </Grid>}
    </Grid>
  );
}
export default CampaignSettings;

import React, { useMemo, useState, useEffect } from 'react';

import {
  Box,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import useContactLists from '../../../lib/hooks/useContactLists';
import IndividualCard from './IndividualCard';
import { ProgressBar } from '../../../components/ProgressBar';
import { AddContactListDialog } from '../../../components/AddContactListDialog';
import usePermission from "../../../lib/hooks/usePermission";
const useStyles = makeStyles(theme => ({
  progressBarContainer:{
    width:214,
    marginRight:25,
  }
}));

const ContactListsCards = ({setForceOpenOverride = false}) => {
  const classes = useStyles();
  const {isLoading, isRefetching, data, refetch } = useContactLists();
  const hasPermissionEdit = usePermission('contacts.edit')
  const contactLists = useMemo(()=> data,[data]);
  const [forceOpen, setForceOpen] = useState(setForceOpenOverride || false);
  useEffect(() => {
    //alert(setForceOpenOverride)
  },[setForceOpenOverride])
  useEffect(() => {
    const callback = (event) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyN') {
        event.preventDefault();
        setForceOpen(true)
      }
      if (event.code === 'Escape') {
        setForceOpen(false)
      }
      
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [forceOpen]);
  
  return (
          <>
            {isLoading || isRefetching ?
              <Box className={classes.progressBarContainer}>
                <ProgressBar style={{margin:'20px auto 20px'}}/>
              </Box>
              :
              <>
                {(forceOpen || !forceOpen) && hasPermissionEdit && <AddContactListDialog refetch={refetch} forceOpen={forceOpen} setForceOpen={setForceOpen}/>}
                {contactLists && contactLists.length > 0 && contactLists.map((list, index) => {
                  return (
                    <IndividualCard listItem={list} key={index} refetch={refetch}/>
                  )
                })
                }
                </>
                }
          </>
  );
};

export default ContactListsCards;

import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {determineUsagePricingStrategy} from "../../lib/helpers/helpers";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
}));

const PricingStrategy = () => {
  const classes = useStyles();
  const [pricePoint, setPricePoint] = useState(1000);
  const [targetMargin, setTargetMargin] = useState(500);
  const [strategy, setStrategy] = useState(null);
  
  const handlePricePointChange = event => {
    setPricePoint(event.target.value);
  };
  
  const handleTargetMarginChange = event => {
    setTargetMargin(event.target.value);
  };
  
  const handleSubmit = () => {
    setStrategy(determineUsagePricingStrategy(pricePoint, targetMargin));
  };
  
  return (
       <div className={classes.root}>
       <TextField
          label="Desired Price Point"
          value={pricePoint}
          onChange={handlePricePointChange}
          className={classes.formControl}
       />
       <TextField
          label="Target Profit Margin"
          value={targetMargin}
          onChange={handleTargetMarginChange}
          className={classes.formControl}
       />
       <Button variant="contained" color="primary" onClick={handleSubmit}>
         Calculate Pricing Strategy
       </Button>
       {strategy && (
          <>
            <h3>Pricing Strategy</h3>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Price</TableCell>
                  <TableCell>Max Usage</TableCell>
                  <TableCell>Usage Cost</TableCell>
                  <TableCell>Alternative Usages</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {strategy.map(point => (
                   <TableRow key={point.price}>
                     <TableCell>${point.price}</TableCell>
                     <TableCell>
                       Email: {point.maxUsage.maxEmailUsage}<br />
                       SMS: {point.maxUsage.maxSMSUsage}<br />
                       AI Text: {point.maxUsage.maxAiTextUsage}<br />
                       AI Photos: {point.maxUsage.maxAiPhotosUsage}
                     </TableCell>
                     <TableCell>${point.usageCost}</TableCell>
                     <TableCell>
                       {point.alternativeUsages.map(usage => (
                          <React.Fragment key={usage.numEmails}>
                            Email: {usage.numEmails}<br />
                            SMS: {usage.numSMS}<br />
                            AI Text: {usage.numAiText}<br />
                            AI Photos: {usage.numAiPhotos}<br /><br />
                          </React.Fragment>
                       ))}
                     </TableCell>
                   </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
       )}
     </div>
  )
}
export default PricingStrategy;

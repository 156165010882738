import React, { useCallback, useContext, useState} from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  Button, InputAdornment
} from "@material-ui/core";
import useCurrentUser from "../../lib/hooks/useCurrentUser";
import {postOrganizationCreate, postOrganizationJoin} from "../../lib/api";
import {ThemeContext, UserContext} from "../../lib/contexts";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const JoinOrganization = ({handleOrganizationCreationNext, setShowPlanRequiredDialog}) => {
  const classes = useStyles();
  const { suggestedOrganization } = useCurrentUser();
  const { darkMode } = useContext(ThemeContext);
  const [inviteCode, setInviteCode] = useState(localStorage.getItem('SB_INVITE'));
  const [newName, setNewName] = useState('');
  
  const {
    state: {
      email
    },
    actions: {
      setOrganization
    }
  } = useContext(UserContext);
  const handleOrganizationCreation = useCallback(()=>{
    postOrganizationCreate({name:newName, default_email: email}).then(response=>{
      // success
      if(response.id){
        setOrganization(response)
        handleOrganizationCreationNext(true)
      }
    })
  },[newName])
  
  const handleOrganizationJoin = useCallback(()=>{
    postOrganizationJoin(inviteCode ? null : suggestedOrganization.id, inviteCode).then(response=>{
      // success
      if(response.id) {
        setOrganization(response)
        setShowPlanRequiredDialog(false)
      }
    })
  },[newName, inviteCode])
  
    return (
      <>
      <Grid container spacing={1} justifyContent={'center'}>
        <Grid item xs={suggestedOrganization ? 6 : 6}>
          {suggestedOrganization &&
             <>
               <img
                  style={{height:100,margin:'auto auto 20px',display:'block'}}
                  src={`https://sendboth.s3.amazonaws.com/organizations/${suggestedOrganization.id}_${darkMode ? 'logo_dark' : 'logo_light'}.png`} />
               <Typography
                  style={{textAlign:'center'}}>You have been invited to join the <b>{suggestedOrganization.name}</b> team!</Typography>
               <Button
                  style={{margin:"20px auto 10px",display:'block'}}
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleOrganizationJoin}
               >Continue</Button>
               <Grid item xs={12}>
                 <Typography
                    style={{textAlign:'center'}}>or</Typography>
               </Grid>
             </>}
             <TextField
            key='ga'
            id='ga'
            label='Do you have an invite code?'
            placeholder={'ABC123'}
            value={inviteCode}
            margin='normal'
            variant='outlined'
            fullWidth={true}
            type={'text'}
            onChange={()=>setInviteCode(event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Button
                   variant={'contained'}
                   color={'primary'}
                   onClick={handleOrganizationJoin}
                >Search</Button>
              </InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
             style={{textAlign:'center'}}>or</Typography>
        </Grid>
        <Grid item xs={6}>
         
               <TextField
                  key='newName'
                  id='newName'
                  label="What's the name of your Organization?"
                  placeholder={'Google'}
                  value={newName}
                  margin='normal'
                  variant='outlined'
                  fullWidth={true}
                  type={'text'}
                  onChange={()=>setNewName(event.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <Button
                         variant={'contained'}
                         color={'primary'}
                         onClick={handleOrganizationCreation}
                      >Create</Button>
                    </InputAdornment>,
                  }}
               />
        </Grid>
        
      </Grid>
      </>
    );
}

export default JoinOrganization;

import { useQuery } from 'react-query';
import { getContacts } from '../api/getContacts';

const useContacts = (listId, exclude, contactId = null) => {
  const result = useQuery('contacts_'+((listId || contactId) ?? 'all')+exclude, () => getContacts(listId,exclude, contactId), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useContacts;

import React, {useCallback, useState} from 'react';
import {
   Typography,
   makeStyles,
   useTheme,
   useMediaQuery,
   Grid,
   Switch,
   TextField,
   InputAdornment, Select, MenuItem, Tooltip, ListItem, ListItemText, List, ListItemSecondaryAction, Button
} from "@material-ui/core";
import usePhoneNumberSearch from "../../lib/hooks/usePhoneNumberSearch";
import useDebounce from "../../lib/hooks/useDebounce";
import ProgressBar from "../ProgressBar/ProgressBar";
import {prettyPhone, toUnderScoreSlug} from "../../lib/helpers/helpers";
import {ShoppingCart} from "@material-ui/icons";
import {postPhoneNumberCheckout} from "../../lib/api";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  },
   searchContainer:{
     marginTop:50,
     marginBottom:50,
   },
   listContainer:{
      maxHeight:300,
      overflowY:'auto'
   },
   confirmContainer:{
     textAlign:'center',
      '& button':{
        margin: 'auto',
         display: 'block'
      }
   }
}));
const PhoneNumberPicker = ({handleToggleOpen}) => {
   const classes = useStyles();
   const theme = useTheme();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
   const [filterPhrase, setFilterPhrase] = useState()
   const [filterType, setFilterType] = useState('Starts With')
   const [numberType, setNumberType] = useState('local')
   const filterPhraseThrottled = useDebounce(filterPhrase)
   const [desiredNumber, setDesiredNumber] = useState('')
   const [isLoadingCheckout, setIsLoadingCheckout] = useState(false)
   const {isLoading, isRefetching, data, refetch} = usePhoneNumberSearch(filterPhraseThrottled, toUnderScoreSlug(filterType), numberType);
   
   const handlePurchase = useCallback(()=>{
      setIsLoadingCheckout(true)
      postPhoneNumberCheckout(desiredNumber).then(response => {
         console.log('checkout response')
         console.log(response)
         setIsLoadingCheckout(false)
         if(response.status === "success"){
            enqueueSnackbar('Success!', {variant:'success'})
            handleToggleOpen()
         }else{
            enqueueSnackbar('Something went wrong, try again.', {variant:'error'})
         }
      })
   },[desiredNumber, handleToggleOpen, enqueueSnackbar])
   
   
   return (
      <>
         <Typography className={classes.title}>Now choose the <b>Phone Number</b> your Organization will use to communicate with.</Typography>
         <Typography className={classes.title}>This is a necessary one-time $5 charge to prevent abuse.*</Typography>
         <Grid container item spacing={1} justifyContent={'center'} alignItems={'center'} alignContent={'center'} style={{marginBottom:40}}>
         {
            desiredNumber && <Grid item xs={8} className={classes.confirmContainer}>
               <Grid container item spacing={1} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                  <Grid item xs={12}>
                     <Typography variant={'h5'} className={classes.title} style={{marginBottom:20}}>Please confirm the one-time purchase of:<br/>
                        <b>{prettyPhone(desiredNumber)}</b>
                     </Typography>
                     {
                        isLoadingCheckout && <ProgressBar style={{marginTop: 20, marginBottom:20}}/>
                     }
                  </Grid>
                  <Grid item xs={6}>
                     <Button
                        disabled={isLoadingCheckout}
                        onClick={()=>setDesiredNumber('')}
                        variant={'contained'}
                        color={'default'}>Cancel</Button>
                  </Grid>
                  <Grid item xs={6}>
                     <Button
                        disabled={isLoadingCheckout}
                        onClick={handlePurchase}
                        variant={'contained'}
                        color={'primary'}><ShoppingCart style={{marginBottom:-5}}/>&nbsp;&nbsp;Purchase</Button>
                  </Grid>
               </Grid>
            </Grid>
         }
         </Grid>
         {!desiredNumber && <Grid container item spacing={1} justifyContent={'center'} alignItems={'center'} alignContent={'center'}
                className={classes.searchContainer}>
            <Grid item xs={8}>
               <TextField
                  autoFocus={true}
                  required={true}
                  key='phrase'
                  id='phrase'
                  label='Search by city or number'
                  value={filterPhrase}
                  margin='normal'
                  variant='outlined'
                  fullWidth={true}
                  type={'text'}
                  onChange={() => setFilterPhrase(event.target.value.replace(/[^a-zA-Z0-9.\s]/g, ''))}
                  InputProps={{
                     maxLength: 10,
                     //mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                     startAdornment: <InputAdornment position="start">
                        <Select
                           aria-label="select search filtering for desired phone number"
                           value={filterType}
                           disabled={numberType === 'toll-free'}
                        >
                           {['Starts With', 'Ends With'].map((item) =>  // todo - add back ,'Contains' when api is updated
                              <MenuItem key={item} value={item}
                                        onClick={(e) => setFilterType(item)}>
                                 {item}
                              </MenuItem>
                           )}
                        </Select>
                     </InputAdornment>,
                     endAdornment: <InputAdornment position="end">
                        <Typography style={{display: 'inline-block'}}>Toll-Free?</Typography>
                        <Tooltip
                           title={'We offer Local US phone numbers or Toll-Free (1-800, 1-833, 1-844, 1-855, 1-866, 1-877, 1-888)'}>
                           <Switch checked={numberType === 'toll-free'}
                                   onChange={() => setNumberType(numberType == 'local' ? 'toll-free' : 'local')}
                                   name="local_tollfree"/>
                        </Tooltip>
                     </InputAdornment>,
                  }}
               />
            </Grid>
            <Grid item xs={8}>
               {
                  isLoading || isRefetching ?
                     <ProgressBar style={{marginTop: 33}}/>
                     :
                     <List className={classes.listContainer}>
                        {data && Boolean(data.length) && data.map(item => {
                           return <ListItem key={item[0]}>
                              <ListItemText primary={prettyPhone(item[0])}
                                            secondary={item[2] != 'local' ? item[1][0].region_name : item[1][1].region_name + ", " + item[1][0].region_name}/>
                              <ListItemSecondaryAction>
                                 <Button
                                    onClick={() => setDesiredNumber(item[0])}
                                    variant={'contained'}
                                    color={'secondary'}>Claim</Button>
                              </ListItemSecondaryAction>
                           </ListItem>
                        })}
                        {data && Boolean(!data.length) &&
                           <Typography style={{textAlign: 'center'}}>
                              No results found, please adjust search.
                           </Typography>
                        }
                     </List>
               }
            </Grid>
         </Grid>}
         
      </>
    );
}

export default PhoneNumberPicker;

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getEmbeddableChat = async (organizationId, contactId, visitorId) => {
  const { token, userID } = useCurrentUser();
  if (!organizationId || [contactId, visitorId, userID].filter(Boolean).length !== 1) {
    return false;
  }

  const apiURL = `${API_HOST}/embeddable/chat?organizationId=${organizationId || ''}${
    contactId ? `&contactId=${contactId}` : ''
  }${visitorId ? `&visitorId=${visitorId}` : ''}`;

  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-sb-user-id': userID,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

import React, { useState, useEffect, useMemo, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem } from '@material-ui/core/';
import {
 useMediaQuery, useTheme,

} from '@material-ui/core';
import ImageOutput from "./components/ImageOutput";
import useAI from "../../lib/hooks/useAI";
import {toUnderScoreSlug} from "../../lib/helpers/helpers";




const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  previewButton:{
    color: theme.palette.secondary.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AIImageGen = () => {
  const [prompt, setPrompt] = useState("Image Generation (realistic)");
  const [debounceQuery, setDebounceQuery] = useState('');
  const [input, setInput] = useState();
  const timeoutIdRef = useRef(null);
  
  useEffect(() => {
    if (input) { // && input.slice(-2) === "::" || input && input.slice(-2) === "//"
      timeoutIdRef.current = setTimeout(() => {
        setDebounceQuery(input);
      }, 500);
      return () => {
        clearTimeout(timeoutIdRef.current);
      };
    }
  }, [input]);
  
  return (
    <>
                  <Grid item xs={12}>
                       <Select
                          fullWidth
                          aria-label="select from personalities"
                          value={prompt}
                          style={{marginBottom:40}}
                       >
                         {['Image Generation (realistic)', 'Image Generation (futuristic)', 'Image Generation (fun)', 'Image Generation (cute)', 'App Icon'].map((item,index)=> <MenuItem key={index} value={item}
                                                                       onClick={(e)=>setPrompt(item)}>
                           <span>{item}</span>
                         </MenuItem>
                         )}
                       </Select>
                  </Grid>
                  <ImageOutput input={input} setInput={setInput} prompt={prompt} debounceQuery={debounceQuery}/>
    </>
  );
}

export default AIImageGen;

import { useQuery } from 'react-query';
import { getVerifiedDomains} from "../api";

const useVerifiedDomains = () => {
  const result = useQuery('verified_domains', () => getVerifiedDomains(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useVerifiedDomains;

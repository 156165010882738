import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Typography, Menu, MenuItem, Grid, Checkbox, FormControlLabel, FormGroup, IconButton, Button } from '@material-ui/core/';
import { MoreVert, Delete, People } from '@material-ui/icons/';
import { deleteContactList } from '../../../lib/api/deleteContactList';
import {duplicateContactList, duplicateTemplate} from "../../../lib/api";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.backgrounds.paper,
  },
}));


export default function ListofLists({value, refetch}) {
  const classes = useStyles();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleDuplicateList = useCallback(() => {
    const confirm = window.confirm('Confirm Duplication: '+value.list_name)
    if(confirm) {
      duplicateContactList(value.id).then(response => {
        setAnchorEl(null);
        refetch();
      })
    }
  },[value])
  
  const deleteList = useCallback(event => {
    const confirm = window.confirm('Confirm Deletion: '+value.list_name)
    if(confirm){
      deleteContactList(value.id).then(()=>{
        setAnchorEl(null);
        refetch();
      })
    }
  },[value]);
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <People />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={value.list_name}
        secondary={value.total_contacts+' Contacts'}
      />
      <ListItemSecondaryAction>
        <Button variant={'outlined'} onClick={()=>navigate("../list/"+value.id, { replace: true })}>View</Button>
        &nbsp;
        <IconButton edge="end" aria-label="more"
                    aria-controls="list-management"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit">
          <MoreVert />
        </IconButton>
        <Menu
          id="list-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={()=>navigate("../list/"+value.id, { replace: true })}>Manage</MenuItem>
          <MenuItem onClick={handleDuplicateList}>Duplicate</MenuItem>
          <MenuItem onClick={deleteList}>Delete</MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

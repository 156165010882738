import { useQuery } from 'react-query';
import {getOrganizationTeammates} from "../api";

const useOrganizationTeammates = () => {
  const result = useQuery('organization_teammates', () => getOrganizationTeammates(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganizationTeammates;

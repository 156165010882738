import React, { useState, useEffect, useMemo, useRef} from 'react';
import { Grid } from '@material-ui/core/';

import {
  Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { toUnderScoreSlug} from "../../../lib/helpers/helpers";
import MarkdownEditor from "@uiw/react-markdown-editor";
import useAI from "../../../lib/hooks/useAI";
import AutoSuggest from '@avcs/autosuggest';
const KeywordExtraction = ({prompt, debounceQuery, setInput, input}) => {
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  
  const [keywords, setKeywords] = useState();
  const [alternativeKeywords, setAlternativeKeywords] = useState();
  
  const {isLoading: isLoadingAI, isRefetching: isRefetchingAI, data: ai, refetch: refetchAI} = useAI('writer_completion', toUnderScoreSlug(prompt), debounceQuery);
  const { response } = useMemo(()=>ai ?? [], [ai]);
  const textareaRef = useRef(null);
  
  useEffect(() => {
    if (response) {
      if (response.keywords && response.keywords.choices.length && response.keywords.choices[0] && response.keywords.choices[0].text) {
        setKeywords(response.keywords.choices[0].text)
      }
      if (response.alternatives && response.alternatives.choices.length && response.alternatives.choices[0] && response.alternatives.choices[0].text) {
        setAlternativeKeywords(response.alternatives.choices[0].text)
      }
    }
    }, [response]);
  
  return (
    <>
                  <Grid item xs={12}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                  <Grid item xs={matchSM ? 12 : 6}>
                    <Typography>Editor</Typography>
                    <MarkdownEditor
                       ref={textareaRef}
                       visible={true}
                       style={{minHeight:400, height:610}}
                       //value={markdown}
                       source={input}
                       onChange={(value, viewUpdate) => setInput(value)}
                    />
                  </Grid>
                  <Grid item xs={matchSM ? 12 : 6}>
                    <Grid item xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}>
                      <Grid item xs={6}>
                        <Typography>Keywords</Typography>
                        <MarkdownEditor.Markdown
                           style={{height:610, overflowY:'auto'}}
                           source={keywords}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>Alternative Keywords</Typography>
                        <MarkdownEditor.Markdown
                           style={{height:610, overflowY:'auto'}}
                           source={alternativeKeywords}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>
    </>
  );
}

export default KeywordExtraction;

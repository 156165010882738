import React, { useCallback, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  Menu,
  MenuItem,
  RadioGroup,
  Radio,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { Add, ShoppingCart } from '@material-ui/icons';
import useOrganization from '../../lib/hooks/useOrganization';
import { daysUntil, prettyDate, prettyNumber } from '../../lib/helpers/helpers';
import ProgressBar from '../ProgressBar/ProgressBar';
import { postCreditRefill } from '../../lib/api';
import { useSnackbar } from 'notistack';
import usePermission from '../../lib/hooks/usePermission';

const useStyles = makeStyles(theme => ({
  title: {
    float: 'right',
    textAlign: 'right',
    '& span': {
      marginBottom: 10,
      display: 'block',
      fontStyle: 'italic',
      fontSize: 14,
      color: theme.palette.primary.light,
    },
  },
  lastButton: {
    marginLeft: 15,
  },
  buttons: {
    '& button': {
      marginLeft: 15,
    },
    '& *': {
      color: theme.palette.common.white,
    },
  },
  creditsMenu: {
    padding: 30,
    '& ul': {
      width: 330,
      height: 360,
      lineHeight: 20,
    },
  },
  formControl: {
    marginTop: 20,
    '& legend': {
      marginBottom: 10,
    },
  },
  creditsTitle: {
    textAlign: 'center',
  },
}));
const AccountCredits = ({ expanded }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    isLoading: isLoadingOrganization,
    isRefetching: isRefetchingOrganization,
    data: organization,
    refetch: refetchOrganization,
  } = useOrganization();
  const { email_credits, sms_credits, ai_credits, current_period_end } = useMemo(
    () => organization ?? [],
    [organization],
  );
  const hasPermissionBillingSettings = usePermission('billing_settings.edit');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [buyEmail, setBuyEmail] = useState(true);
  const [buySms, setBuySms] = useState(true);
  const [buyAi, setBuyAi] = useState(true);
  const [confirmBuy, setConfirmBuy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discountCode, setDiscountCode] = useState('');

  const totalCost = useMemo(() => {
    let total = 0;
    const values = [buyEmail, buySms, buyAi];

    for (let i = 0; i < values.length; i++) {
      if (values[i]) {
        total += 10;
      }
    }
    return total;
  }, [buyEmail, buySms, buyAi]);
  const handleCreditsMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseCreditsMenu = () => {
    setAnchorEl(null);
  };
  const handlePurchase = useCallback(() => {
    setConfirmBuy(false);
    setLoading(true);
    postCreditRefill({ buyEmail, buySms, buyAi, discountCode }).then(response => {
      if (response && response.success) {
        enqueueSnackbar('Success!', { variant: 'success' });
        refetchOrganization();
        setAnchorEl(null);
      } else {
        enqueueSnackbar('Something went wrong, try again.', { variant: 'error' });
      }
      setLoading(false);
    });
  }, [buyEmail, buySms, buyAi, discountCode]);

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={'space-between'}
      alignItems={'center'}
      style={{ margin: '70px auto auto', textAlign: expanded ? 'center' : 'right' }}
    >
      <Grid item xs={12}>
        <Typography color="primary" className={expanded ? '' : classes.title}>
          Credits Remaining: {expanded && <br />}
          <span>(Renews on: {prettyDate(current_period_end)})</span>
        </Typography>
        <br />
      </Grid>
      <Grid item xs={12} className={classes.buttons}>
        <Button size={'small'} variant={'contained'} color={'primary'}>
          Email: {prettyNumber(email_credits || 0)}
        </Button>
        <Button size={'small'} variant={'contained'} color={'primary'}>
          SMS: {prettyNumber(sms_credits || 0)}
        </Button>
        <Button size={'small'} variant={'contained'} color={'primary'}>
          AI Gen: {prettyNumber(ai_credits || 0)}
        </Button>
        <Button size={'small'} variant={'contained'} color={'primary'} onClick={handleCreditsMenu}>
          <Add style={{ fontSize: 22 }} />
        </Button>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseCreditsMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          className={classes.creditsMenu}
        >
          <MenuItem className={classes.creditsTitle}>
            <Box>
              <Typography>
                <b>{daysUntil(current_period_end)} Days</b> left until your next batch of credits.
              </Typography>
              <Typography>Need a quick refill for now?</Typography>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Select which:</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={loading}
                        checked={buyEmail}
                        onChange={() => setBuyEmail(!buyEmail)}
                        name="email"
                      />
                    }
                    label="$10 - 1,000 Email Credits"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox disabled={loading} checked={buySms} onChange={() => setBuySms(!buySms)} name="sms" />
                    }
                    label="$10 - 500 SMS Credits"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox disabled={loading} checked={buyAi} onChange={() => setBuyAi(!buyAi)} name="ai" />
                    }
                    label="$10 - 1,000 AI Credits"
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </MenuItem>
          <MenuItem>
            <TextField
              autoFocus={true}
              fullWidth={true}
              variant="outlined"
              label="Enter Promo Code"
              placeholder=""
              value={discountCode}
              onChange={e => setDiscountCode(e.target.value)}
            />
          </MenuItem>
          <MenuItem>
            {loading && <ProgressBar style={{ marginTop: 10, width: '100%' }} />}
            {!loading && (
              <Grid container item justifyContent={'center'} alignItems={'center'} alignContent={'center'} xs={12}>
                <Grid item xs={6}>
                  <Typography style={{ margin: 'auto', textAlign: 'center' }}>Total: ${totalCost}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={
                      confirmBuy
                        ? handlePurchase
                        : hasPermissionBillingSettings
                        ? () => setConfirmBuy(true)
                        : () => enqueueSnackbar('Please ask Admin for permission.', { variant: 'error' })
                    }
                    disabled={totalCost === 0 && !discountCode.length}
                    variant={'contained'}
                    color={'secondary'}
                  >
                    <ShoppingCart style={{ marginBottom: -2 }} />
                    &nbsp;&nbsp;{confirmBuy ? 'Confirm' : 'Buy'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default AccountCredits;

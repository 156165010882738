import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Typography, Button,
  FormGroup, FormHelperText,
  TextField,
  withStyles, Checkbox, ListItemText,
} from '@material-ui/core';
import { Add, GroupAdd } from '@material-ui/icons';
import { ProgressBar } from '../ProgressBar';
import {postContactHashtag, postContactsToHashtags} from '../../lib/api';
import { AdminCheckbox } from '../AdminCheckbox';
import { AddContactDialog } from '../AddContactDialog';
import useContacts from '../../lib/hooks/useContacts';
import useContactHashtags from "../../lib/hooks/useContactHashtags";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import useHashtags from "../../lib/hooks/useHashtags";
import {ContactsContext} from "../../lib/contexts";
import {useSnackbar} from "notistack";
import usePermission from "../../lib/hooks/usePermission";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    },
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addcontactHashtag:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  hashtagBorder:{
    borderWidth:0,
    borderBottomWidth:2,
    borderStyle:'solid'
  }
}));

const filter = createFilterOptions();
const AddContactsToHashtagsDialog = ({ forceOpen = false, setForceOpen, contact, refetch, size, invisible, viewingHashtag = undefined}) => {
  const {
    state: { viewingContact },
  } = useContext(ContactsContext);
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(invisible);
  
  const [value, setValue] = useState(null);
  
  
  const {isLoading, isRefetching, data, refetch:refetchInternal } = useContacts(viewingContact, true);
  const {isLoading:isLoadingHashtags, isRefetching:isRefetchingHashtags, data:dataHashtags, refetch:refetchInternalHashtags } = useHashtags(viewingContact);
  const hasPermissionEdit = usePermission('contacts.edit')
  const contactHashtagData = useMemo(()=>dataHashtags && !dataHashtags.error ? dataHashtags : [],[dataHashtags]);
  const activeHashtags = contactHashtagData.filter(item => item.in_use);
  
  const [chosenHashtags, setChosenHashtags] = useState(activeHashtags || []);
  
  const tableData = useMemo(()=>
     viewingContact ?
        contactHashtagData && !contactHashtagData.error ? contactHashtagData : [] :
        data && !data.error ? data : []
     ,[data, contactHashtagData, viewingContact]);
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open,forceOpen]);
  
  const handleChange = useCallback((hashtag, remove = false, override=false ) => {
    if(hashtag){
      if(override){
        setChosenHashtags(hashtag);
      }else{
        setChosenHashtags(!remove ? [...chosenHashtags.filter(item=>item.id !== hashtag.id), hashtag] : chosenHashtags.filter(item=>item.id !== hashtag.id))
      }
      //setChosenHashtags([...chosenHashtags, hashtag])
    }
  },[chosenHashtags]);
  
  const handleSave = useCallback(() => {
    if(hasPermissionEdit){
    if(chosenHashtags){
      setSaving(true);
      const chosenHashtagsIds = chosenHashtags.map(hashtag=>hashtag.id);
      postContactsToHashtags(viewingContact ? chosenHashtagsIds : [chosenHashtagsIds], viewingContact ? [viewingContact] : []).then(()=>{
        setSaving(false);
        refetchInternal();
        if(refetch !== undefined){
          refetch();
        }
        handleToggleOpen();
      })
    }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[chosenHashtags, viewingContact, hasPermissionEdit]);
  
  const saveNewHashtag = useCallback((hashtagName) => {
    if(hasPermissionEdit){
    if(hashtagName){
      postContactHashtag(hashtagName).then(response=>{
        setValue('');
        refetchInternalHashtags();
        handleChange([...chosenHashtags, response], false, true);
      })
    }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[chosenHashtags, hasPermissionEdit]);
  
  return (
     <>
       {!invisible && <Button style={{ height:size === 'small' ? 40 : 50}} variant={'contained'} color={'primary'}
                              onClick={handleToggleOpen} disabled={open || saving}>
         {size != 'text' && <Add/>} {size == 'text' && <Typography>{'Add Hashtag'}</Typography>}
       </Button>}
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-contacts-to-hashtags-dialog" className={classes.form}
      >
        <DialogTitle id="add-contacts-to-hashtags-dialog-title"><GroupAdd/> {viewingContact ? 'Add Hashtag to Contact' : 'Choose Contacts to Add to this Hashtag'} </DialogTitle>
        <DialogContent>
          {
            saving || isLoading || isLoadingHashtags ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
              <>
                <DialogContentText>
                  Organize your Contacts by Hashtags to easily drop them into Campaigns or Contacts Lists.
                </DialogContentText>
                <form noValidate>
                  <FormGroup style={{margin:'20px auto 40px', width:'90%', display:'block'}}>
                    <Autocomplete
                       fullWidth={true}
                       value={value}
                       onChange={(event, newValue) => {
                         if (typeof newValue === 'string') {
                           //setValue({hashtag: newValue});
                         } else if (newValue && newValue.inputValue) {
                           // Create a new value from the user input
                           //setValue('');
                         } else {
                           //setValue('');
                         }
                         handleChange(newValue);
                         setValue('');
                       }}
                       filterOptions={(options, params) => {
                         const filtered = filter(options, params);
       
                         // Suggest the creation of a new value
                         if (params.inputValue !== '') {
                           filtered.push({
                             inputValue: params.inputValue,
                             hashtag: `Add "${params.inputValue}"`,
                           });
                         }
       
                         return filtered;
                       }}
                       selectOnFocus
                       clearOnBlur
                       handleHomeEndKeys
                       id="hashtag-autocomplete"
                       options={contactHashtagData}
                       getOptionLabel={(option) => {
                         // Value selected with enter, right from the input
                         if (typeof option === 'string') {
                           return option;
                         }
                         // Add "xxx" option created dynamically
                         if (option.inputValue) {
                           saveNewHashtag(option.inputValue)
                           return option.inputValue;
                         }
                         // Regular option
                         if(option.hashtag){
                           return option.hashtag;
                         }
                         return option.hashtag || option.title || option;
                       }}
                       renderOption={(option) => option.hashtag}
                       freeSolo
                       renderInput={(params) => (
                          <TextField fullWidth={true} {...params} label="Add or Create" variant="outlined" />
                       )}
                    />
                
                
                
                </FormGroup>
                  <FormGroup style={{margin:'20px auto 10px', width:'90%', maxHeight:320, overflowY:'auto', display:'grid'}}>
                    {Boolean(chosenHashtags.length) && chosenHashtags.map((hashtag, index) => {
                      return (
                      <FormControlLabel className={classes.hashtagBorder}
                                        control={<Checkbox color={'primary'} checked={true} onChange={()=>handleChange(hashtag, true)} name={String(hashtag.id)} />}
                                        label={<ListItemText primary={`#${hashtag.hashtag}`}/>}
                                        key={String(hashtag.id)}
                      />
                      )
                    })
                    }
                  </FormGroup>
                  {viewingContact ? <>{/*<AddContactHashtagDialog size={'small'} refetch={refetchInternal} title={''}/>*/}</> : <AddContactDialog size={'small'} refetch={refetchInternal} title={''}/>}
                  <FormHelperText style={{marginTop:5,textAlign:'center'}}>Not seeing a {viewingContact ? 'Hashtag' : 'Contact'}? Create one directly above.</FormHelperText>
                </form>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave}
                  className={classes.addButton}
                  disabled={saving || chosenHashtags.length === 0}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AddContactsToHashtagsDialog;



// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  {
    title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];

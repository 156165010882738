import React, { useState, useEffect, useRef, useCallback } from 'react';
import './style.css';
import PropTypes from 'prop-types';
import { blue } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { SideBarNavigation } from '../../components/SideBarNavigation/';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DoneIcon from '@material-ui/icons/Done';
import { useQuery, useMutation } from 'react-query';
import { loadNewAppointments, updateAppointment } from '../../lib/api/adminPanelActions';

import {
  Grid,
  Divider,
  Typography,
  withStyles,
  Avatar,
  LinearProgress,
  CssBaseline,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  MuiThemeProvider,
  Card,
  Tabs,
  Tab,
  Checkbox,
  TableSortLabel,
  IconButton,
} from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: { main: '#2E74C5', secondary: '#E89B49', tri: '#f5f5f5' },
  },
  typography: {
    fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
  },
});

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#f5f5f5',
  },
  drawerText: {
    color: 'white',
    padding: 0,
  },
  content: {
    flexGrow: 1,
    padding: `${theme.spacing(2)} px`,
    marginLeft: 275,
    marginRight: 25,
  },
  toolbar: theme.mixins.toolbar,
  contactCard: {
    height: 400,
    width: '100%',
    padding: 20,
  },

  avatar: {
    color: '#fff',
    backgroundColor: blue[700],
    display: 'inline-flex',
  },
  searchBox: {
    width: '91.5%',
    margin: 'auto',
  },
  detailTable: {},
});

const AdminScreen = props => {
  const { classes, provider, logout, token } = props;

  const [expandedMode, setExpandedMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('unix_time');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [refreshTimer, setRefreshTimer] = useState(60);

  const setIntervalRef = useRef();

  const { data, isFetching, refetch } = useQuery('appointments', async () => {
    const { appointments } = await loadNewAppointments(rowsPerPage, page, token);
    return appointments;
  });

  const { mutateAsync: mutatePatientContacted } = useMutation(async row => {
    const { id, patient_contacted } = row;
    const params = {
      id: id,
      patient_contacted: patient_contacted,
      token: token,
    };
    await updateAppointment(params);
  });

  const { mutateAsync: mutatePatientAccepted } = useMutation(async row => {
    const { id, patient_accepted } = row;
    const params = {
      id: id,
      patient_accepted: patient_accepted,
      token: token,
    };
    await updateAppointment(params);
  });

  useEffect(() => {
    document.title = 'Admin | SendBoth';
  }, []); //html page title

  useEffect(() => setTotalCount(!data ? data.length : 0), [data]);

  useEffect(() => {
    // updateTimer
    const intervalID = setInterval(() => {
      setRefreshTimer(updateTimer());
    }, 1000);

    setIntervalRef.current = intervalID;

    return () => {
      clearInterval(setIntervalRef.current);
    };
  });

  const updateTimer = () => {
    if (refreshTimer > 1) {
      return refreshTimer - 1;
    } else {
      clearInterval(setIntervalRef.current);
      refetch();
      return 60;
    }
  };

  const changeTab = useCallback(
    v => {
      setSelectedTab(v);
      switch (v) {
        case 0:
          window.location.hash = '#mass';
          break;
      }
    },
    [selectedTab],
  );

  const handleRequestSort = useCallback(
    property => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleChangePage = useCallback(
    newPage => {
      setPage(newPage);
      if (newPage * rowsPerPage + rowsPerPage <= totalCount - rowsPerPage) refetch();
    },
    [page],
  );

  const handleChangeRowsPerPage = useCallback(
    event => {
      setRowsPerPage(event ? event.target.value : 25);
      setPage(0);
    },
    [rowsPerPage],
  );

  return (
    <MuiThemeProvider theme={theme} className={classes.root}>
      <CssBaseline />
      <SideBarNavigation
        classes={classes}
        active="admin"
        provider={provider}
        passon={v => setExpandedMode(v)}
        logout={logout}
      />

      <main className={expandedMode ? classes.content + ' pageContent expanded' : classes.content + ' pageContent'}>
        <div className={classes.searchBox} style={{ display: 'none' }}>
          <Card style={{ paddingTop: '2%', paddingLeft: '2%', paddingRight: '2%' }}>
            <br />
            <Tabs
              settings={{ marginTop: '2%' }}
              value={selectedTab}
              onChange={(e, v) => changeTab(v)}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label=""
              centered
            >
              <Tab icon={<MobileFriendlyIcon />} label="Rebook Reporting" />
            </Tabs>
          </Card>
        </div>

        <br />
        <br />
        <TabPanel value={selectedTab} index={0}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            spacing={0}
            style={{ paddingTop: 0, marginTop: 0 }}
          >
            {isFetching ? (
              <>
                <LinearProgress
                  color="primary"
                  style={{ width: '80%', display: 'block', textAlign: 'center', margin: '35% auto 0px auto' }}
                />
              </>
            ) : (
              <>
                <Card className="contactCard_short">
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ fontSize: 15, float: 'right', clear: 'both' }}
                    color="textSecondary"
                  >
                    Refreshing in: {refreshTimer}s<br />
                    <LinearProgress
                      value={1.677 * (60 - refreshTimer) + 1}
                      variant={'determinate'}
                      color="primary"
                      style={{ width: '80%', display: 'block', textAlign: 'center', margin: '5px auto 0px auto' }}
                    />
                  </Typography>
                  <Typography className={'patient_name'} variant="h5" style={{ marginBottom: 8 }}>
                    <Avatar style={{ display: 'inline-flex', backgroundColor: blue[700] }}>
                      <MobileFriendlyIcon />
                    </Avatar>{' '}
                    Recent Appointments
                  </Typography>

                  <Divider />
                  <Typography
                    className={'date_details'}
                    variant="h7"
                    style={{ marginTop: 10 }}
                    color="textSecondary"
                    gutterBottom
                  ></Typography>
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={0}
                    style={{ paddingTop: 0, marginTop: 20 }}
                  >
                    <div style={{ width: '100%', overflowX: 'auto', maxHeight: 700 }}>
                      <Table size="small" style={{ fontSize: '5 !important' }}>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(event, property) => handleRequestSort(property)}
                        />
                        <TableBody>
                          {data &&
                            stableSort(data, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id + '_' + index}
                                    className={row.patient_contacted ? 'doneItem' : 'pendingItem'}
                                  >
                                    <TableCell align="left">{row.date_time}</TableCell>
                                    <TableCell align="left">
                                      <b>{row.patient_name}</b>
                                      <br />
                                      {row.patient_number &&
                                        row.patient_number
                                          .substr(2)
                                          .replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3, p4) => {
                                            let output = '';
                                            if (p1) output = `${p1}`;
                                            if (p2) output += `${p2}-`;
                                            if (p3) output += `${p3}-`;
                                            if (p4) output += `${p4}`;
                                            return output;
                                          })}
                                      <br />
                                      {row.patient_email}
                                      <br />
                                      <b>Pharmacy:</b>
                                      <br />
                                      {row.patient_pharma ? row.patient_pharma : 'None'}
                                    </TableCell>
                                    <TableCell align="left">
                                      <b>Gender:</b>
                                      <br />
                                      {row.patient_gender}
                                      <br />
                                      <b>DOB:</b>
                                      <br />
                                      {row.patient_dob}
                                      <br />
                                      <b>
                                        {row.patient_insurance_type}:&nbsp;
                                        {row.patient_insurance_status ? (
                                          <DoneIcon style={{ height: 18, marginBottom: -3 }} />
                                        ) : (
                                          <></>
                                        )}
                                      </b>
                                      <br />
                                      {row.patient_insurance}
                                      <br />
                                      <b>PCP:</b>
                                      <br />
                                      {row.patient_pcp}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.patient_address}
                                      <br />
                                      {row.patient_location}
                                    </TableCell>
                                    <TableCell align="left">{row.patient_reason}</TableCell>
                                    <TableCell align="center">
                                      <Checkbox
                                        checked={row.patient_contacted}
                                        onChange={() => {
                                          row.patient_contacted = !row.patient_contacted;
                                        }}
                                        onClick={() => mutatePatientContacted(row)}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <Checkbox
                                        checked={row.patient_accepted}
                                        onChange={() => {
                                          row.patient_accepted = !row.patient_accepted;
                                        }}
                                        onClick={() => mutatePatientAccepted(row)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </div>
                    <Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        style={{ float: 'right' }}
                        colSpan={3}
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={newPage => handleChangePage(newPage)}
                        onChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                        ActionsComponent={TablePaginationActions}
                      />
                    </Table>
                  </Grid>
                </Card>
              </>
            )}
          </Grid>
        </TabPanel>
      </main>
    </MuiThemeProvider>
  );
};

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const headCells = [
  { id: 'unix_time', numeric: true, label: 'Date Time' },
  { id: 'patient_name', numeric: false, label: 'Patient Info' },
  { id: 'patient_number', numeric: false, label: 'Patient Details' },
  { id: 'patient_location', numeric: false, label: 'Location' },
  { id: 'patient_reason', numeric: false, label: 'Reason' },
  { id: 'patient_contacted', numeric: false, label: 'Patient Called' },
  { id: 'patient_accepted', numeric: false, label: 'Patient Scheduled' },
];

const EnhancedTableHead = props => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  style={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const TablePaginationActions = props => {
  const { count, page, rowsPerPage, onChangePage } = props;

  return (
    <div
      style={{
        flexShrink: 0,
        marginLeft: 15,
      }}
    >
      <IconButton onClick={() => onChangePage(0)} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={() => onChangePage(page - 1)} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={() => onChangePage(page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={() => onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default withStyles(styles)(AdminScreen);

export const tutorialSteps = [
  {
    target: 'body',
    title: "Welcome to Early Access: Phase 1!", //"You're ready to begin!",
    content: <div dangerouslySetInnerHTML={{ __html:
         "<div style='text-align:left; font-weight:bold;'>Phase 1:</div>" +
         "<div style='text-align:left;'>Communicate with your current <i>Contacts</i> using Campaigns & Templates.</div><br/>" +
         "<div style='text-align:left; font-weight:bold;'>Phase 2:</div>" +
         "<div style='text-align:left;'>Embeddable Growth Widgets & expanded Dashboard to turn <i>Visitors</i> into <i>Contacts</i>.</div><br/>" +
         "<div style='text-align:left; font-weight:bold;'>Phase 3:</div>" +
         "<div style='text-align:left;'>Asset Management, Embeddable Chat, Heatmaps, & more integrations!</div><br/>"
    }} />
    ,
    locale: {
      back: 'Back', close: 'Get Started', last: 'Last', open: 'Open the dialog', skip: 'Skip',
      next: 'Get Started',
    },
    hideCloseButton: true,
    disableBeacon: true,
    placement: 'center',
  },
  {
    target: 'body',
    title: "You're ready to begin!",
    content: "Let's give you a quick walkthrough of your Dashboard.",
    locale: {
      back: 'Back', close: 'Get Started', last: 'Last', open: 'Open the dialog', skip: 'Skip',
      next: 'Get Started',
    },
    hideCloseButton: true,
    disableBeacon: true,
    placement: 'center',
  },
  {
    target: 'body .MuiTypography-root.MuiTypography-h2.MuiTypography-colorPrimary',
    content: "These are your Campaign Metrics over the last 30 Days. You can click on each one to always refresh for the latest data.",
    placement: 'bottom-start',
    eBeacon: false,
    disableBeacon: true,
    title: 'Campaign Overview',
    locale: {
      close: 'Next',
    },
  },
  {
    target: 'body .MuiTypography-root.MuiTypography-h2.MuiTypography-colorPrimary:nth-of-type(2)',
    content: "Here you can quickly add or manage your Contact Lists.",
    placement: 'top-start',
    eBeacon: false,
    disableBeacon: true,
    title: 'Contact Lists',
    locale: {
      close: 'Next',
    },
  },
  {
    target: 'body .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-space-between.MuiGrid-grid-xs-12',
    content: "Here you can keep an eye on your credit usage or always buy a quick re-fill.",
    placement: 'bottom-start',
    eBeacon: false,
    disableBeacon: true,
    title: 'Email, SMS & AI Credits',
    locale: {
      close: 'Next',
    },
  },
  {
    target: 'body',
    content: 'New ways to save you time are coming soon.',
    placement: 'center',
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    spotlightClicks: true,
    showSkipButton: false,
    title: "But wait, there's more!",
    locale: {
      last: 'Close',
    },
  },
];
export const tutorialStepsOnboarding = [
  {
    target: 'body',
    title: 'Welcome to the Beta!',
    content:
      'The new Partner Portal attempts to automate various pain-points on your behalf.',
    locale: {
      last: "Let's Go!",
    },
    hideCloseButton: true,
    disableBeacon: true,
    showSkipButton: false,
    placement: 'center',
  },
];

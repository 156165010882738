import * as React from 'react';
import { ListItem, ListItemIcon, ListItemText, Divider} from "@material-ui/core";
import {
   BorderTop, BrandingWatermark,
   DoneAll, FeaturedVideo,
   Palette, PersonAdd, PostAdd, VisibilityOff,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {useContext} from "react";
import {UserContext} from "../../../lib/contexts";
import List from "@material-ui/core/List";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.backgrounds.paper,
      '& nav':{
         padding: '0px !important',
         margin: '0px !important'
      }
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
   icon: {
      color: theme.palette.primary.paperText,
   },
}));

export default function Sidebar({selectedIndex}) {
   const classes = useStyles();
   let navigate = useNavigate();
   const [expandOrg, setExpandOrg] = React.useState(true);
   
   const handleClick = () => {
      setExpandOrg(!open);
   };
   const {
      state: {
         showingRange,
         startDate,
         endDate
      },
      actions: {
         setShowingRange
      }
   } = useContext(UserContext);
   
   const handleListItemClick = (event, index) => {
      (() => {
         switch (index) {
            case 0:
               navigate("../widgets/site_wide_banner", { replace: true })
               break;
            case 1:
               navigate("../widgets/newsletter_form", { replace: true })
               break;
            case 2:
               navigate("../widgets/contact_form", { replace: true })
               break;
            case 3:
               navigate("../widgets/chat_support_form", { replace: true })
               break;
            case 4:
               navigate("../widgets/branded_landing_page", { replace: true })
               break;
            case 5:
               navigate("../widgets/pop_up_on_leave", { replace: true })
               break;
            case 6:
               navigate("../widgets/embeddable_survey", { replace: true })
               break;
            case 7:
               navigate("../widgets/invisible_pixel", { replace: true })
               break;
            default:
               return true
         }
      })()
   };
   
  return (
     <div className={classes.root}>
        <List component="nav" aria-label="widget sidebar">
           <ListItem
              button
              selected={selectedIndex === 7}
              onClick={(event) => handleListItemClick(event, 7)}
           >
              <ListItemIcon>
                 <VisibilityOff  className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Invisible Pixel" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
           >
              <ListItemIcon>
                 <BorderTop className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Embeddable Site-Wide Banner" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 1)}
           >
              <ListItemIcon>
                 <PersonAdd className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Embeddable Newsletter Form" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 2)}
           >
              <ListItemIcon>
                 <PostAdd className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Embeddable Contact Form" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 7}
              onClick={(event) => handleListItemClick(event, 3)}
           >
              <ListItemIcon>
                 <BrandingWatermark className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Embeddable Faux Chat Support" />
           </ListItem>
           <Divider/>
           <ListItem
              button
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 4)}
           >
              <ListItemIcon>
                 <Palette className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Branded Landing Page" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 5)}
           >
              <ListItemIcon>
                 <FeaturedVideo className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Pop-Up on Page Leave" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
           >
              <ListItemIcon>
                 <DoneAll  className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Embeddable Survey" />
           </ListItem>
        </List>
     </div>
  );
}

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const postUserReset = async (loginData, partnerID) => {
  const apiURL = API_HOST + '/password_resets/update';
  const { token, userID } = useCurrentUser();
  
  
  try {
    const config = {
      headers: {
        'Cache-Control': 'no-store',
        'Content-Type': 'application/json',
        'x-sb-user-id': userID,
        Accepts: 'application/json',
        'Authorization': 'Bearer ' + token,
        'Access-Control-Max-Age': 60 * 60 * 24 //See: https://stackoverflow.com/a/40373949/609956
      },
    };
    const result = await axios.post(apiURL, loginData, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};

import { useQuery } from 'react-query';
import {getOrganizationInvitations} from "../api";

const useOrganizationInvitations = () => {
  const result = useQuery('organization_invites', () => getOrganizationInvitations(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganizationInvitations;

import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  outlinedPrimary:{
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderRadius: 20,
    fontSize: 12,
    '& svg':{
      fontSize: 18
    }
  },
  containedPrimary:{
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    padding:12,
    fontSize: 16,
    '& svg':{
      fontSize: 18
    }
  },
}));
const AdminButton = ({style, onClick, variant, fullWidth, children, disabled}) => {
  const classes = useStyles();
  return (
    <Button color="primary"
            variant={variant || 'outlined'}
            fullWidth={fullWidth}
            disabled={disabled}
            onClick={onClick}
            className={classes.progressBarLoading}
            classes={{outlinedPrimary: classes.outlinedPrimary, containedPrimary: classes.containedPrimary}}
            style={style}>
      {children}
    </Button>
  );
};

export default AdminButton;

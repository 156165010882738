import React, {Component, useMemo, useState, useEffect, useContext, useCallback} from 'react';
import {
  Box,
  Divider,
  Button,
  Switch,
  FormControlLabel,
  LinearProgress,
  Paper,
  CardContent,
  Grid,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  Tooltip
} from "@material-ui/core";
import { CheckCircle, Cancel, Group } from '@material-ui/icons'
import usePrices from '../../lib/hooks/usePrices';
import { formatMoney, toCapitalize } from '../../lib/helpers/helpers';
import { BillingContext } from '../../lib/contexts/BillingContext';
import {updateOrganization} from "../../lib/api";
import useOrganization from "../../lib/hooks/useOrganization";
import usePermission from "../../lib/hooks/usePermission";
import {useSnackbar} from "notistack";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  switch:{
    textAlign: 'center',
    display: 'block',
    margin: '20px auto 20px',
    color: theme.palette.primary.main,
  },
  plan:{
    minHheight:500,
    height:'100%',
    cursor:'pointer',
    '& :hover':{
    
    }
  },
  topRowPlan:{
    minHheight:500,
    height:'100%',
    cursor:'pointer',
    '& :hover':{
    
    }
  },
  mo:{
    fontSize:18
  },
  divide:{
    marginTop:20,
    marginBottom:20
  },
  features:{
    maxWidth: '90%',
    margin: 'auto'
  },
  bigger:{
    fontSize:22,
    margin:5,
    '& svg':{
      fontSize:30,
      marginBottom: -6,
      marginRight: 5
    },
  },
  pos:{
    fontSize:17,
    margin:5,
    '& svg':{
      marginBottom: -5,
      marginRight: 5
    },
  },
  check:{
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },
  cancel:{},
  totalCredits:{
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  disclaimer:{
    margin:"auto",
    fontSize:12,
    textAlign:'center',
    display:'none'
  }
}));
const PlanPicker = ({handleCheckout, handleToggleOpen}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    state: { chosenPlan },
    actions: { setChosenPlan },
  } = useContext(BillingContext);
  const { data, isLoading } = usePrices();
  const { enqueueSnackbar } = useSnackbar()
  const freePlan = useMemo(()=>data ? data.filter((item)=>item && item.unit_amount === 0)[0] : [],[data]);
  const mostPopularPlan = useMemo(()=>data ? data.filter((item)=>item && item.nickname && item.nickname.includes('Basic') && item.recurring.interval === "month")[0] : [],[data]);
  const monthlyPlans = useMemo(()=>data ? data.filter((item)=>item && item.nickname && !item.nickname.includes('Premium') && item.unit_amount !=0 && item.recurring.interval === "month") : [],[data]);
  const annualPlans = useMemo(()=>data ? data.filter((item)=>item && item.nickname && !item.nickname.includes('Premium') && item.unit_amount !=0 && item.recurring.interval === "year") : [],[data]);
  const [viewingMonthly, setViewingMonthly] = useState(true);
  const lookingAt = useMemo(()=> {
    const plans = viewingMonthly ? monthlyPlans : annualPlans;
    return plans.sort((a, b) => {
      return a.unit_amount - b.unit_amount;
    })
  },[viewingMonthly, annualPlans, monthlyPlans]);
  const [autoRefill, setAutoRefill] = useState();
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  const hasPermissionBillingSettings = usePermission('billing_settings.edit')
  
  useEffect(()=>{
    if(organization){
      setAutoRefill(organization.auto_refill_tokens)
    }
  },[organization])
  useEffect(()=>{
    setChosenPlan(mostPopularPlan.id)
  },[mostPopularPlan])
  
  const handleFreeAccount = useCallback(()=>{
      handleToggleOpen()
    // - If we enable the Stripe free plan, it still forces them to go through checkout flow
    // - and prevents them from trialing credit-card free with promo codes
    //setChosenPlan("price_1LbVINAfIAD0J5sWlyLggWUf") // free
    //handleCheckout("price_1LbVINAfIAD0J5sWlyLggWUf")
  },[handleCheckout, handleToggleOpen])
  
  
  const handleAutoRefill = useCallback(()=>{
    if(hasPermissionBillingSettings){
      const newValue = !autoRefill
      updateOrganization({auto_refill_tokens: newValue}).then(()=>{
        setAutoRefill(newValue)
        enqueueSnackbar('Success', {variant:'success'})
      })
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[hasPermissionBillingSettings, autoRefill])
  
    return (
      <>
        {isLoading || data && data.lenth === 0 ?
          <LinearProgress style={{margin:30}}/>
          :
          <>
            <FormControlLabel className={classes.switch}
                              control={<Switch checked={autoRefill} onChange={handleAutoRefill} />}
                              label="Enable credit auto-refill to prevent scheduled campaigns from being paused?"
            />
  
            <Grid container spacing={2}>
              {lookingAt.map((item, index)=>{
                return (<PlanPickerItem item={item} viewingMonthly={viewingMonthly} key={index} />)
              })}
{/*
              <PlanPickerItem item={{nickname:'Enterprise', recurring:{interval:'year'}}} viewingMonthly={viewingMonthly}/>
*/}
            </Grid>
  
            <FormControlLabel className={classes.switch} style={{marginBottom:0}}
                              control={<Switch checked={!viewingMonthly} onChange={()=>setViewingMonthly(!viewingMonthly)} />}
                              label="Activate 10% Discount with our annual plans?"
            />
            {freePlan &&
            <Button color="textSecondary" style={{margin:'10px auto auto', display:'block'}} onClick={handleFreeAccount}>
              Skip for now
            </Button>}
            <Typography className={classes.disclaimer}>* Refunds require no more than 5% of Credits being used.</Typography>
          </>
        }
      </>
    );
}
const PlanPickerItem = ({item, viewingMonthly}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    state: { chosenPlan },
    actions: { setChosenPlan },
  } = useContext(BillingContext);
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const matchSMM = useMediaQuery(theme.breakpoints.down('sm'));
  const stripePriceId = item.id;
  const stripeProductId = item.product;
  const chosen = useMemo(()=>item.id === chosenPlan,[chosenPlan]);
    return (<>
      {item &&
  <Grid item xs={matchSM || matchSMM ? 12 : 3}>
    <Paper elevation={chosen ? 5 : 1} onClick={()=>setChosenPlan(item.id)} className={item.nickname.includes('Premium') || item.nickname.includes('Plus') || item.nickname.includes('Standard') ? classes.topRowPlan : classes.plan}>
      <CardContent>
        <Typography className={classes.title} color="primary" gutterBottom>
          {item.nickname.includes('Basic') ? 'Most Popular!' : <br/>}
        </Typography>
        <Typography variant="h4" component="h2">
          {item.nickname+' - '}
          {item.nickname.includes('Enterprise') && "Let's Chat"}
          {item.unit_amount ? formatMoney(viewingMonthly ? item.unit_amount : Math.floor(item.unit_amount/12), true).slice(0, -3) : ''}
          {item.unit_amount && <span className={classes.mo}>/mo</span>}
        </Typography>
        <Typography className={classes.pos} color="textSecondary" style={{display:'none'}}>
          Billed {toCapitalize(item.recurring.interval === 'year' ? 'annual' : 'month')}ly
        </Typography>
        <Divider className={classes.divide}/>
        <Box className={classes.features}>
  
          {
             item.nickname.includes('Lite') ?
             <LiteFeatures/>
             :
                <>
                  <Typography className={classes.bigger} color="textSecondary">
                    <Group className={classes.check}/> {item.nickname.includes('Enterprise') ? 'Unlimited' : item.nickname.includes('Premium') ? '200,000' : item.nickname.includes('Plus') ? '100,000' : item.nickname.includes('Standard') ? '50,000' : item.nickname.includes('Basic') ? '25,000' : '10,000'} Subscribers
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckCircle className={classes.check}/> Send SMS <span className={classes.totalCredits}>(Credits: {item.nickname.includes('Enterprise') ? 'Unlimited' : item.nickname.includes('Premium') ? '20k' : item.nickname.includes('Plus') ? '10k' : item.nickname.includes('Standard') ? '5k' : item.nickname.includes('Basic') ? '2.5k' : '1k'})</span>
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckCircle className={classes.check}/> Send Emails <span className={classes.totalCredits}>(Credits: {item.nickname.includes('Enterprise') ? 'Unlimited' : item.nickname.includes('Premium') ? '1M' : item.nickname.includes('Plus') ? '500k' : item.nickname.includes('Standard') ? '150k' : item.nickname.includes('Basic') ? '75k' : '50k'})</span>
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckCircle className={classes.check}/> AI Generations <span className={classes.totalCredits}>(Credits: {item.nickname.includes('Enterprise') ? 'Unlimited' : item.nickname.includes('Premium') ? '500k' : item.nickname.includes('Plus') ? '100k' : item.nickname.includes('Standard') ? '50k' : item.nickname.includes('Basic') ? '25k' : '10k'})</span>
                  </Typography>
                  {item.nickname.includes('Enterprise') ? <EnterpriseFeatures/> : item.nickname.includes('Premium') ? <PremiumFeatures/> : item.nickname.includes('Plus') ? <PlusFeatures/> : item.nickname.includes('Standard') ? <StandardFeatures/> : item.nickname.includes('Basic') ? <BasicFeatures/> : ''}
  
                  <Typography className={classes.pos} color="textSecondary">
                    <CheckCircle className={classes.check}/> Everything on the {item.nickname.includes('Enterprise') ? 'Premium' : item.nickname.includes('Premium') ? 'Plus' : item.nickname.includes('Plus') ? 'Standard' : item.nickname.includes('Standard') ? 'Basic' : item.nickname.includes('Basic') ? 'Lite' : ''} Plan
                  </Typography>
                </>
          }
        </Box>
        
      </CardContent>
    </Paper>
  </Grid>}
</>
    );
}

const LiteFeatures = () => {
  const classes = useStyles();
  return <>
    <Typography className={classes.bigger} color="textSecondary">
      <Group className={classes.check}/> 10,000 Subscribers
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Send SMS <span className={classes.totalCredits}>(Credits: 1k)</span>
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Send Emails <span className={classes.totalCredits}>(Credits: 50k)</span>
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> AI Generations <span className={classes.totalCredits}>(Credits: 10k)</span>
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Powerful CRM
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Dynamic Contact Lists / Hashtags
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Campaigns w/ Scheduling
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Responsive & Custom Templates
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Embeddable Growth Widgets (Coming Soon)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> In-depth Reporting
    </Typography>
  </>
}
const BasicFeatures = () => {
  const classes = useStyles();
  return <>
    <Tooltip title={'Delivers the Campaign in Batches to prevent flooding your Website'}>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Batch Delivery
    </Typography>
    </Tooltip>
    <Tooltip title={'Decides the best Delivery Window based off Historical Data'}>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> AI-Optimized Delivery
    </Typography>
    </Tooltip>
      <Tooltip title={'Bases the Delivery Time off of the Contact\'s Time Zone'}>
        <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Time-Warp Delivery
        </Typography>
      </Tooltip>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Attach Photos to SMS (MMS)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> SMS Inbox
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Unlimited Team Members (+$5/user)
    </Typography>
  </>
}
const StandardFeatures = () => {
  const classes = useStyles();
  return <>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> White-Labeled Embeds (Coming Soon)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Split-Testing (Coming Soon)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Recurring / Drip Campaigns (Coming Soon)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Asset Cloud Storage (Coming Soon)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Unlimited Team Members (+$10/user)
    </Typography>
  </>
}
const PlusFeatures = () => {
  const classes = useStyles();
  return <>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> API Access (Coming Soon)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Session Screen Recording (Coming Soon)
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Dedicated Support
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Unlimited Team Members (+$10/user)
    </Typography>
  </>
}
const PremiumFeatures = () => {
  const classes = useStyles();
  return <>
  </>
}
const EnterpriseFeatures = () => {
  const classes = useStyles();
  return <>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> HIPAA Compliant
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> First-Party Data
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Surveys
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      <CheckCircle className={classes.check}/> Product Quizzes
    </Typography>
  </>
}

export default PlanPicker;

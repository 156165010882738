import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Tune, Create, GroupAdd } from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import DialogTabs from './DialogTabs';
import usePermission from "../../lib/hooks/usePermission";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:160,
    height:50,
    marginTop:0,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.contrastText,
  },
}));


const AddContactDialog = ({mode, refetch, size, invisible, title}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [forceOpen, setForceOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('contacts.edit')
  
  useEffect(() => {
    const callback = (event) => {
      // event.metaKey - pressed Command key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.metaKey || event.ctrlKey || event.altKey) && event.code === 'KeyN') {
        event.preventDefault();
        setForceOpen(true)
      }
      if (event.code === 'Escape') {
        setForceOpen(false)
      }
      
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [forceOpen]);
  
  
  useEffect(()=>{
    if(mode !== 'import'){
      setOpen(forceOpen)
    }
  },[forceOpen, mode])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen]);
  
  return (
    <>
      {!invisible && <Button className={size == 'small' ? classes.addPatientListSmall : classes.addPatientList} variant={'contained'}
               onClick={hasPermission ? handleToggleOpen : () => enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})} disabled={open}>
        {size == 'small' && <Add/>} {title ?? 'Add / Import Contacts'}
      </Button>}
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-patient-list-dialog-title" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><GroupAdd/> Add or Import New Contacts</DialogTitle>
        <DialogTabs handleToggleOpen={handleToggleOpen} prestTab={mode === 'import' ? 1 : 0} refetch={refetch}/>
      </Dialog>
    </>
  );
}
export default AddContactDialog;

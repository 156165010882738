import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Dialog} from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ExitToApp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
  },
  formControl: {
    padding:50,
    '& p':{
      marginBottom: 20,
    },
    textAlign:'center'
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
}));

const LoggedOutDialog = ({ logout }) => {
  const classes = useStyles();
  return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={true}
        aria-labelledby="add-patient-list-dialog-title" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><ExitToApp/> You&apos;ve been logged out!</DialogTitle>
        <DialogContent className={classes.formControl}>
          <DialogContentText>
            Please log back in to continue.
          </DialogContentText>
                <Button variant={'contained'} color={'primary'} onClick={logout}>
                  Log In
                </Button>
          
        </DialogContent>
      </Dialog>
  );
}
export default LoggedOutDialog;

import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  childrenBox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentBox: {
    display: 'flex',
    minHeight: ({ minHeight }) => minHeight,
  },
}));
const CenteredContent = ({ children, minHeight = '75vh' }) => {
  const styles = useStyles({ minHeight });
  return (
    <Box className={styles.contentBox}>
      <Box className={styles.childrenBox}>{children}</Box>
    </Box>
  );
};

export default CenteredContent;

import React, {useCallback, useEffect, useState} from 'react';
import {
  Grid,
  List,
  ListItem,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  FormControl,
  Avatar, ListItemText, ListItemSecondaryAction, Switch, Divider, Button
} from "@material-ui/core";
import useOrganization from "../../../lib/hooks/useOrganization";
import {ColorPicker} from "material-ui-color";
import UploadPhotoDialog from "../../../components/UploadPhotoDialog/UploadPhotoDialog";
import {updateOrganization} from "../../../lib/api";
import usePermission from "../../../lib/hooks/usePermission";
import {useSnackbar} from "notistack";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    margin:'auto auto -10px',
    paddingBottom:20
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    '& ul':{
      width:'100%',
    },
    '& li':{
      textAlign: 'center',
      display: 'block'
    },
  },
  patientLists:{
    marginTop:20,
  },
  title:{
    marginTop:20,
    textAlign: 'center',
    display: 'block'
  }
}));
const Design = ({hideSave}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('organization_settings.edit')
  const {isLoading: isLoadingOrganization, isRefetching: isRefetchingOrganization, data: organization, refetchOrganization} = useOrganization();
  
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [primaryColor, setPrimaryColor] = useState();
  const [secondaryColor, setSecondaryColor] = useState();
  const [poweredBy, setPoweredBy] = useState(true);
  const [icon, setIcon] = useState('');
  const [logoLight, setLogoLight] = useState('');
  const [logoDark, setLogoDark] = useState('');
  const [editPic, setEditPic] = useState('');
  
  const handleRefetch = useCallback(() => {
    setIcon(`https://sendboth.s3.amazonaws.com/organizations/${organization.id}_icon.png?r=${Math.random()}`)
    setLogoLight(`https://sendboth.s3.amazonaws.com/organizations/${organization.id}_logo_light.png?r=${Math.random()}`)
    setLogoDark(`https://sendboth.s3.amazonaws.com/organizations/${organization.id}_logo_dark.png?r=${Math.random()}`)
  })
  
  useEffect(()=>{
    if(organization){
      setPrimaryColor(organization.primary_color)
      setSecondaryColor(organization.secondary_color)
      setPoweredBy(organization.show_powered_by)
      handleRefetch()
    }
  },[])
  
  useEffect(()=>{
      if(hideSave && primaryColor && secondaryColor){
       handleSave()
    }
  },[hideSave, primaryColor, secondaryColor])
  
  const handleSave = useCallback(()=>{
    if(hasPermission){
      if(primaryColor && primaryColor.hex){
        updateOrganization({
          primary_color: "#"+primaryColor.hex,
        })
        enqueueSnackbar('Saved!', {variant:'success'})
      }
      if(secondaryColor && secondaryColor.hex){
        updateOrganization({
          secondary_color: "#"+secondaryColor.hex,
        })
        enqueueSnackbar('Saved!', {variant:'success'})
      }
    }else{
      enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
    }
  },[hasPermission, primaryColor, secondaryColor, poweredBy])
  
  const handlePoweredBy = useCallback(()=>{
    setPoweredBy(!poweredBy)
    updateOrganization({
      show_powered_by: !poweredBy,
    })
  },[poweredBy])
  
  const handlePrimary = useCallback(value=>{
    //console.log(value)
    setPrimaryColor(value)
  },[hideSave])
  
  const handleSecondary = useCallback(value=>{
    setSecondaryColor(value)
  },[hideSave])
  return (
     <>
       <Typography className={classes.title}>Quickly adjust the default designs for your templates, forms and landing pages.</Typography>
       {Boolean(editPic) && <UploadPhotoDialog forceOpen={Boolean(editPic)} setForceOpen={setEditPic} invisible={true} uploadType={editPic}
                           refetch={handleRefetch}/>}
       <Grid container spacing={2} style={{marginTop:20}}>
         <Grid item xs={6} style={{textAlign:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="primaryColor">Primary Color</Typography>
             <ColorPicker id={'primaryColor'} value={primaryColor} onChange={handlePrimary} />
           </FormControl>
         </Grid>
         <Grid item xs={6} style={{textAlign:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="secondaryColor">Secondary Color</Typography>
             <ColorPicker id={'secondaryColor'} value={secondaryColor} onChange={handleSecondary} />
           </FormControl>
         </Grid>
         <Grid item xs={4} style={{textAlign:'center', alignItems:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="icon">Icon</Typography>
             <Avatar id="icon" style={{margin:'20px auto auto', width:90, height:icon ? 'auto' : 90}} src={icon}
                                onClick={() => setEditPic('organization_icon')}/>
           </FormControl>
         </Grid>
         <Grid item container xs={4} style={{textAlign:'center', alignItems:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="logoLight">Light Logo</Typography>
             <Avatar id='logoLight' variant={'square'} style={{margin:'20px auto auto', width:160, height:logoLight ? 'auto' : 80}} src={logoLight}
                     onClick={() => setEditPic('organization_logo_light')}/>
           </FormControl>
         </Grid>
         <Grid item container xs={4} style={{textAlign:'center', alignItems:'center'}}>
           <FormControl variant="outlined">
             <Typography htmlFor="logoDark">Dark Logo</Typography>
             <Avatar id='logoDark' variant={'square'} style={{margin:'20px auto auto', width:160, height:logoDark ? 'auto' : 80}} src={logoDark}
                     onClick={() => setEditPic('organization_logo_dark')}/>
           </FormControl>
         </Grid>
         {!hideSave && <Grid item xs={12}>
           <List style={{width: '90%', margin: '20px auto auto'}}>
             <ListItem>
               <ListItemText>Show &quot;Powered By SendBoth&quot;</ListItemText>
               <ListItemSecondaryAction>
                 <Switch checked={poweredBy} onChange={handlePoweredBy} disabled={true}/>
                 <Button variant={'contained'} color={'primary'} style={{marginLeft: 10}}>Upgrade</Button>
               </ListItemSecondaryAction>
             </ListItem>
           </List>
         </Grid>}
         {!hideSave && <Grid item xs={12}>
           <List>
             <Divider style={{marginTop: 20, marginBottom: 20}}/>
             <ListItem>
               <ListItemText primary={''} secondary={''}/>
               <ListItemSecondaryAction>
                 <Button variant={'contained'} color={'primary'} onClick={handleSave}>Save</Button>
               </ListItemSecondaryAction>
             </ListItem>
           </List>
         </Grid>}

       </Grid>
     </>
  );
}

export default Design;

import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useNavigate } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Typography, Menu, MenuItem, IconButton, Button } from '@material-ui/core/';
import { MoreVert } from '@material-ui/icons/';
import { prettyDateTime, prettyPhone, truncate } from '../../../lib/helpers/helpers';
import { useSnackbar } from 'notistack';
import usePermission from '../../../lib/hooks/usePermission';
import { deleteMailboxMessage } from '../../../lib/api';
import { Sms } from '@material-ui/icons';
import { Badge } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  row: {
    cursor: 'pointer',
  },
  itemTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  itemText: {
    color: theme.palette.primary.text,
  },
  itemTextCreatedAt: {
    color: theme.palette.primary.text,
    float: 'right',
    fontSize: 14,
    marginTop: 5,
  },
}));

export default function MessageItemRow({ value, refetch, onClick, badgeCount }) {
  const { name, created_at, identifier } = value || '';
  const classes = useStyles();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const hasPermissionDelete = usePermission('inbox.delete');

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConvo = () => {
    setAnchorEl(null);
    navigate('../inbox/' + value.id, { replace: true });
  };
  const handleContact = () => {
    setAnchorEl(null);
    navigate('../contact/' + value.contact_id, { replace: true });
  };

  const handleDeleteInbox = useCallback(
    event => {
      if (hasPermissionDelete && value && value.name) {
        const confirm = window.confirm('Confirm Deletion: ' + value.name);
        if (confirm) {
          deleteMailboxMessage(value.id).then(() => {
            setAnchorEl(null);
            refetch();
          });
        }
      } else {
        enqueueSnackbar('Please ask Admin for permission.', { variant: 'error' });
      }
    },
    [value, refetch, hasPermissionDelete],
  );

  return (
    <ListItem className={classes.row}>
      <ListItemAvatar onClick={() => navigate('../contact/' + value.id, { replace: true })}>
        <Badge
          badgeContent={badgeCount}
          color="secondary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Avatar>
            <Sms />
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        style={{ maxWidth: '80%' }}
        onClick={onClick}
        className={classes.itemTitle}
        primary={truncate(value.message, 200)}
        secondary={
          value && (
            <>
              {value.from_phone_number || value.from_email ? (
                <>From {value.from_phone_number ? prettyPhone(value.from_phone_number) : value.from_email}</>
              ) : (
                ''
              )}
              {value.media && value.media.length > 0 ? <> with {value.media.length} media</> : ''}
              <br />
              {value.campaign_id ? (
                <>
                  Responded via Campaign: <NavLink to={'campaigns/' + value.campaign_id}>{value.campaign_name}</NavLink>
                </>
              ) : (
                ''
              )}
              {value.thread_type === 'chat' && value.page ? (
                <>
                  Responded via Live Chat on page: <NavLink to={value.page}>{value.page}</NavLink>
                </>
              ) : value.thread_type === 'chat' && !value.page ? (
                <>Responded via Live Chat</>
              ) : (
                <>Responded via {value.thread_type}</>
              )}
            </>
          )
        }
      />
      <ListItemSecondaryAction className={classes.itemText}>
        <Button
          variant={'outlined'}
          style={{ marginRight: 5 }}
          className={classes.itemText}
          // onClick={()=>navigate("../inbox/"+value.id, { replace: true })}
          onClick={onClick}
        >
          Open
        </Button>

        <IconButton
          edge="end"
          aria-label="more"
          aria-controls="list-management"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <MoreVert />
        </IconButton>
        <br />
        <Typography color="textSecondary" className={classes.itemTextCreatedAt}>
          {prettyDateTime(value.created_at)}
        </Typography>
      </ListItemSecondaryAction>
      <Menu id="list-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleConvo}>View Conversation</MenuItem>
        <MenuItem onClick={handleContact}>View Contact</MenuItem>
        <MenuItem onClick={handleDeleteInbox}>Archive Conversation</MenuItem>
      </Menu>
    </ListItem>
  );
}

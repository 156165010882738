import { useQuery, useContext } from 'react-query';
import { getCheckoutToken } from '../api/getCheckoutToken';
const useCheckoutToken = (chosenPlan) => {
  const result = useQuery('checkout_token_'+chosenPlan, () => getCheckoutToken(chosenPlan), {
    refetchOnWindowFocus: false,
    staleTime: 1000,
  });
  return result;
};

export default useCheckoutToken;

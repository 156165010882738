import React, { useCallback, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Tune } from '@material-ui/icons';
import {FirstList, ImportContacts, ChoosePlan, JoinOrganization, PhoneNumberPicker} from './';

import useCheckoutToken from '../../lib/hooks/useCheckoutToken';
import {BillingContext, UserContext} from "../../lib/contexts";
import {AboutYou, AboutOrganization, Design} from "../../screens/SettingsScreen/components";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  onboardingForm: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& .MuiDialog-paperWidthSm':{
      minWidth: '50vw',
      width: '80%',
      maxWidth: '80%',
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  listBorder:{
    borderWidth:0,
    borderBottomWidth:2,
    borderStyle:'solid'
  }
}));


const getSteps = () => {
  return [
    'About You',
    'Your Organization',
    'Design',
    //'Embed Code',
    'First List',
    'Import Contacts',
    //'First Campaign',
    //'Choose Plan'
  ];
}

const OnboardingDialog = ({patient, handleClose, refetch, setShowOnboardRequiredDialog}) => {
  const {
    state: { organization_id },
  } = useContext(UserContext);
  
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [makeOrganization, setMakeOrganization] = useState(false);
  
  const steps = getSteps();
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  const handleBack = () => {
    if(activeStep === 1){
      //setMakeOrganization(false)
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleReset = () => {
    setActiveStep(0);
  };
  
  const handleOrganizationCreationNext = () => {
    setMakeOrganization(true)
  };
  const handleForceClose = () => {
    setShowOnboardRequiredDialog(false)
  };
  
  return (
    <>
      <Dialog
         maxWidth={activeStep === steps.length? 'lg' : 'md'}
         fullWidth={true}
         open={true}
         onClose={handleClose}
         aria-labelledby="onboarding-dialog"
         className={classes.onboardingForm}
      >
        <DialogTitle id="onboarding-dialog-title"><Tune style={{marginBottom:-5}}/> Let&apos;s get you onboarded!</DialogTitle>
        <DialogContent>
          {
              <>
                <div className={classes.root}>
                  <DialogContentText style={{textAlign:'center', display:'none'}}>
                    Just a few questions to get you ready.
                  </DialogContentText>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {activeStep !== steps.length && steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {activeStep === steps.length ? (
                      <div>
                        {/*<ChoosePlan/>*/}
                      </div>
                    ) : (
                      <>
                        {activeStep === 0 && <AboutYou hideSave />}
                        {activeStep === 1 && <>{organization_id || makeOrganization ? <AboutOrganization hideSave onBoardMode /> : <JoinOrganization handleOrganizationCreationNext={handleOrganizationCreationNext} makeOrganization={makeOrganization} setShowOnboardRequiredDialog={setShowOnboardRequiredDialog} />}</>}
                        {activeStep === 2 && <Design hideSave/>}
                        {/*activeStep === 2 && <APIKey/>*/}
                        {activeStep === 3 && <FirstList handleNext={handleNext}/>}
                        {activeStep === 4 && <ImportContacts/>}
                        {/*activeStep === 4 &&  <FirstCampaign/>*/}
                      </>
                    )}
                  </div>
                </div>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          <Button
             disabled={activeStep === 1 && !organization_id}
             variant="contained" color="primary" onClick={activeStep === (steps.length) - 1 ? handleForceClose : handleNext}>
            {activeStep === (steps.length) - 1 ? 'Finish' : 'Next'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default OnboardingDialog;

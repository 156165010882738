import React, {useContext, useState} from 'react';

import {Grid, Paper, Typography} from "@material-ui/core";
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from '../../lib/contexts';
import { WidgetTabs, Sidebar } from './components/index.js';
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
}));

const WidgetsScreen = () => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    state: { first_name },
  } = useContext(UserContext)
  
  return (
    <Screen title={'Growth Widgets'} showTopNav={true} showFooter={true}>
      <Grid
         container
         alignItems="center"
         spacing={0}
         className={classes.screen}
      >
        <Typography variant={'subtitle1'}>
          Growth Widgets
        </Typography>
        <Grid
           container
           item xs={12}
           direction="row"
           justifyContent="space-evenly"
           alignItems="flex-start"
           spacing={0}
           className={classes.container}
        >
          <Grid item xs={4}>
            <Sidebar selectedIndex={selectedIndex} />
          </Grid>
          <Grid item xs={8}>
            <Typography component={'h2'} style={{textAlign:'center'}}>
              Take advantage of our embeddable widgets to grow your community!
            </Typography>
            <Paper style={{padding:20}}>
              {
                (() => {
                  switch (selectedIndex) {
                    case 0:
                      return <WidgetTabs/>
                    case 1:
                      return <WidgetTabs/>
                    case 2:
                      return <WidgetTabs/>
                    case 3:
                      return <WidgetTabs/>
                    case 4:
                      return <WidgetTabs/>
                    case 5:
                      return <WidgetTabs/>
                    case 6:
                      return <WidgetTabs/>
                    case 7:
                      return <WidgetTabs/>
                    case 8:
                      return <WidgetTabs/>
                    case 9:
                      return <WidgetTabs/>
                    default:
                      return <WidgetTabs/>
                  }
              
                })()
              }
            </Paper>
          </Grid>
    
        </Grid>
      </Grid>
    </Screen>
  );
};
export default WidgetsScreen;

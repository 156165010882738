import { useQuery } from 'react-query';
import { getTemplates } from '../api/getTemplates';

const useTemplates = (templateId) => {
  const result = useQuery('templates_'+(templateId ?? 'all'), () => getTemplates(templateId), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useTemplates;

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const postCampaign = async (options, testMode) => {
  const apiURL = API_HOST + (testMode ? '/campaigns/send_test' : '/campaigns');
  const { token, userID } = useCurrentUser();
  
  try {
    const { data } = await axios.post(apiURL,{
      campaign:{
          ...options
        }
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data.data;
  } catch (error) {
    console.error(error);
  }
};

import React, { useContext } from 'react';
import LogoUrl from '../../assets/logos/logo_white.png';
import LogoUrlBlack from '../../assets/logos/logo_black.png';
import LogoUrlBlackPeach from '../../assets/logos/logo_black_peach.png';
import LogoUrlOrange from '../../assets/logos/logo_white_peach.png';
import { OrganizationContext } from '../../lib/contexts';

const Logo = ({ link_herf, variant, height, color }) => {
  const {
    state: { logo_medium_url },
  } = useContext(OrganizationContext);
  const url_base = window.location.protocol + '//' + window.location.host;
  const url = link_herf ? url_base + link_herf : url_base + '/dashboard';
  const darkMode =
    localStorage.getItem('SB_THEME') === 'true' ||
    (!localStorage.getItem('SB_THEME') &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches);
  height = height === null ? 38 : height;
  let imgSrc = '';
  let additionalStyle = {};
  switch (variant) {
    default:
    case 'login_logo':
      imgSrc = darkMode ? LogoUrlOrange : LogoUrlBlackPeach;
      additionalStyle = { margin: '70px auto 10px', display: 'block', width: 500 };
      break;
    case 'logo':
      imgSrc = color === 'black' ? LogoUrlBlack : darkMode ? LogoUrlOrange : LogoUrl;
      additionalStyle = { margin: 'auto', display: 'block', width: 280 };
      break;
    case 'text':
      additionalStyle = { margin: '10px auto', display: 'block' };
      break;
  }
  return (
    <a href={url} style={{ display: 'block', color: 'inherit', fontSize: variant === 'text' ? 38 : 1 }}>
      {variant === 'text' ? (
        'SendBoth'
      ) : (
        <img
          style={{ height: height, ...additionalStyle }}
          src={variant == 'admin' ? imgSrc : logo_medium_url ?? imgSrc}
          alt="logo"
        />
      )}
    </a>
  );
};
export default Logo;

import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getPhoneNumberSearch = async (phrase, filterType, numberType) => {
  if(!phrase){
    return false
  }

  const apiURL = API_HOST + '/search/availability/?q='+phrase+'&filter='+filterType+'&number_type='+numberType;
  const { token, userID } = useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

import React, { useCallback, useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  TextField, Typography,
  withStyles,
  useTheme
} from '@material-ui/core';
import { Add, Tune, Create, ShoppingCart } from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { BillingContext } from '../../lib/contexts/BillingContext';
import useCheckoutToken from '../../lib/hooks/useCheckoutToken';
import { ProgressBar } from '../ProgressBar';

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const StripePaymentElement = ({refetch, size, invisible}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(invisible);
  const {
    state: { chosenPlan }
  } = useContext(BillingContext);
  
  const { isLoading, data } = useCheckoutToken(chosenPlan);
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
  },[open]);
  
  const options = {
    clientSecret: data ? data.client_secret : '',
    appearance: {
      theme: 'flat',
      labels: 'above',
      variables: {
        colorPrimary: theme.palette.primary.main,
        colorText: theme.palette.primary.main,
        colorDanger: theme.palette.error.main,
        spacingUnit: '5px',
        borderRadius: '2px'
        // See all possible variables below
      }
    },
  };
  
  return (
    <>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={true}
        onClose={handleToggleOpen}
        aria-labelledby="add-patient-list-dialog-title" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title"><ShoppingCart/> Finish Check Out</DialogTitle>
        {
          isLoading || data === undefined ?
            <ProgressBar style={{margin:'50px auto 50px'}}/>
            :
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements>
        }
      </Dialog>
    </>
  );
}

const CheckoutForm = ({checkoutUrl}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const {
    state: { chosenPlan, checkingOut },
    actions: { setChosenPlan, setCheckingOut },
  } = useContext(BillingContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [saving, setSaving] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }
    setSaving(true);
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://sendboth.com/order/complete',
      },
    });
    
    
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setSaving(false);
  };
  
  const handleRelocation = useCallback(()=>{
    console.log('checkingOut')
    console.log(checkingOut)
    //window.location = data.checkout_url
  },[checkingOut])
  
  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <DialogContentText>
          You may change your plan at any time from the Account page.
        </DialogContentText>
        {errorMessage && <div>{errorMessage}</div>}
        <PaymentElement />
        { saving && <ProgressBar style={{margin:'50px auto 50px'}}/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setCheckingOut(undefined)}
                className={classes.cancelButton}
                disabled={saving}>
          Back
        </Button>
        <Button
          onClick={handleRelocation}
          className={classes.addButton}
          disabled={saving || !stripe}>
          Submit
        </Button>
      </DialogActions>
    </form>
  )
}

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  
  useEffect(() => {
    if (!stripe) {
      return;
    }
    
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );
    
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({paymentIntent}) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Success! Payment received.');
            break;
          
          case 'processing':
            setMessage("Payment processing. We'll update you when payment is received.");
            break;
          
          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Payment failed. Please try another payment method.');
            break;
          
          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe]);
  
  
  return message;
};

export default StripePaymentElement;

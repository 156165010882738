import React, {useState, useCallback, useContext, useMemo, useEffect} from 'react';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {Button, List, Menu, MenuItem } from '@material-ui/core/';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MoreVert, Delete, People, Settings, SaveAlt } from '@material-ui/icons/';
import { ListItemRow } from './index';
import { AddContactsToListsDialog, AddCampaignDialog } from '../../../components';
import { ContactTable } from '../../ContactsScreen/components/';
import { CampaignMetricCards, OpensByDay, PerformanceByHour, CampaignTableBreakdownByContact } from '../../ReportsScreen/components/';
import useCampaigns from '../../../lib/hooks/useCampaigns.js';
import { ContactsContext, ContactsContextProvider } from '../../../lib/contexts/ContactsContext';
import ProgressBar from '../../../components/ProgressBar/ProgressBar.js';
import {Edit, Pause, PlayArrow} from "@material-ui/icons";
import {Fab} from "@material-ui/core";
import {API_HOST} from "../../../lib/constants/constants";
import useCurrentUser from "../../../lib/hooks/useCurrentUser";
import axios from "axios";
import {updateCampaign, updateTemplate} from "../../../lib/api";
import {useSnackbar} from "notistack";
import usePermission from "../../../lib/hooks/usePermission";
import useOrganization from "../../../lib/hooks/useOrganization";

const useStyles = makeStyles((theme) => ({
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
    padding:10
  },
  content:{
    margin:'20px auto auto',
    width:'100%',
    backgroundColor: theme.palette.backgrounds.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  noneFound:{
    padding:40,
    margin: 'auto',
    textAlign:'center',
    '& *':{
      display:'block',
      margin: 'auto',
      textAlign:'center',
    }
  }
}));

const SpecificCampaign = () => {
  const {
    state: { selectedContacts },
    actions: { setSelectedContacts },
  } = useContext(ContactsContext);
  const classes = useStyles();
  const {campaignId} = useParams();
  const { isLoading:isLoadingOrganization, data:organization, refetch: refetchOrganization } = useOrganization();
  const { email_credits, sms_credits } = organization;
  const { isLoading, data, refetch } = useCampaigns(campaignId);
  const campaignData = useMemo(()=>data && !data.error ? data : [],[data]);
  const { contacts } = campaignData && campaignData[0] ? campaignData[0] : [];
  const [internalPaused, setInternalPaused] = useState(false);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const hasPermissionEdit = usePermission('campaigns.edit')
  
  useEffect(()=>{
     //initial values
     if(campaignData.length){
        setInternalPaused(campaignData[0].paused)
     }
  },[campaignData])
   
   const handleUpdateCampaign = useCallback(async (editedContent) => {
  
   },[campaignId]);
   
   const handlePauseCampaign = useCallback(async () => {
      if(hasPermissionEdit){
      if(email_credits == 0 && sms_credits == 0){
         enqueueSnackbar('Please refill Credits to Unpause a Campaign.', {variant:'error'})
         return false
      }
      if(contacts.length > email_credits || contacts.length > sms_credits || 100 > email_credits || 100 > sms_credits){
         enqueueSnackbar('Please refill Credits to prevent Campaigns being Paused.', {variant:'warning'})
      }
         const editedContent = {
            paused: !internalPaused
         }
         updateCampaign(campaignId, editedContent).then(()=>{
            setInternalPaused(!internalPaused)
         })
      }else{
         enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})
      }
   },[campaignId, internalPaused, hasPermissionEdit]);
  
  return (<>
     
       <Grid item xs={2}>
         <Typography variant={'subtitle1'}>
           {campaignId ? 'Viewing Campaign' : 'Campaign Management'}
         </Typography>
       </Grid>
       <Grid item xs={8}></Grid>
       <Grid item xs={1}>
          <Fab color={'default'} style={{float:'right', marginTop:60}} onClick={handlePauseCampaign}>{internalPaused ? <PlayArrow/> : <Pause/>}</Fab>
       </Grid>
       <Grid item xs={1}>
            <Fab color={'default'} style={{float:'right', marginTop:60}} disabled={true}><Edit/></Fab>
       </Grid>
       <CampaignMetricCards/>
     <div className={classes.content}>
     <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
        className={classes.container}
     >
            {isLoading ?
               <ProgressBar /> :
               <>
                 
                 {campaignData ? <>
                     <Grid
                        container
                        item
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        xs={12}
                     >
                       <OpensByDay/>
                       <PerformanceByHour/>
                     <CampaignTableBreakdownByContact campaignData={campaignData}/>
                   </Grid>
                 </>
                    :
                    <>
                      <Typography variant="h6" className={classes.noneFound}>
                        Campaign not found.
                        <AddCampaignDialog size={'text'} title={'Add Your First Campaign'}/>
                      </Typography>
                    </>
                 }
                 </>
            }
            </Grid>
     </div>
     </>
  );
}

const SpecificCampaignWrapper = () => {
  return (
     <ContactsContextProvider>
       <SpecificCampaign/>
     </ContactsContextProvider>
  )
}

export default SpecificCampaignWrapper;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  makeStyles,
  CircularProgress,
  DialogActions,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChatBubble, Phone, Send, Sms } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import Avatar from 'boring-avatars';
import { prettyDateTime } from '../../lib/helpers/helpers';
import { postEmbeddableChat } from '../../lib/api';
const useStyles = makeStyles(theme => ({
  '@keyframes Lmuha': {
    '100%': {
      transform: 'scale(1)',
    },
  },
  chatBubble: {
    animation: 'Lmuha 0.3s ease forwards',
    background: '#1b9feb', //'#1e8555',
    borderRadius: '18px 18px 18px 0',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
    color: '#fff', //'#000',
    display: 'inline-block',
    fontSize: 14,
    maxWidth: '50%',
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    padding: 12,
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
  },
  chatBubbleUser: {
    animation: 'Lmuha 0.3s ease forwards',
    background: '#E5E5EA', //'#1e8555',
    borderRadius: '18px 18px 0px 18px',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
    color: '#000', //'#000',
    display: 'inline-block',
    fontSize: 14,
    maxWidth: '50%',
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    padding: 12,
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
  },
  timestampLeft: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    display: 'block',
    margin: '5px 0 10px',
    float: 'none',
    textAlign: 'right',
  },
  timestampRight: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    display: 'block',
    margin: '5px 0 10px',
    float: 'none',
  },
  avatar: {
    animation: 'Lmuha 0.3s ease forwards',
    borderRadius: '50% 50% 0 50%',
    boxShadow: 'rgba(0,0,0,0) 0px 1px 2px 0px',
    height: 40,
    minWidth: 40,
    padding: 3,
    transform: 'scale(0)',
    transformOrigin: 'bottom right',
    width: 40,
  },
  container: {
    display: 'inline-block',
    order: 0,
    padding: 6,
  },
  fromOrganization: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  fromUser: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  convoContainer: {
    overflowY: 'auto',
    maxHeight: '420px',
  },
}));
const MessageBubble = ({ row, orgIcon, colors, organization }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={!organization ? classes.fromUser : classes.fromOrganization}>
        {!organization && <div className={classes.chatBubbleUser}>{row.message}</div>}
        <div className={classes.container}>
          <Avatar
            className="avatar"
            size={45}
            src={orgIcon}
            name={
              organization
                ? row.organization_id
                : row?.user_id || row?.contact_id || row?.visitor_id || row?.from_phone_number || row?.from_email || ''
            }
            variant={orgIcon ? 'image' : 'beam'}
            colors={colors}
          />
        </div>
        {organization && <div className={classes.chatBubble}>{row.message}</div>}
      </div>
      <span className={organization ? classes.timestampRight : classes.timestampLeft}>
        {prettyDateTime(row.created_at)}{' '}
      </span>
    </div>
  );
};

export default function Convo({
  colors,
  initialMessage,
  organizationID,
  visitorID,
  contactID,
  convo,
  refetch,
  setInitialMessage,
}) {
  const chatRef = useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [newMessage, setNewMessage] = useState(initialMessage);
  const [campaignID, setCampaignID] = useState(null);
  const [orgIcon, setOrgIcon] = useState('');
  const [posting, setPosting] = useState(false);

  useEffect(() => {
    // auto scroll down on new messages
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [convo]);

  useEffect(() => {
    // send the first message for them
    if (initialMessage && initialMessage === newMessage) {
      handleSendMessage();
      setInitialMessage('');
    }
  }, [initialMessage, newMessage, setInitialMessage]);

  useEffect(() => {
    if (organizationID) {
      const image = new Image();
      image.src = `https://sendboth.s3.amazonaws.com/organizations/${organizationID}_icon.png?r=${Math.random()}`;
      image.onload = () => {
        setOrgIcon(image.src);
      };
      image.onerror = () => {
        setOrgIcon('');
      };
    }
  }, [organizationID]);

  useEffect(() => {
    const callback = event => {
      if (newMessage) {
        if (event.code === 'Enter') {
          event.preventDefault();
          handleSendMessage();
        }
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [newMessage]);

  const handleSendMessage = useCallback(() => {
    if (newMessage && organizationID) {
      setPosting(true);
      const object = {
        campaignId: campaignID, // todo should check for url param or localstorage
        visitorId: visitorID,
        organizationId: organizationID,
        contactId: contactID,
        message: newMessage,
        page: window.location.href,
        //media: attachmentUrl, //- todo when we have asset management working
      };
      postEmbeddableChat(object).then(response => {
        if (response) {
          setNewMessage('');
          setPosting(false);
          refetch();
        } else {
          setPosting(false);
          enqueueSnackbar('Something went wrong, try again.', { variant: 'error' });
        }
      });
    }
  }, [newMessage, campaignID, organizationID, contactID]);
  return (
    <Box style={{ padding: 20 }}>
      {!convo && (
        <Typography color={'textSecondary'} style={{ textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
          Loading Conversation...
        </Typography>
      )}
      <div className={classes.convoContainer} ref={chatRef}>
        <>
          {convo &&
            Boolean(convo.length) &&
            convo.map(row => {
              return (
                <React.Fragment key={row.id}>
                  {row.ai_responded || row.responded_by ? (
                    <MessageBubble organization orgIcon={orgIcon} row={row} colors={colors} />
                  ) : (
                    <MessageBubble row={row} colors={colors} />
                  )}
                </React.Fragment>
              );
            })}
        </>
      </div>

      <DialogActions>
        <TextField
          autoFocus={true}
          value={newMessage}
          onChange={e => {
            setNewMessage(e.target.value);
          }}
          variant={'outlined'}
          fullWidth={true}
          multiline
          label={
            <>
              <ChatBubble style={{ marginBottom: -6 }} /> New Message
            </>
          }
          placeholder={'I need assistance with...'}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSendMessage} disabled={!newMessage || posting}>
                {posting ? <CircularProgress size={'2rem'} /> : <Send />}
              </IconButton>
            ),
          }}
        />
      </DialogActions>
    </Box>
  );
}

import React, {useMemo} from 'react';

import {Grid, ListItem, ListItemIcon, ListItemText, Paper, Typography} from "@material-ui/core";
import {ProgressBar, Screen} from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { Sidebar } from './components/index.js';
import useOrganizationFaqs from "../../lib/hooks/useOrganizationFaqs";
import {useParams} from "react-router";
import List from "@material-ui/core/List";
import {toUnderScoreSlug} from "../../lib/helpers/helpers";
import {Help} from "@material-ui/icons";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
  containerTop:{
    maxHeight: '78vh',
    overflowY:"auto",
  },
}));

const FAQScreen = () => {
  const classes = useStyles();
  const { tag, faqID, question } = useParams();
  const { isLoading: isLoadingFaq, data: faqs } = useOrganizationFaqs('6bf28d38-f266-4e22-afcb-8b77f5714b86');
  
  const filteredData = useMemo(() => {
    const filteredMostPopularHashtags = faqs?.filter(({ hashtags = [] }) => hashtags.some(hashtag => toUnderScoreSlug(hashtag).includes(toUnderScoreSlug(tag))))
       .map(item => ({ ...item, hashtags: item.hashtags.map(hashtag => toUnderScoreSlug(hashtag)) }))
       .filter(item => item.hashtags.some(slug => slug === toUnderScoreSlug(tag))) ?? [];
    const filteredFaqs = question ? faqs?.filter(item => toUnderScoreSlug(item.question).includes(toUnderScoreSlug(question))) ?? [] : [];
    return filteredMostPopularHashtags.length || filteredFaqs.length ? [...filteredMostPopularHashtags, ...filteredFaqs] : faqs ?? [];
  }, [faqs, question, tag]);
  
  return (
    <Screen title={'Growth Widgets'} showTopNav={true} showFooter={true}>
      <Grid
         container
         alignItems="center"
         spacing={0}
         className={classes.screen}
      >
        <Typography variant={'subtitle1'}>
          Help Center
        </Typography>
        <Grid
           container
           item xs={12}
           direction="row"
           justifyContent="space-evenly"
           alignItems="flex-start"
           spacing={0}
           className={classes.container}
        >
          <Grid item xs={4}>
            <Sidebar />
          </Grid>
          <Grid item xs={8}>
            <Typography component={'h2'} style={{textAlign:'center'}}>
              Browse our Help Articles and Frequently Asked Questions
            </Typography>
            <Paper style={{padding:20}}>
              {isLoadingFaq && <ProgressBar/>}
              <List component="nav" aria-label="faq sidebar list"
                    className={classes.containerTop}>
                {filteredData && filteredData.map(item=><ListItem
                   key={item.id}
                >
                  <ListItemIcon>
                    <Help className={classes.icon}/>
                  </ListItemIcon>
                  <ListItemText primary={item.question} secondary={item.answer} />
                </ListItem>)}
              </List>
              
            </Paper>
          </Grid>
    
        </Grid>
      </Grid>
    </Screen>
  );
};
export default FAQScreen;

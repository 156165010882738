import * as React from 'react';
import {  Grid } from "@material-ui/core";
import MetricCard from '../../../components/MetricCard/MetricCard';

export default function CampaignMetricCards() {
  return (
    <>
      <Grid
         container
         direction="row"
         justifyContent="flex-start"
         alignItems="center"
         spacing={0}
      >
        <MetricCard slug={'emails_sent'}/>
        <MetricCard slug={'open_rate'}/>
        <MetricCard slug={'total_opens'}/>
        <MetricCard slug={'sms_sent'}/>
      </Grid>
    </>
  );
}

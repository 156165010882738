import * as React from 'react';
import {Box, Grid, Typography, Fab, ListItem, ListItemIcon, ListItemText, Divider, Collapse} from "@material-ui/core";
import {
   AccountBalance, AccountCircle, Ballot, BrandingWatermark,
   Business,
   DateRange, DoneAll, ExpandLess, ExpandMore, FeaturedVideo, FileCopy, Folder, FolderOpen, FolderSpecial, Group,
   GroupAdd,
   Inbox,
   LiveHelp, NotificationsActive, OpenInBrowser,
   Palette, PermMedia, PersonAdd, PhotoFilter, PostAdd,
   Print, VerifiedUser,
   VpnKey
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {useCallback, useContext, useState} from "react";
import DateRangeFilterDialog from "../../../components/DateRangeFilterDialog/DateRangeFilterDialog";
import {UserContext} from "../../../lib/contexts";
import {prettyDate, minusMonth} from "../../../lib/helpers/helpers";
import List from "@material-ui/core/List";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.backgrounds.paper,
      '& nav':{
         padding: '0px !important',
         margin: '0px !important'
      }
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
   icon: {
      color: theme.palette.primary.paperText,
   },
}));

export default function Sidebar({selectedIndex}) {
   const classes = useStyles();
   let navigate = useNavigate();
   const [expandOrg, setExpandOrg] = React.useState(true);
   
   const handleClick = () => {
      setExpandOrg(!open);
   };
   const {
      state: {
         showingRange,
         startDate,
         endDate
      },
      actions: {
         setShowingRange
      }
   } = useContext(UserContext);
   
   const handleListItemClick = (event, index) => {
      (() => {
         switch (index) {
            case 0:
               navigate("../assets/recent", { replace: true })
               break;
            case 1:
               navigate("../assets/uploads", { replace: true })
               break;
            case 2:
               navigate("../assets/generated", { replace: true })
               break;
            case 3:
               navigate("../assets/in_use", { replace: true })
               break;
            case 4:
               navigate("../assets/not_in_use", { replace: true })
               break;
            case 5:
               navigate("../assets/custom_folder_1", { replace: true })
               break;
            case 6:
               navigate("../assets/custom_folder_2", { replace: true })
               break;
            default:
               return true
         }
      })()
   };
   
  return (
     <div className={classes.root}>
        <List component="nav" aria-label="asset sidebar">
           <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
           >
              <ListItemIcon>
                 <FolderSpecial className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Recently Added" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
           >
              <ListItemIcon>
                 <FileCopy className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Uploads" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
           >
              <ListItemIcon>
                 <PhotoFilter className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="AI Generated" />
           </ListItem>
           <Divider/>
           <ListItem
              button
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
           >
              <ListItemIcon>
                 <Folder className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Files In-Use" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
           >
              <ListItemIcon>
                 <FolderOpen className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Files Not In-Use" />
           </ListItem>
           <Divider/>
           <ListItem
              button
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
           >
              <ListItemIcon>
                 <PermMedia className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Custom Folder" />
           </ListItem>
           <ListItem
              button
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
           >
              <ListItemIcon>
                 <PermMedia className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Custom Folder 2" />
           </ListItem>
        </List>
     </div>
  );
}

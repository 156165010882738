import React, { useContext, useState, useCallback } from 'react';

import { Paper, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,  ListItemIcon,
  ListItemSecondaryAction, ListItemText,
} from '@material-ui/core';
import { Close, Person } from '@material-ui/icons';
import {AddContactForm} from '../AddContactForm'
import ImportContacts from '../OnboardingDialog/ImportContacts'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container:{
    minWidth:'40vw'
  },
}));

const DialogTabs = ({handleToggleOpen, prestTab, refetch}) => {
  const classes = useStyles();
  const [tab, setTab] = useState(prestTab ?? 0);
  const handleChange = useCallback((event,newValue)=>{
    setTab(newValue);
  },[])
  return (
    <Grid item className={classes.container}>
      <Paper className={classes.root}>
        <Tabs
           value={tab}
           onChange={handleChange}
           indicatorColor="primary"
           textColor="primary"
           centered
        >
          <Tab label="Manually Add" />
          <Tab label="Bulk Import" />
        </Tabs>
  
      {tab === 0 ?
      <>
      <AddContactForm handleToggleOpen={handleToggleOpen} refetch={refetch}/>
      </>
      :
      <>
      <ImportContacts refetch={refetch}/>
      </>
      }
      </Paper>
    </Grid>
  );
};

export default DialogTabs;

import React, { useMemo } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { prettyDateTime } from '../../../lib/helpers/helpers';
import Avatar from 'boring-avatars';
import useMetric from '../../../lib/hooks/useMetric';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';

const GetTooltip = props => {
  return (
    <Tooltip placement={'bottom'} title={props.val}>
      {props.val}
    </Tooltip>
  );
};

const columns = [
  {
    field: 'geo',
    headerName: 'Visitor',
    width: 115,
    renderCell: params => (
      <Tooltip placement={'bottom'} title={params.id ?? 'N/A'}>
        <span style={{ textAlign: 'center', margin: 'auto', display: 'block' }}>
          <Avatar
            size={45}
            name={'' + params.row.id}
            variant="beam"
            colors={['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']}
          />
        </span>
      </Tooltip>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 135,
    renderCell: params => (
      <Tooltip placement={'bottom'} title={prettyDateTime(params.row.createdAt)}>
        <span className="truncate">{prettyDateTime(params.row.createdAt)}</span>
      </Tooltip>
    ),
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 130,
    renderCell: params => (
      <Tooltip
        placement={'bottom'}
        title={params.row.geoIP ? params.row.geoIP.city + ', ' + params.row.geoIP.region : 'N/A'}
      >
        <>
          <span className="truncate">
            {params.row.geoIP ? params.row.geoIP.city + ', ' + params.row.geoIP.region : 'N/A'}
          </span>
        </>
      </Tooltip>
    ),
  },
  {
    field: 'referrer',
    headerName: 'Referrer',
    width: 135,
    renderCell: params => (
      <Tooltip placement={'bottom'} title={params.row.referrer}>
        <span className="truncate">{params.row.referrer}</span>
      </Tooltip>
    ),
  },
  {
    field: 'device',
    headerName: 'Device',
    width: 120,
    renderCell: params => (
      <Tooltip placement={'bottom'} title={params.row.deviceType}>
        <span className="truncate">{params.row.deviceType}</span>
      </Tooltip>
    ),
  },
  {
    field: 'userAgent',
    headerName: 'User Agent',
    width: 150,
    renderCell: params => (
      <Tooltip placement={'bottom'} title={params.row.userAgent}>
        <span className="truncate">{params.row.userAgent}</span>
      </Tooltip>
    ),
  },
  {
    field: 'IP',
    headerName: 'IP Address',
    width: 155,
    renderCell: params => (
      <Tooltip placement={'bottom'} title={params.row.IP ?? 'N/A'}>
        <>
          <span className="truncate">{params.row.IP}</span>
        </>
      </Tooltip>
    ),
  },
];

const VisitorsTable = () => {
  const { isLoading, isRefetching, data, refetch } = useMetric('visitors_breakdown', 'visitor');
  const { metric } = useMemo(() => (data !== undefined ? data : []), [data]);
  return (
    <Grid item xs={6}>
      <Typography variant={'h2'} color={'primary'} style={{ marginTop: 0 }}>
        Visitor Breakdown
      </Typography>
      {isLoading || (isRefetching && <ProgressBar style={{ margin: 25 }} />)}
      {!(isLoading || isRefetching) && data && (
        <div style={{ height: 500, width: '100%', textTransform: 'capitalize' }}>
          <DataGrid rows={metric} columns={columns} pageSize={5} checkboxSelection={false} />
        </div>
      )}
    </Grid>
  );
};
export default VisitorsTable;

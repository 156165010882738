import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getVerifiedDomains = async organizationId => {
  if (!organizationId) {
    return false;
  }

  const apiURL = API_HOST + (organizationId ? '/verified_domains/' + organizationId : '/verified_domains');
  const { token, userID } = useCurrentUser();
  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-sb-user-id': userID,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';

export default function SimpleBreadcrumbs({ crumbs }) {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{
      width:'100%',
      maxWidth:'80vw',
      margin:'100px auto auto'}}>
      {crumbs.map((item,index)=> item.path.length ? <Link color="inherit" to={item.path} key={index}>
          {item.name}
        </Link> : <Typography color="textPrimary" key={index}>{item.name}</Typography>
      )}
    </Breadcrumbs>
  );
}

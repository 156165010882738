import React, { useCallback, useContext, useMemo } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { ContactsContext } from '../../../lib/contexts/ContactsContext';
import {useNavigate} from "react-router-dom";

const columns = [
  {
    field: 'first_name',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 160,
    editable: true,
  },
  {
    field: 'phone_number',
    headerName: 'Phone',
    sortable: false,
    editable: true,
    width: 115,
  },
  {
    field: 'total_sent',
    headerName: 'Total Sent',
    sortable: false,
    editable: true,
    width: 120,
  },
  {
    field: 'open_rate',
    headerName: 'Open Rate',
    sortable: false,
    editable: true,
    width: 120,
  },
  {
    field: 'username',
    headerName: 'Username',
    sortable: false,
    editable: true,
    width: 160,
  },
  {
    field: 'gender',
    headerName: 'Gender',
    sortable: false,
    editable: true,
    width: 100,
  },
  {
    field: 'organization',
    headerName: 'Organization',
    sortable: false,
    editable: true,
    width: 160,
  },
  {
    field: 'dob',
    headerName: 'DOB',
    sortable: false,
    editable: true,
    width: 100,
  },
  {
    field: 'city',
    headerName: 'City',
    sortable: false,
    editable: true,
    width: 160,
  },
  {
    field: 'state',
    headerName: 'State',
    sortable: false,
    editable: true,
    width: 85,
  },
  {
    field: 'country',
    headerName: 'Country',
    sortable: false,
    editable: true,
    width: 85,
  },
  {
    field: 'postal_code',
    headerName: 'Postal Code',
    sortable: false,
    editable: true,
    width: 130,
  },
  {
    field: 'locale',
    headerName: 'Locale',
    sortable: false,
    editable: true,
    width: 95,
  },
  {
    field: 'source',
    headerName: 'Source',
    sortable: false,
    editable: true,
    width: 160,
  },
  {
    field: 'sms_opt_in_time',
    headerName: 'SMS Opt-in Time',
    sortable: false,
    editable: true,
    width: 160,
  },
  {
    field: 'email_opt_in_time',
    headerName: 'Email Opt-in Time',
    sortable: false,
    editable: true,
    width: 160,
  },
  {
    field: 'email_opt_in_ip',
    headerName: 'Email Opt-in IP',
    sortable: false,
    editable: true,
    width: 160,
  },
];
const contactColumns = [
  {
    field: 'campaign_id',
    headerName: 'Campaign ID',
    width: 160,
    editable: true,
  },
  {
    field: 'event_type',
    headerName: 'Event Type',
    width: 150,
    editable: true,
  },
  {
    field: 'device_type',
    headerName: 'Device Type',
    width: 160,
    editable: true,
  },
  {
    field: 'user_agent',
    headerName: 'User Agent',
    sortable: false,
    editable: true,
    width: 150,
  },
  {
    field: 'referrer',
    headerName: 'Referrer',
    sortable: false,
    editable: true,
    width: 120,
  },
  {
    field: 'IP',
    headerName: 'IP',
    sortable: false,
    editable: true,
    width: 120,
  },
  {
    field: 'region',
    headerName: 'Region',
    sortable: false,
    editable: true,
    width: 160,
  },
  {
    field: 'country',
    headerName: 'Country',
    sortable: false,
    editable: true,
    width: 120,
  },
  {
    field: 'created_at',
    headerName: 'Action At',
    sortable: false,
    editable: true,
    width: 160,
  }
];

export default function ContactTable({data, setSelectedContacts, selectedContacts}) {
  const navigate = useNavigate();
  const tableData = useMemo(()=>data && !data.error ? data : [],[data]);
  const {
    state: { viewingContact },
    actions: { setSelectedContacts:setSelectedContactsContext, setViewingContact }
  } = useContext(ContactsContext);
  
  const handleSelection = useCallback(newSelectionModel => {
    setSelectedContacts ? setSelectedContacts(newSelectionModel) : setSelectedContactsContext(newSelectionModel)
  },[]);
  
  return (
    <div style={{ height: 400, width: '100%' }}>
      {tableData && <DataGrid
         rows={tableData}
         columns={viewingContact ? contactColumns : columns}
         onSelectionModelChange={handleSelection}
         pageSize={viewingContact ? 10 : 5}
         editMode={false}
         onRowClick={viewingContact ? false : (e)=>navigate("../contact/"+e.id, { replace: true })}
         checkboxSelection={viewingContact ? false : true}
         disableSelectionOnClick
         initialState={{
           columns: {
             columnVisibilityModel: {
               id: false,
               username: false,
             },
           },
         }}
      />}
    </div>
  );
}

import useMailbox from './useMailbox';
import { useMemo } from 'react';

const useMailboxUnreadCount = organizationId => {
  const { data: mailbox } = useMailbox(organizationId);
  const unreadMessages = useMemo(() => (mailbox ? mailbox.unread : 0), [mailbox]);
  return unreadMessages;
};

export default useMailboxUnreadCount;

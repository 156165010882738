import { useQuery } from 'react-query';
import {getSubdomainAvailability} from "../api";

const useSubdomainAvailability = (subdomain) => {
  const result = useQuery('subdomain_availability_'+subdomain, () => getSubdomainAvailability(subdomain), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useSubdomainAvailability;

import { useQuery } from 'react-query';
import { getMailbox } from '../api/getMailbox';

const useMailbox = organizationId => {
  const result = useQuery('mailbox_' + organizationId, () => getMailbox(organizationId), {
    refetchOnWindowFocus: true,
    refetchInterval: 5000,
    refetchIntervalInBackground: 10000,
  });
  return result;
};

export default useMailbox;

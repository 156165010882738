import React, { useContext, useState, useMemo } from 'react';

import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
  useMediaQuery,
  TextField,
  Grid,
  Typography,
  Divider,
  Select,
  FormControl,
  InputLabel,
  Badge,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  AccountCircle,
  EmailOutlined,
  HomeOutlined,
  People,
  TableChart,
  PollOutlined,
  Widgets,
  Assignment,
  Search,
  InvertColors,
  PersonAddDisabled,
  Forum,
  PermMedia,
  Help,
  Settings,
  GetApp,
  ArrowDropDown,
  Add,
  Info,
} from '@material-ui/icons';
import { OrganizationContext, ThemeContext, UserContext } from '../../lib/contexts';
import { makeStyles } from '@material-ui/core/styles';
import { Logo } from '../Logo';
import cn from 'classnames';
import { pageAccess } from '../../lib/constants/routes';
import { Logout } from '@mui/icons-material';
import useOrganization from '../../lib/hooks/useOrganization';
import { prettyPhone } from '../../lib/helpers/helpers';

import macOS_download from '../../assets/third/macOS_download.png';
import Avatar from 'boring-avatars';
import useUser from '../../lib/hooks/useUser';
import { updateOrganization } from '../../lib/api';
import usePermission from '../../lib/hooks/usePermission';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& a': {
      color: 'inherit',
    },
    '& a:visited': {
      color: 'inherit',
    },
  },
  link: {
    color: 'inherit',
  },
  header: {
    background: theme.palette.backgrounds.header,
    borderRadius: 0,
  },
  container: {
    overflowX: 'auto',
    margin: 'auto',
    width: '100%',
    maxWidth: '94vw',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    '& *': {
      color: theme.palette.common.white,
    },
  },
  navList: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  listItem: {},
  listItemActive: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
  },
  listItemText: {
    height: 30,
    display: 'block',
    margin: 'auto',
    padding: '0px',
    fontWeight: 'bold',
    '& *': {
      verticalAlign: '-webkit-baseline-middle',
      color: theme.palette.common.white + ' !important',
    },
  },
  ListItemIcon: {
    verticalAlign: '-webkit-baseline-middle',
    marginRight: -25,
    marginTop: 0,
    height: 30,
  },
  menu: {
    minWidth: '100%',
    marginRight: 20,
  },
}));

const TopBarNavigation = () => {
  const {
    state: { code: partnerCode, name: partnerName, userRole },
  } = useContext(OrganizationContext);
  const {
    actions: { logout, setShowingSearch, setShowingHelp },
  } = useContext(UserContext);
  const { data: userData } = useUser();
  const user = useMemo(() => (userData ? userData.user : undefined), [userData]);
  const {
    isLoading: isLoadingOrganization,
    isRefetching: isRefetchingOrganization,
    data: organization,
    refetch: refetchOrganization,
  } = useOrganization();
  const { phone_numbers, chosen_phone_number, id: organizationId } = useMemo(() => organization ?? [], [organization]);

  const { darkMode, toggleTheme } = useContext(ThemeContext);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [chosenNumber, setChosenNumber] = useState(chosen_phone_number || '+18442624828');
  const [openPhoneNumbers, setOpenPhoneNumbers] = useState(false);
  const active = useMemo(() => window.document.location.pathname, [window.document.location]);
  let page_access = pageAccess(userRole);
  const { enqueueSnackbar } = useSnackbar();
  const hasPermissionEdit = usePermission('organization_members.edit');
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const matchMD = useMediaQuery(theme.breakpoints.only('md'));
  const matchLG = useMediaQuery(theme.breakpoints.only('xl'));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleChosenNumber = event => {
    if (hasPermissionEdit) {
      updateOrganization({
        chosen_phone_number: event.target.value,
      }).then(() => {
        setChosenNumber(event.target.value);
        setOpenPhoneNumbers(false);
        enqueueSnackbar('Updated!', { variant: 'success' });
        refetchOrganization();
      });
    } else {
      enqueueSnackbar('Please ask Admin for permission.', { variant: 'error' });
    }
  };
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    setAnchorEl(null);
    setShowingSearch(true);
  };

  const handleTheme = () => {
    toggleTheme();
  };

  const handleHelp = () => {
    setAnchorEl(null);
    setShowingHelp(true);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.header}>
          <Toolbar className={classes.container}>
            <Logo variant={'logo'} />
            <List className={classes.navList}>
              {page_access.map((page_obj, index) => {
                const { name, path, onclick, badge_count } = page_obj;
                return (
                  <ListItem
                    selected={active === name ? true : false}
                    button
                    key={index}
                    className={cn(
                      classes.listItem,
                      active === path || (path === '/dashboard' && active === '/') ? classes.listItemActive : '',
                    )}
                  >
                    <Link to={path}>
                      <Badge
                        badgeContent={badge_count}
                        color="secondary"
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <Tooltip placement="bottom" title={name} aria-label={name}>
                          <ListItemIcon className={classes.ListItemIcon}>
                            {(() => {
                              switch (path) {
                                default:
                                case '/dashboard':
                                  return <HomeOutlined />;
                                case '/visitors':
                                  return <PersonAddDisabled />;
                                case '/inbox':
                                  return <Forum />;
                                case '/contacts':
                                  return <People />;
                                case '/lists':
                                  return <Assignment />;
                                case '/campaigns':
                                  return <EmailOutlined />;
                                case '/reports':
                                  return <PollOutlined />;
                                case '/templates':
                                  return <TableChart />;
                                case '/widgets':
                                  return <Widgets />;
                                case '/assets':
                                  return <PermMedia />;
                              }
                            })()}
                          </ListItemIcon>
                        </Tooltip>
                        {!matchSM && <ListItemText className={classes.listItemText} primary={name} />}
                      </Badge>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
            <div>
              {!matchSM && (
                <IconButton
                  aria-label="universal search"
                  aria-controls="search"
                  aria-haspopup="true"
                  onClick={handleSearch}
                  color="inherit"
                >
                  <Search />
                </IconButton>
              )}
              {!matchSM && (
                <IconButton
                  aria-label="toggle theme"
                  aria-controls="theme"
                  aria-haspopup="true"
                  onClick={handleTheme}
                  color="inherit"
                >
                  <InvertColors />
                </IconButton>
              )}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disableRipple={true} className={classes.menu}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="chosenNumber-select-label">
                      &nbsp;&nbsp;&nbsp;Your Organization&apos;s Current Phone Number
                    </InputLabel>
                    <Select
                      labelId="chosenNumber-select-label"
                      id="chosenNumber-select"
                      fullWidth={true}
                      size={'small'}
                      variant={'outlined'}
                      value={chosenNumber}
                      onChange={handleChosenNumber}
                      renderInput={(inputProps, ref) => (
                        <TextField
                          {...inputProps}
                          inputRef={ref}
                          InputProps={{
                            endAdornment: (
                              <IconButton onClick={() => alert('here') && setOpenPhoneNumbers(true)}>
                                <ArrowDropDown />
                              </IconButton>
                            ),
                          }}
                          value={chosenNumber}
                        />
                      )}
                    >
                      {phone_numbers && phone_numbers.length === 0 && <MenuItem value={'N/A'}>N/A</MenuItem>}
                      {phone_numbers &&
                        phone_numbers.map(item => {
                          return (
                            <MenuItem key={item} value={item}>
                              {prettyPhone(item)}
                            </MenuItem>
                          );
                        })}
                      <MenuItem value={''} style={{ position: 'relative' }}>
                        <Add style={{ marginLeft: -2, marginBottom: 0 }} />
                        &nbsp;Add Phone Number&nbsp;
                        <span style={{ right: 0, position: 'absolute', marginRight: 10 }}>
                          <Tooltip
                            title={
                              'Multiple phone numbers allow you to create even further segmentation within your contacts and also helps you scale beyond the 6 SMS/second rate-limit set by Mobile Carriers.'
                            }
                          >
                            <Info />
                          </Tooltip>
                        </span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MenuItem>

                <MenuItem className={classes.menu} style={{ display: 'none' }}>
                  <TextField
                    fullWidth={true}
                    size={'small'}
                    variant={'outlined'}
                    value={phone_numbers && phone_numbers[0] ? prettyPhone(phone_numbers[0]) : 'N/A'}
                    label={"Your Organization's Phone Number"}
                  />
                </MenuItem>

                <MenuItem>
                  <Grid container item xs={12} spacing={2} style={{ margin: 'auto' }}>
                    <Grid item xs={6}>
                      <a
                        href={
                          'https://slack.com/oauth/v2/authorize?client_id=4274529150977.4594250718355&scope=app_mentions:read,chat:write,commands,incoming-webhook,links.embed:write,links:write,chat:write.public&user_scope=&state=' +
                          organizationId
                        }
                      >
                        <img
                          alt="Add to Slack"
                          height="35"
                          width="120"
                          src="https://platform.slack-edge.com/img/add_to_slack.png"
                          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={6}>
                      <a
                        href={
                          'https://slack.com/oauth/v2/authorize?client_id=4274529150977.4594250718355&scope=app_mentions:read,chat:write,commands,incoming-webhook,links.embed:write,links:write,chat:write.public&user_scope=' +
                          organizationId
                        }
                      >
                        <img alt="Download for macOS" height="35" width="120" src={macOS_download} />
                      </a>
                    </Grid>
                  </Grid>
                </MenuItem>

                <MenuItem>
                  <Link to={'/settings/organization'} className={classes.link}>
                    <Settings />
                    &nbsp;Manage Organization
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleSearch}>
                  <Search />
                  &nbsp;Universal Search
                </MenuItem>
                <MenuItem onClick={handleTheme}>
                  <InvertColors />
                  &nbsp;Switch to {darkMode ? 'Light' : 'Dark'} Theme
                </MenuItem>
                {/*
                <MenuItem onClick={handleHelp}>
                  <Help />
                  &nbsp;FAQ / Knowledge Base
                </MenuItem>*/}
                <MenuItem>
                  <Link to={'/help'} className={classes.link}>
                    <Help />
                    &nbsp;FAQ / Knowledge Base
                  </Link>
                </MenuItem>
                {/*<MenuItem><Link to={'/docs'}>API Docs</Link></MenuItem>*/}
                {user && (
                  <MenuItem disableRipple={true}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={2} style={{ marginTop: 7 }}>
                        <Link to={'/settings'} className={classes.link}>
                          <Avatar
                            size={35}
                            name={'' + user.id}
                            variant="beam"
                            colors={['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']}
                            src={`https://sendboth.s3.amazonaws.com/profile_photos/${user.id}_profile.png`}
                          />
                        </Link>
                      </Grid>
                      <Grid item xs={8}>
                        <Link to={'/settings'} className={classes.link}>
                          <Typography style={{ marginTop: 12 }}>
                            {user.first_name ?? ''} {user.last_name ?? ''}
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip title={'Log Out'}>
                          <IconButton onClick={logout}>
                            <Logout />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Divider style={{ fill: 'white' }} />
                  </MenuItem>
                )}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      {/*
    <Drawer
      className={isOpen ? classes.drawer + ' drawerParent' : classes.drawerClosed + ' drawerParent'}
      variant="permanent"
      classes={{
        paper: isOpen ? classes.drawerPaper : classes.drawerClosed,
      }}
      
    >
      <Box className={classes.topBox}>
        
        <Logo variant={'admin'} />
        <Menu
          style={{
            padding: 0,
            margin: '0 5px 0 0',
            top: -3,
            position: 'relative'
          }}
          className={'burger'}
          buttonWidth={20}
          barColor="white"
          isActive={isOpen}
          toggleButton={() => setOpen(!isOpen)}
        />
      </Box>
      <Typography className={classes.partnerName}>{partnerName}</Typography>
      <div className={classes.toolbar} style={{ display: 'none' }} />

      {page_access.map((page_obj, index) => {
        const { name, path, onclick, disabled, url } = page_obj;
        return (
          <List className={classes.sideNavList} key={`${path}_${index}`}>
            <ListItem
              selected={active === name ? true : false}
              button
              key={index}
              className={cn(classes.listItem, active === name ? classes.listItemActive : '')}
            >
              <Link to={url ? { pathname: getClientHost(partnerCode)+'/'+url } : '/'+path} target={url ? '_blank' : 'inherit'}>
                <Tooltip placement="bottom" title={name} aria-label={name}>
                  <ListItemIcon className={classes.ListItemIcon}>
                    {(() => {
                      switch (path) {
                        default:
                        case 'dashboard':
                          return <HomeOutlined />;
                        case 'patients':
                          return <PollOutlined />;
                        case 'resources':
                          return <MenuBookOutlined />;
                        case 'messages':
                          return <EmailOutlined />;
                        case 'settings':
                          return <SettingsOutlined />;
                        case 'help':
                          return <HelpOutline />;
                        case 'billing':
                          return <Payment />;
                        case 'settings#billing':
                          return <CheckBoxOutlined />;
                      }
                    })()}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  className={classes.listItemText}
                  primary={name}
                  secondary={disabled ? <span className={classes.comingSoonBadge}>{'Coming Soon'}</span> : ''}
                />
              </Link>
            </ListItem>
          </List>
        );
      })}
  
      <List className={'SideNavList'}>
        <ListItem button key="ShareURL" className={classes.listItem}>
          <a target="#" rel="noopener noreferrer" onClick={() => setShowShareLink(true)}>
            <Tooltip placement="right" title={'Log Out'} aria-label={'Share Widgets'}>
              <ListItemIcon className={classes.ListItemIcon}>
                <ShareOutlined />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText} primary={matchXL || matchLG ? "Share Widgets" : "Share Widgets"} />
          </a>
        </ListItem>
      </List>
      <Divider />
      <List className={'SideNavList'}>
        <ListItem button key="LogOut" className={classes.listItem}>
          <a target="#" rel="noopener noreferrer" onClick={logout}>
            <Tooltip placement="right" title={'Log Out'} aria-label={'Log Out'}>
              <ListItemIcon className={classes.ListItemIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className={classes.listItemText} primary="Log Out" />
          </a>
        </ListItem>
      </List>
      <div
        className={'bottom'}
        style={{
          margin: 'auto 0 20px auto',
          textAlign: 'left',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography className={classes.internalInfo}>
          Build: v{packageJson.version}
        </Typography>
        {userRole && <Typography className={classes.internalInfo}>
          Role: {toCapitalizeFromUnderScore(userRole)}
        </Typography>}
      </div>
      {showShareLink && <ShareLinkDialog handleClose={() => setShowShareLink(false)}/>}
    </Drawer>*/}
    </>
  );
};

export default TopBarNavigation;

const top_sources = [
  { "value" : "google.com"},
  { "value" : "mail.google.com"},
  { "value" : "docs.google.com"},
  { "value" : "twitter.com"},
  { "value" : "instagram.com"},
  { "value" : "facebook.com"},
  { "value" : "reddit.com"},
  { "value" : "tiktok.com"},
  { "value" : "snapchat.com"},
  { "value" : "linkedin.com"},
  { "value" : "bing.com"},
  { "value" : "yahoo.com"},
  { "value" : "mail.yahoo.com"},
  { "value" : "youtube.com"},
  { "value" : "pinterest.com"},
  { "value" : "amazon.com"},
  { "value" : "live.com"},
  { "value" : "etsy.com"},
  { "value" : "ebay.com"},
]
export default top_sources;

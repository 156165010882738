import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Grid, Select, MenuItem } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  IconButton, InputAdornment,
  TextField, Typography,
  withStyles,
} from '@material-ui/core';
import {Add, Tune, Create, GroupAdd, Delete, Close, Check, Refresh, FileCopy, VerifiedUser} from '@material-ui/icons';
import { postContactList } from '../../lib/api/postContactList';
import { ProgressBar } from '../ProgressBar';
import {postApiKey, postVerifiedDomain} from "../../lib/api";
import {useSnackbar} from "notistack";
import usePermission from "../../lib/hooks/usePermission";
import {isValidEmail} from "../../lib/helpers/helpers";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  },
  refreshButton:{
    float: 'right',
    color: theme.palette.secondary.main,
    '& button':{
      marginLeft: 2.5,
      marginTop: -5,
    }
  }
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const AddVerifiedDomainDialog = ({style, size = 'small', forceOpen = false, setForceOpen, refetch,  invisible = undefined, title = undefined, viewVerificationDetails = undefined}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [verificationDetails, setVerificationDetails] = useState();
  const [email, setEmail] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const hasPermission = usePermission('verified_domains.edit')
  
  useEffect(()=>{
    if(viewVerificationDetails){
      setSaving(true);
      setEmail("mail@"+viewVerificationDetails)
      postVerifiedDomain("mail@"+viewVerificationDetails).then(response=>{
        setVerificationDetails(response);
        setSaving(false);
      })
    }
  },[viewVerificationDetails])
  
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
    if(forceOpen && open){
      setForceOpen(false)
    }
  },[open, forceOpen, setForceOpen]);
  
  const handleClose = useCallback(() => {
    refetch();
    setEmail('')
    setOpen(false);
  },[open]);
  
  const handleInputChange = useCallback((event) => {
    setEmail(event.target.value);
  },[]);
  
  const handleSave = useCallback(() => {
    const cleanEmail = email.trim();
    if(cleanEmail.length > 3){
      setSaving(true);
      postVerifiedDomain(cleanEmail).then(response=>{
        setVerificationDetails(response);
        setSaved(true);
        setSaving(false);
      })
    }
  },[email]);
  
  const copyTextToClipboard = useCallback(async (input) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(input);
    } else {
      return document.execCommand('copy', true, input);
    }
  },[]);
  const handleCopy = (input) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(input)
       .then(() => {
         // If successful, update the isCopied state value
         enqueueSnackbar('Copied!', {variant:'info'})
         setTimeout(() => {
         }, 1500);
       })
       .catch((err) => {
         console.log(err);
       });
  }
  
  return (
    <>
      {!invisible && <Button variant={'contained'} color={'primary'} onClick={hasPermission ? handleToggleOpen : () => enqueueSnackbar('Please ask Admin for permission.', {variant:'error'})} disabled={open || saving} style={style} size={size}>
        {title ?? 'New Domain'}
      </Button>}
      <Dialog
        maxWidth={verificationDetails ? 'md' : 'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-verified-domain-dialog-form" className={classes.form}
      >
        <DialogTitle id="add-verified-domain-dialog-title"><VerifiedUser/>Add a new Verified Domain{
          verificationDetails && <>: {verificationDetails.domain}</>}</DialogTitle>
        <DialogContent>
          {
            saving ?
              <ProgressBar style={{margin:'50px auto 50px'}}/>
              :
               <>
                 {
                   verificationDetails ?
                      <>
                        <DialogContentText>
                          In addition to verifying your email, you MUST update your new domain&apos;s DNS with the following records:
                        </DialogContentText>
                        <DialogTitle id="add-verified-domain-dialog-title">CNAME Records <span className={classes.refreshButton}>Status: {verificationDetails.dkim_status} { verificationDetails.dkim_status === 'Pending' ? <IconButton className={classes.refreshButton} onClick={handleSave}><Refresh/></IconButton> : <Check/>}</span></DialogTitle>
                        <form noValidate>
                          <Grid container item xs={12} spacing={1}>
                            <Grid item xs={6}>
                              <AdminGreenTextField
                                 value={verificationDetails.dkim_tokens[0]+'._domainkey.'+verificationDetails.domain}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Name</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy CNAME name'
                                           onClick={() => handleCopy(verificationDetails.dkim_tokens[0]+'._domainkey.'+verificationDetails.domain)}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <AdminGreenTextField
                                 value={verificationDetails.dkim_tokens[0]+'.dkim.amazonses.com'}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Value</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy CNAME value'
                                           onClick={() => handleCopy(verificationDetails.dkim_tokens[0]+'.dkim.amazonses.com')}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <AdminGreenTextField
                                 value={verificationDetails.dkim_tokens[1]+'._domainkey.'+verificationDetails.domain}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Name</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy CNAME name'
                                           onClick={() => handleCopy(verificationDetails.dkim_tokens[1]+'._domainkey.'+verificationDetails.domain)}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <AdminGreenTextField
                                 value={verificationDetails.dkim_tokens[1]+'.dkim.amazonses.com'}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Value</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy CNAME value'
                                           onClick={() => handleCopy(verificationDetails.dkim_tokens[1]+'.dkim.amazonses.com')}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <AdminGreenTextField
                                 value={verificationDetails.dkim_tokens[2]+'._domainkey.'+verificationDetails.domain}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Name</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy CNAME name'
                                           onClick={() => handleCopy(verificationDetails.dkim_tokens[2]+'._domainkey.'+verificationDetails.domain)}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <AdminGreenTextField
                                 value={verificationDetails.dkim_tokens[2]+'.dkim.amazonses.com'}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Value</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy CNAME value'
                                           onClick={() => handleCopy(verificationDetails.dkim_tokens[2]+'.dkim.amazonses.com')}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                          </Grid>
                        </form>
                        <DialogTitle id="add-verified-domain-dialog-title">MX Records <span className={classes.refreshButton}>Status: {verificationDetails.mx_txt_status} { verificationDetails.mx_txt_status === 'Pending' ? <IconButton className={classes.refreshButton} onClick={handleSave}><Refresh/></IconButton> : <Check/>}</span></DialogTitle>
                        <form noValidate>
                          <Grid container item xs={12} spacing={1}>
                            <Grid item xs={5}>
                              <AdminGreenTextField
                                 value={verificationDetails.mx_txt_value}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Name</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy MX name'
                                           onClick={() => handleCopy(verificationDetails.mx_txt_value)}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <AdminGreenTextField
                                 value={verificationDetails.mx_priority}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Priority</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy MX priority'
                                           onClick={() => handleCopy(verificationDetails.mx_priority)}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <AdminGreenTextField
                                 value={verificationDetails.mx_value}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>Value</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy MX value'
                                           onClick={() => handleCopy(verificationDetails.mx_value)}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            </Grid>
                        </form>
                        <DialogTitle id="add-verified-domain-dialog-title">TXT Records <span className={classes.refreshButton}>Status: {verificationDetails.mx_txt_status} { verificationDetails.mx_txt_status === 'Pending' ? <IconButton className={classes.refreshButton} onClick={handleSave}><Refresh/></IconButton> : <Check/>}</span></DialogTitle>
                        <form noValidate>
                          <Grid container item xs={12} spacing={1}>
                            <Grid item xs={6}>
                              <TextField
                                 value={'@'}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>TXT Record</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy TXT name'
                                           onClick={() => handleCopy('@')}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <AdminGreenTextField
                                 value={verificationDetails.txt_verification_token}
                                 variant={'outlined'}
                                 fullWidth={true}
                                 label={<>TXT Record</>}
                                 InputProps={{
                                   endAdornment:
                                      <InputAdornment position='end'>
                                        <IconButton
                                           aria-label='Copy TXT value'
                                           onClick={() => handleCopy(verificationDetails.txt_verification_token)}
                                        >
                                          {<FileCopy />}
                                        </IconButton>
                                      </InputAdornment>,
                                 }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                          <AdminGreenTextField
                             value={verificationDetails.mx_txt_value}
                             variant={'outlined'}
                             fullWidth={true}
                             label={<>Name</>}
                             InputProps={{
                               endAdornment:
                                  <InputAdornment position='end'>
                                    <IconButton
                                       aria-label='Copy TXT name'
                                       onClick={() => handleCopy(verificationDetails.mx_txt_value)}
                                    >
                                      {<FileCopy />}
                                    </IconButton>
                                  </InputAdornment>,
                             }}
                          />
                            </Grid>
                            <Grid item xs={6}>
                          <AdminGreenTextField
                             value={verificationDetails.txt_value}
                             variant={'outlined'}
                             fullWidth={true}
                             label={<>Value</>}
                             InputProps={{
                               endAdornment:
                                  <InputAdornment position='end'>
                                    <IconButton
                                       aria-label='Copy TXT value'
                                       onClick={() => handleCopy(verificationDetails.mx_txt_value)}
                                    >
                                      {<FileCopy />}
                                    </IconButton>
                                  </InputAdornment>,
                             }}
                          />
                            </Grid>
                            </Grid>
                        </form>
                      </>
                      :
                      <>
                        <DialogContentText>
                          Please enter a valid Email Address of a Domain that you own. We will send you a verification link.
                        </DialogContentText>
                        <form noValidate>
                          <AdminGreenTextField
                             autoFocus={true}
                             value={email}
                             onChange={handleInputChange}
                             variant={'outlined'}
                             fullWidth={true}
                             label={<>Email Address</>} placeholder={'e.g., hello@Example.com'}
                          />
                        </form>
                      </>
                 }
                 </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={verificationDetails ? handleClose : handleSave}
                  className={classes.addButton}
                  disabled={saving || !isValidEmail(email)}>
            {verificationDetails ? 'Close' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  );
}

export default AddVerifiedDomainDialog;

import React, {useContext, useState} from 'react';

import {Grid, Paper, Typography} from "@material-ui/core";
import { Screen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from '../../lib/contexts';
import { Sidebar } from './components/index.js';
import {FileBrowser} from "./components";
const useStyles = makeStyles(theme => ({
  screen:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
  },
  container:{
    width:'100%',
    maxWidth:'80vw',
    margin:'auto',
    '& h2':{
      width:'100%',
      fontSize:20,
      marginBottom:20,
      color: theme.palette.primary.main,
      display: 'block'
    },
  },
}));

const AssetManagementScreen = () => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    state: { first_name },
  } = useContext(UserContext)
  
  return (
    <Screen title={'Assets'} showTopNav={true} showFooter={true} permission={'assets.view'}>
      <Grid
         container
         alignItems="center"
         spacing={0}
         className={classes.screen}
      >
        <Typography variant={'subtitle1'}>
          Asset Management
        </Typography>
        <Grid
           container
           item xs={12}
           direction="row"
           justifyContent="space-evenly"
           alignItems="flex-start"
           spacing={0}
           className={classes.container}
        >
          <Grid item xs={4}>
            <Sidebar selectedIndex={selectedIndex} />
          </Grid>
          <Grid item xs={8}>
            <Typography component={'h2'} style={{textAlign:'center'}}>
              Your Organization&apos;s Assets can be attached to templates or sent to various contacts.
            </Typography>
            <Paper style={{padding:20}}>
              <FileBrowser selectedFilter={selectedIndex} />
            </Paper>
          </Grid>
    
        </Grid>
      </Grid>
    </Screen>
  );
};
export default AssetManagementScreen;

import React, { useState, useCallback } from 'react';
import {Avatar, LinearProgress, Button, Box, Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, Typography, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/';
import {Create} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ProgressBar from '../ProgressBar/ProgressBar.js';
import {postContacts} from '../../lib/api';

const useStyles = makeStyles(theme => ({
   root: {
      flexGrow: 1,
   },
   container:{
      padding: 20,
   },
   avatar:{
      textAlign:'center',
   },
   successBox:{
      textAlign:'center',
      margin: '25px auto 25px',
      '& p':{
         fontSize:20,
         margin: '25px auto 25px',
      }
   }
}));

const AddContactForm = ({ handleToggleOpen, refetch }) => {
   const classes = useStyles();
   const [success, setSuccess] = useState(false);
   const [saving, setSaving] = useState(false);
   const [avatar, setAvatar] = useState('');
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [username, setUsername] = useState('');
   const [organization, setOrganization] = useState('');
   const [gender, setGender] = useState('');
   const [dob, setDob] = useState('');
   const [address, setAddress] = useState('');
   const [address2, setAddress2] = useState('');
   const [city, setCity] = useState('');
   const [state, setState] = useState('');
   const [postalCode, setPostalCode] = useState('');
   const [country, setCountry] = useState('');
   const [source, setSource] = useState('');
   const [locale, setLocale] = useState('');
   const [emailOptIn, setEmailOptIn] = useState(false);
   const [smsOptIn, setSmsOptIn] = useState(false);
   
   const handleInputChange = useCallback((event) => {
      setAvatar(event.target.value);
   },[]);
   
   const handleReset = useCallback(() => {
      setSmsOptIn(false);
      setEmailOptIn(false);
      setSuccess(false);
      setSaving(false);
      setAvatar('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setUsername('');
      setOrganization('');
      setGender('');
      setDob('');
      setAddress('');
      setAddress2('');
      setCity('');
      setState('');
      setPostalCode('');
      setCountry('');
      setSource('');
      setLocale('');
   },[])
   const handleSave = useCallback(() => {
      const cleanFirstName = firstName.trim();
      const cleanLastName = lastName.trim();
      const cleanPhone = phone.trim();
      const cleanEmail= email.trim();
      if(cleanFirstName.length > 2 || cleanLastName.length > 2 ||  cleanEmail.length > 2 || (cleanPhone.length > 1 && cleanPhone.length < 13)){
         setSaving(true);
         const req = {
            contact:{
               profile_photo: avatar,
               first_name: firstName,
               last_name: lastName,
               phone_number: phone,
               address_1: address,
               address_2: address2,
               postal_code: postalCode,
               dob:dob,
               email,
               username,
               organization,
               gender,
               city,
               state,
               country,
               source,
               locale,
               emailOptIn,
               smsOptIn,
            }
         }
         postContacts(req).then((response)=>{
            if(response.id){
               setSuccess(true);
               refetch();
            }
            setSaving(false);
         })
      }
   },[
      avatar,firstName,lastName,phone,address,address2, postalCode,
      email,
      username,
      organization,
      gender,
      dob,
      city,
      state,
      country,
      source,
      locale,
      emailOptIn,
      smsOptIn]);
   return (<>
    <Box className={classes.container}>
       {success && <Box className={classes.successBox}>
          <Typography>{'Success! New Contact Saved.'}</Typography>
          <Button onClick={handleReset}
                  className={classes.addButton}
                  color={'primary'}
                  variant={'contained'}
          >
             Add Another?
          </Button>
       </Box>
       }
       {!success && <DialogContent>
          {
             saving ? <>
                   <ProgressBar style={{margin: '120px auto 120px'}}/>
                </>
                :
                <form noValidate>
                   <Grid
                      container
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                      spacing={2}>
                  
                      <Grid item xs={1} container justifyContent={'space-evenly'}>
                         <Avatar className={classes.avatar}
                                 color={'primary'}
                                 value={avatar}
                                 onChange={handleInputChange}
                                 variant={'square'}
                         />
                      </Grid>
                      <Grid item xs={5}>
                         <TextField
                            color={'primary'}
                            value={firstName}
                            onChange={event => setFirstName(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>First Name</>} placeholder={'First Name'}
                         />
                      </Grid>
                      <Grid item xs={5}>
                         <TextField
                            color={'primary'}
                            value={lastName}
                            onChange={event => setLastName(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Last Name</>} placeholder={'Last Name'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Email</>} placeholder={'Email'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={phone}
                            onChange={event => setPhone(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Phone</>} placeholder={'Phone'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Username</>} placeholder={'Username'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={organization}
                            onChange={event => setOrganization(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Organization</>} placeholder={'Username'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={gender}
                            onChange={event => setGender(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Gender</>} placeholder={'Gender'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={dob}
                            onChange={event => setDob(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Date of Birth</>} placeholder={'Date of Birth'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={address}
                            onChange={event => setAddress(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Address</>} placeholder={'Address'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={address2}
                            onChange={event => setAddress2(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Address 2</>} placeholder={'Address 2'}
                         />
                      </Grid>
                      <Grid item xs={4}>
                         <TextField
                            color={'primary'}
                            value={city}
                            onChange={event => setCity(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>City</>} placeholder={'City'}
                         />
                      </Grid>
                      <Grid item xs={2}>
                         <TextField
                            color={'primary'}
                            value={state}
                            onChange={event => setState(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>State</>} placeholder={'State'}
                         />
                      </Grid>
                      <Grid item xs={3}>
                         <TextField
                            color={'primary'}
                            value={postalCode}
                            onChange={event => setPostalCode(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Postal Code</>} placeholder={'Postal Code'}
                         />
                      </Grid>
                      <Grid item xs={3}>
                         <TextField
                            color={'primary'}
                            value={country}
                            onChange={event => setCountry(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Country</>} placeholder={'Country'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={source}
                            onChange={event => setSource(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Source</>} placeholder={'i.e., Instagram.com'}
                         />
                      </Grid>
                      <Grid item xs={6}>
                         <TextField
                            color={'primary'}
                            value={locale}
                            onChange={event => setLocale(event.target.value)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={<>Locale</>} placeholder={'i.e., EN/US'}
                         />
                      </Grid>
                      <Grid item xs={6} container justifyContent={'space-evenly'}>
                         <FormControlLabel
                            control={
                               <Checkbox
                                  checked={emailOptIn}
                                  onChange={() => setEmailOptIn(!emailOptIn)}
                                  name="emailOptIn"
                                  color="primary"
                               />
                            }
                            label="Email Opt-In Confirm?"
                         />
                      </Grid>
                      <Grid item xs={6} container justifyContent={'space-evenly'}>
                         <FormControlLabel
                            control={
                               <Checkbox
                                  checked={smsOptIn}
                                  onChange={() => setSmsOptIn(!smsOptIn)}
                                  name="smsOptIn"
                                  color="primary"
                               />
                            }
                            label="SMS Opt-In Confirm?"
                         />
                      </Grid>
                   </Grid>
            
                </form>
          }
       </DialogContent>}
    </Box>
      
         {!success && <DialogActions>
            <Button onClick={handleToggleOpen}
                    className={classes.cancelButton}
                    disabled={saving}
                    variant={'contained'}>
               Cancel
            </Button>
            <Button onClick={handleSave}
                    className={classes.addButton}
                    disabled={saving || firstName.length < 2 || lastName.length < 2 || email.length < 2 || (phone.length < 1 && phone.length > 13)}
                    color={'primary'}
                    variant={'contained'}
            >
               Save Contact
            </Button>
         </DialogActions>}
      </>
  );
}

export default AddContactForm;

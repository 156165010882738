import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Logo } from '../../components/Logo/';

import {
  Fade,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core';
import { Screen } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  bgshape1: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    zIndex: -1,
    backgroundColor: theme.palette.primary.main, // || '#3f83d1',
  },
  bgshape2: {
    transform: 'rotate(52deg)',
    position: 'fixed',
    right: -500,
    top: -100,
    width: 0,
    height: 0,
    borderTop: '100vh solid transparent',
    borderBottom: '100vh solid transparent',
    borderLeft: '100vh solid #b6b6b6',
    zIndex: -1,
    backgroundColor: '#b6b6b6',
  },
  bgshape3: {
    transform: 'rotate(-35deg)',
    position: 'fixed',
    left: '-50vh',
    top: -250,
    width: 0,
    height: 0,
    borderTop: '100vh solid transparent',
    borderBottom: '100vh solid transparent',
    borderLeft: '100vh solid ' + theme.palette.secondary.main,
    zIndex: -1,
    backgroundColor: theme.palette.secondary.main, // || '#ff0044',
  },
  paper: {
    minWidth: 400,
    minHeight: 520,
    width: '60vh',
    height: '60vh',
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
  },
}));

const NotFoundScreen = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Not Found | SendBoth';
  }, []); //html page title

  return (
    <Screen showSidebarNav={true}>
      <Paper className={classes.paper} elevation={24}>
        <div style={{ marginTop: '10vh', paddingTop: '10vh', textAlign: 'center' }}>
          <Logo height="70px" />
        </div>
        
              <>
                <Grid className="copy" container alignItems="center">
                  <Grid item xs>
                    <Fade in={true}>
                      <>
                        <Typography
                          gutterBottom
                          variant="h4"
                          style={{ marginTop: 0, fontSize: '1.8rem', marginBottom: 15, textAlign: 'center' }}
                        >
                          {'Oops!'}
                        </Typography>
                        <Typography gutterBottom variant="h6" style={{ fontSize: '1.0rem', textAlign: 'center' }}>
                          {'Page Not Found'}
                        </Typography>
                        <a href={'javascript:history.back()'} style={{ fontSize: '0.8rem', textAlign: 'center', display:'block' }}>
                          {'Go Back'}
                        </a>
                      </>
                    </Fade>
                  </Grid>
                </Grid>
              </>
      </Paper>
    </Screen>
  );
};

export default NotFoundScreen;
